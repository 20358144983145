import { Icon, type IconType } from '@sevenrooms/core/ui-kit/icons'
import { Box, Flex } from '@sevenrooms/core/ui-kit/layout'
import type { ThemeColor, ThemeTextStyle } from '@sevenrooms/core/ui-kit/theme'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { CSSProperties } from 'react'

export interface ReservationBreadcrumbItemProps {
  icon?: IconType
  text?: string
  textStyle?: ThemeTextStyle
  textDecoration?: CSSProperties['textDecoration']
  color?: ThemeColor
  minWidth?: string
  'data-test'?: string
}

export function ReservationBreadcrumbItem({
  icon,
  text,
  textStyle = 'body1',
  textDecoration,
  color,
  minWidth,
  'data-test': testId,
}: ReservationBreadcrumbItemProps) {
  if (!text && !icon) {
    return null
  }
  return (
    <Flex alignItems="baseline" data-test={`breadcrumb-item-${testId}`} minWidth={minWidth}>
      <Text textStyle={textStyle} textDecoration={textDecoration} color={color} textAlign="center">
        {icon ? (
          <Box display="initial" mr={text ? 'xs' : 'none'}>
            <Icon color={color ?? 'primaryIcons'} name={icon} size="lg" />
          </Box>
        ) : null}
        {text ?? null}
      </Text>
    </Flex>
  )
}
