import { defineMessages } from '@sevenrooms/core/locales'

export const widgetContainerMessages = defineMessages({
  // Not Currently Translated
  headerAlt: {
    id: 'widget.venueImage.alt',
    defaultMessage: '{venue_name} Header Image',
  },
  venue: {
    id: 'widget.venueImage.venueLabel',
    defaultMessage: 'Venue',
  },
})
