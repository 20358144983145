import { useState } from 'react'
import mergeRefs from 'react-merge-refs'
import styled from 'styled-components'
import { useLocales } from '@sevenrooms/core/locales'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { IconButton, Button, BasicButton } from '@sevenrooms/core/ui-kit/form'
import { useScrollController, useOverflowed, useMeasure } from '@sevenrooms/core/ui-kit/hooks'
import { HStack, VStack, Box, Flex } from '@sevenrooms/core/ui-kit/layout'
import { Text, SecondaryText } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { getTimeslotDescription } from '../../PrimaryAvailability/ReservationAvailability'
import { ReservationTimeslot } from '../../ReservationTimeslot'
import { SkeletonBox } from '../../SkeletonBox'
import { TimeSlotStack } from '../../TimeSlotStack'
import type { AvailabilityTimeWithUpSellCost } from '../../../hooks'

export interface OtherAvailabilityGroupProps {
  title: string
  titleDataTest: string
  subtitle?: string
  onTitleClick: () => void
  onTimeSlotClick: (slot: AvailabilityTimeWithUpSellCost) => void
  isMobile: boolean
  timeslots: AvailabilityTimeWithUpSellCost[]
  currencyCode: string
  scrollLeftLabel: string
  scrollRightLabel: string
  isFetching: boolean
  noAvailabilityMessage?: string
  numLoadingSkeleton?: number
  shouldUsePrimaryColor?: boolean
}

const OTHER_AVAILABILITY_TIMESLOT_WIDTH = 150
const NUMBER_OF_SKELETON_LOADERS = 8

export function OtherAvailability({
  title,
  titleDataTest,
  subtitle,
  onTitleClick,
  onTimeSlotClick,
  timeslots,
  isMobile,
  currencyCode,
  scrollLeftLabel,
  scrollRightLabel,
  isFetching,
  noAvailabilityMessage,
  numLoadingSkeleton,
  shouldUsePrimaryColor,
}: OtherAvailabilityGroupProps) {
  const { ref, scrollLeft, scrollRight, canScrollLeft, canScrollRight } = useScrollController(
    2 * OTHER_AVAILABILITY_TIMESLOT_WIDTH,
    'smooth'
  )
  const { formatMessage } = useLocales()
  const { fieldHeight } = useTheme()
  const [setMeasureRef, { width }] = useMeasure({ scroll: false, offsetSize: false })
  const [isTruncated, setOverflowedRef] = useOverflowed(width)

  const [shouldEnableEllipsis, setShouldEnableEllipsis] = useState(true)

  return (
    <VStack spacing="m">
      <HStack minWidth="0" justifyContent="space-between" alignItems="flex-end">
        <Box minWidth="0">
          <OtherDateButtonTitle onClick={onTitleClick} data-test={titleDataTest}>
            {title}
          </OtherDateButtonTitle>
          {subtitle &&
            (shouldEnableEllipsis ? (
              <HStack mt="xs">
                <SecondaryText
                  ref={mergeRefs([setMeasureRef, setOverflowedRef])}
                  overflow="hidden"
                  whiteSpace="nowrap"
                  textOverflow="ellipsis"
                >
                  {subtitle}
                </SecondaryText>
                {isTruncated && (
                  <Button
                    data-test="view-more-button"
                    noPadding
                    variant="tertiary"
                    onClick={() => {
                      setShouldEnableEllipsis(!shouldEnableEllipsis)
                    }}
                  >
                    {formatMessage(reservationWidgetMessages.resConfirmPageViewMore)}
                  </Button>
                )}
              </HStack>
            ) : (
              <Box mt="xs">
                <SecondaryText ref={mergeRefs([setMeasureRef, setOverflowedRef])}>{subtitle}</SecondaryText>
                <Box as="span" ml="xs">
                  <Button
                    data-test="view-less-button"
                    noPadding
                    variant="tertiary"
                    onClick={() => {
                      setShouldEnableEllipsis(!shouldEnableEllipsis)
                    }}
                  >
                    {formatMessage(reservationWidgetMessages.resConfirmPageViewLess)}
                  </Button>
                </Box>
              </Box>
            ))}
        </Box>

        {!isMobile && !isFetching && timeslots && timeslots.length > 0 && (canScrollLeft || canScrollRight) && (
          <Flex flex="0 0 auto" ml="s">
            <IconButton
              borderType="none-round"
              size="s"
              icon="VMSWeb-chevron-left"
              aria-label={scrollLeftLabel}
              onClick={scrollLeft}
              disabled={!canScrollLeft}
            />
            <IconButton
              borderType="none-round"
              size="s"
              icon="VMSWeb-chevron-right"
              aria-label={scrollRightLabel}
              onClick={scrollRight}
              disabled={!canScrollRight}
            />
          </Flex>
        )}
      </HStack>
      <TimeSlotStack isMobile={isMobile} width="100%" spacing="sm" justifyContent="flex-start" flexWrap="nowrap" ref={ref}>
        {isFetching
          ? Array.from({ length: numLoadingSkeleton || NUMBER_OF_SKELETON_LOADERS }, (_v, i) => i).map(i => (
              <Box key={i}>
                <SkeletonBox width={`${OTHER_AVAILABILITY_TIMESLOT_WIDTH}px`} height={fieldHeight.l} />
              </Box>
            ))
          : timeslots.map(timeSlotInfo => (
              <ReservationTimeslot
                variant={shouldUsePrimaryColor ? 'primary' : 'secondary'}
                width={`${OTHER_AVAILABILITY_TIMESLOT_WIDTH}px`}
                key={`${timeSlotInfo.time}${timeSlotInfo.accessPersistentId ?? ''}`}
                timeslotDescription={getTimeslotDescription(
                  formatMessage,
                  timeSlotInfo.publicTimeSlotDescription,
                  timeSlotInfo.showCost,
                  (timeSlotInfo.cost ?? 0) + timeSlotInfo.includedUpgradesCost,
                  timeSlotInfo.chargeType,
                  timeSlotInfo.duration,
                  currencyCode,
                  timeSlotInfo.fees
                )}
                time={timeSlotInfo.time}
                onClick={() => {
                  onTimeSlotClick(timeSlotInfo)
                }}
              />
            ))}
        {timeslots.length === 0 && !isFetching && noAvailabilityMessage && <Text textStyle="body1">{noAvailabilityMessage}</Text>}
      </TimeSlotStack>
    </VStack>
  )
}

// Exceptional button (existing ui kit component doesnt serve required purpose), requirement from the design team
const OtherDateButtonTitle = styled(BasicButton)`
  color: ${props => props.theme.colors.primaryFont};
  font: ${props => props.theme.textStyles.body1Bold};
  text-decoration: underline !important;

  &:focus,
  &:hover {
    font-weight: 600;
  }
`
