import { filterNullish } from '@sevenrooms/core/api'
import type { AvailabilityAutomaticUpsell, GuestFacingUpgradeCategory, GuestFacingUpgradeInventory } from '@sevenrooms/core/domain'
import { calculateCategoryFees } from './calculateFees'

export const mapAvailabilityUpgrades = (
  partySize: number,
  automaticUpsells?: AvailabilityAutomaticUpsell[],
  // inventories is a full upgrades list for venue, we should always find preselected upgrade here
  inventories?: GuestFacingUpgradeInventory[],
  categories?: GuestFacingUpgradeCategory[],
  defaultServiceCharge?: number,
  defaultGratuity?: number
) => {
  if (!automaticUpsells || !inventories || inventories.length === 0) {
    return []
  }

  return (
    automaticUpsells
      ?.map(({ id, quantityEqualType, quantityNum }) => {
        const upgrade = inventories.find(upgrade => upgrade.id === id)
        if (upgrade) {
          const category = Array.isArray(categories) && categories.find(category => category.id === upgrade.categoryId)
          return {
            ...upgrade,
            quantity: quantityEqualType === 'SPECIFIC_NUMBER' ? quantityNum : partySize,
            fees: category ? calculateCategoryFees(upgrade.price, category, defaultServiceCharge, defaultGratuity) : 0,
          }
        }
        return undefined
      })
      .filter(filterNullish) ?? []
  )
}
