export namespace ReservationDetailFormatService {
  export function formatPartySize(guestCount?: number | null, femaleCount?: number | null, maleCount?: number | null): string {
    const countString = guestCount ?? ''
    const details = []
    if (maleCount) {
      details.push(`${maleCount}m`)
    }
    if (femaleCount) {
      details.push(`${femaleCount}f`)
    }
    return (countString + (details.length ? ` (${details.join(', ')})` : '')).trim()
  }
}
