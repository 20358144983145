import { type ForwardedRef, forwardRef, useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { DateTime } from '@sevenrooms/core/timepiece'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { IconButton } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Box, HStack, StackResponsive } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import { ReservationBreadcrumbItem } from './ReservationBreadcrumbItem'
import { ReservationBreadcrumbLoader } from './ReservationBreadcrumbLoader'
import { useTimer } from './useTimer'

export interface ReservationBreadcrumbProps {
  label?: string
  partySize?: number
  timeIso?: string
  holdEndTime?: Date
  onClick?: () => void
  venueName: string
  isLoading?: boolean
  navigationDisabled?: boolean
}

const holdTimerWidth = '56px'

export function ReservationBreadcrumbComponent(
  { label, partySize, timeIso, venueName, isLoading = false, onClick, holdEndTime, navigationDisabled }: ReservationBreadcrumbProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  const { formatMessage } = useLocales()
  const { fieldHeight } = useTheme()
  const isMobile = useMaxWidthBreakpoint('m')
  const isSmallMobile = useMaxWidthBreakpoint('xs')
  const remainingSeconds = useTimer(holdEndTime)
  const holdCountdownText = useMemo(() => {
    if (remainingSeconds) {
      const minutes = Math.floor(remainingSeconds / 60)
      const seconds = remainingSeconds - minutes * 60
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds
      return `${minutes}:${formattedSeconds}`
    }
    return formatMessage(reservationWidgetMessages.resWidgetHoldExpiredLabel)
  }, [formatMessage, remainingSeconds])

  const wrapBreakpoint = remainingSeconds ? 's' : 'm'
  const shouldWrap = useMaxWidthBreakpoint(wrapBreakpoint)

  if (isLoading) {
    return <ReservationBreadcrumbLoader />
  }

  const date = timeIso ? DateTime.from(timeIso).toDateOnly().formatSMonthNDaySWeek() : ''
  const time = timeIso ? DateTime.from(timeIso).toTimeOnly().formatSTime() : ''

  return (
    <HStack
      data-test="reservation-breadcrumb-container"
      p={isMobile ? `s m` : `s lm`}
      spacing={isMobile ? 'm' : 'l'}
      width="100%"
      alignItems="center"
      justifyContent="center"
      ref={ref}
    >
      {onClick && (
        <IconButton
          size="s"
          data-test="back-button"
          borderType="none-round"
          icon="VMSWeb-chevron-left"
          onClick={onClick}
          disabled={navigationDisabled}
          aria-label="Navigate to Previous Page"
        />
      )}
      <StackResponsive
        spacing={shouldWrap || isSmallMobile ? 's' : 'l'}
        alignItems="center"
        justifyContent="center"
        minHeight={fieldHeight.s}
        flexWrap="wrap"
        breakpoint={wrapBreakpoint}
      >
        <HStack spacing={isSmallMobile ? 'xs' : 's'} alignItems="center">
          {label && <ReservationBreadcrumbItem textStyle="body1Bold" text={label} data-test="label" />}
          {!isMobile && (
            <>
              <ReservationBreadcrumbItem text={venueName} data-test="venue-name" />
              <Text color="inactive">•</Text>
            </>
          )}
          <ReservationBreadcrumbItem text={date} data-test="date" />
          <Text color="inactive">•</Text>
          <ReservationBreadcrumbItem text={time} data-test="time" />
          <Text color="inactive">•</Text>
          <ReservationBreadcrumbItem
            data-test="party-size"
            text={`${partySize} ${
              partySize === 1
                ? formatMessage(reservationWidgetMessages.commonGuestLabel)
                : formatMessage(reservationWidgetMessages.commonGuestsLabel)
            }`}
          />
        </HStack>
        {holdEndTime && (
          <ReservationBreadcrumbItem
            minWidth={holdTimerWidth}
            data-test="remaining-time"
            icon="GX-timer"
            color={remainingSeconds ? 'primaryFont' : 'error'}
            text={holdCountdownText}
          />
        )}
      </StackResponsive>
      {onClick && (
        <Box visibility="hidden">
          <IconButton
            size="s"
            data-test="forward-button"
            borderType="none-round"
            icon="VMSWeb-chevron-right"
            disabled={navigationDisabled}
          />
        </Box>
      )}
    </HStack>
  )
}

export const ReservationBreadcrumb = forwardRef(ReservationBreadcrumbComponent)
