import { useMemo, useCallback, useEffect } from 'react'
import type { CountryCode } from '@sevenrooms/core/domain'
import {
  useAppSelector,
  useAppDispatch,
  clientLoginActions,
  type ClientLoginProvider,
  useReservationFormState,
  type ClientLoginState,
} from '../store'
import { useVenue } from './useVenue'

export function useClientLogin(onClientLogin?: (newState: Omit<ClientLoginState, 'isGettingClaims' | 'isLoggingOut'>) => void) {
  const dispatch = useAppDispatch()
  const {
    clientId,
    provider,
    phoneCountryCode,
    emailAddress,
    firstName,
    lastName,
    phoneNumber,
    image,
    phoneDialCode,
    isGettingClaims,
    isLoggingOut,
  } = useAppSelector(state => state.clientLogin)
  const { newResWidgetSrLoginEnabled } = useVenue()
  const userInfo = useMemo(
    () => ({
      clientId,
      firstName,
      lastName,
      phoneNumber,
      phoneDialCode,
      emailAddress,
      image,
      phoneCountryCode,
    }),
    [clientId, firstName, lastName, phoneNumber, phoneDialCode, emailAddress, image, phoneCountryCode]
  )

  // onClientLogin is called here instead of in setClientLoginDetails intentional,
  // This is because we want onClientLogin to always fire,
  //   even if the login is triggered not where this particular hook is called
  // The useMemo above should protect userInfo from constantly mutating,
  //   even if this useEffect is triggered multiple times
  useEffect(() => {
    if (!onClientLogin || !provider) {
      return
    }
    onClientLogin(userInfo)
  }, [provider, userInfo, onClientLogin])

  const { updateFormState } = useReservationFormState()
  const setClientLoginDetails = useCallback(
    (
      provider: ClientLoginProvider,
      userInfo: {
        clientId?: string
        firstName: string
        lastName: string
        emailAddress?: string
        phoneNumber?: string
        phoneCountryCode?: CountryCode
        phoneDialCode?: string
        image?: string
      }
    ) => {
      dispatch(clientLoginActions.login({ provider, ...userInfo }))
      updateFormState({
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        emailAddress: userInfo.emailAddress,
        phoneNumber: userInfo.phoneNumber,
        phoneDialCode: userInfo.phoneDialCode,
        phoneCountryCode: userInfo.phoneCountryCode,
      })
    },
    [dispatch, updateFormState]
  )
  const clearClientLoginDetails = useCallback(() => {
    dispatch(clientLoginActions.logout())
    updateFormState({
      firstName: undefined,
      lastName: undefined,
      emailAddress: undefined,
      phoneNumber: undefined,
      phoneDialCode: undefined,
      phoneCountryCode: undefined,
    })
  }, [dispatch, updateFormState])

  const getSevenRoomsClientClaims = useCallback(() => {
    if (provider === 'sevenrooms' || isGettingClaims) {
      return
    }
    dispatch(clientLoginActions.getSevenRoomsClientClaimsAction())
  }, [provider, isGettingClaims, dispatch])

  const logoutOfSevenrooms = useCallback(() => {
    if (provider !== 'sevenrooms' || isLoggingOut) {
      return
    }
    dispatch(clientLoginActions.sevenRoomsLogoutAction())
  }, [provider, isLoggingOut, dispatch])

  const onClientDelete = useCallback(() => {
    dispatch(clientLoginActions.logout())
  }, [dispatch])

  // Pull claims on mount if user is logged in with Global Client
  useEffect(() => {
    if (newResWidgetSrLoginEnabled) {
      getSevenRoomsClientClaims()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newResWidgetSrLoginEnabled])

  return {
    provider,
    userInfo,
    isClientLoginLoading: isGettingClaims || isLoggingOut,
    setClientLoginDetails,
    clearClientLoginDetails,
    getSevenRoomsClientClaims,
    logoutOfSevenrooms,
    onClientDelete,
  }
}
