import { useCallback, useMemo } from 'react'
import type { LanguageCode } from '@sevenrooms/core/api'
import { WidgetContainer } from '../../components'
import {
  useAppLoading,
  useVenue,
  useWidgetLanguage,
  useQueryParamAvailability,
  useGetSevenroomsLogin,
  useModifyReservationRoute,
} from '../../hooks'
import { updateFormState, useAppDispatch, useReservationFormState } from '../../store'
import { Upgrades } from './Upgrades'
import { UpgradesContainer } from './UpgradesContainer'
import { UpgradesSectionLoader } from './UpgradesSection'

export function UpgradesPage() {
  const dispatch = useAppDispatch()
  const { largeLogoUrl, urlKey, name } = useVenue()
  const isLoading = useAppLoading()
  const { availableLanguageOptions, selectedLanguage, setSelectedLanguage } = useWidgetLanguage()
  const { hasQueryParamAvailability } = useQueryParamAvailability()
  const { formState } = useReservationFormState()
  const { isModifyRoute } = useModifyReservationRoute()
  const isResDetailsLoading = isModifyRoute && !formState.actual

  const handleSetSelectedLanguage = useCallback(
    (language: LanguageCode) => {
      setSelectedLanguage(language)
      dispatch(updateFormState({ preferredLanguage: language }))
    },
    [dispatch, setSelectedLanguage]
  )

  const languagePicker = useMemo(() => {
    if (availableLanguageOptions.length <= 1) {
      return undefined
    }
    return {
      value: selectedLanguage,
      onChange: handleSetSelectedLanguage,
      options: availableLanguageOptions,
      disabled: isLoading,
    }
  }, [availableLanguageOptions, handleSetSelectedLanguage, isLoading, selectedLanguage])

  const sevenRoomsLogin = useGetSevenroomsLogin()

  return (
    <WidgetContainer
      largeLogoUrl={largeLogoUrl}
      urlKey={urlKey}
      venueName={name}
      showFooter={false}
      breadcrumbsPortalId="upgrades-breadcrumbs"
      footerPortalId="footer-portal-id"
      languagePicker={languagePicker}
      sevenRoomsLogin={sevenRoomsLogin}
    >
      <UpgradesContainer>{hasQueryParamAvailability || isResDetailsLoading ? <UpgradesSectionLoader /> : <Upgrades />}</UpgradesContainer>
    </WidgetContainer>
  )
}

export * from './useUpgradesForm'
