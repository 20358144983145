import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { HStack, CardSection, type CardSectionProps } from '@sevenrooms/core/ui-kit/layout'
import { Header, Text } from '@sevenrooms/core/ui-kit/typography'
import { useClientLogin } from '../../hooks'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import type { ReactNode, PropsWithChildren } from 'react'

export interface ReservationCheckoutGuestLayoutProps extends CardSectionProps {
  socialMediaSection?: ReactNode
  headerText?: string
}

export function ReservationCheckoutGuestLayout({
  socialMediaSection,
  children,
  ...props
}: PropsWithChildren<ReservationCheckoutGuestLayoutProps>) {
  return (
    <CardSection
      data-test="reservation-checkout-button-guest-layout"
      borderColor="borders"
      backgroundColor="primaryBackground"
      borderRadius="s"
      {...props}
    >
      <HeaderText />
      {socialMediaSection}

      {children}
    </CardSection>
  )
}

function HeaderText() {
  const { formatMessage } = useLocales()
  const {
    provider,
    userInfo: { firstName, lastName },
    logoutOfSevenrooms,
  } = useClientLogin()
  if (provider === 'sevenrooms' && firstName && lastName) {
    return (
      <HStack spacing="sm">
        <Header type="h3" fontWeight="normal">
          {formatMessage(reservationWidgetMessages.userGreeting, { user_first_name: firstName, user_last_name: lastName })}
        </Header>
        <Button noPadding variant="tertiary" color="black" onClick={logoutOfSevenrooms} data-test="not-you-button">
          <Text color="primaryFont" textDecoration="underline">
            {formatMessage(reservationWidgetMessages.notLoggedInUser)}
          </Text>
        </Button>
      </HStack>
    )
  }
  return (
    <Header type="h3" fontWeight="normal">
      {formatMessage(reservationWidgetMessages.resWidgetGuestInformationHeader)}
    </Header>
  )
}
