import { VenueTagsService, RTKUtils } from '@sevenrooms/core/api'
import { baseReservationWidgetApi } from './BaseReservationWidgetApi'

const TagsApiTags = ['Tags']

export const reservationTagsApi = baseReservationWidgetApi.enhanceEndpoints({ addTagTypes: TagsApiTags }).injectEndpoints({
  endpoints: builder => ({
    getVenueTags: builder.query({
      providesTags: TagsApiTags,
      queryFn: RTKUtils.rtkQuery(VenueTagsService.getTags),
    }),
  }),
})

export const { useGetVenueTagsQuery } = reservationTagsApi
