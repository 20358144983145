import type { LanguageCode } from '@sevenrooms/core/api'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { useFormWrapperProps } from '@sevenrooms/core/ui-kit/hooks'
import type { LanguagePickerProps } from '@sevenrooms/core/ui-kit/layout'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export interface PreferredLanguageFormProps extends Omit<LanguagePickerProps, 'onChange' | 'value'> {
  languageField: Field<LanguageCode>
}

export function PreferredLanguageForm({ languageField, ...props }: PreferredLanguageFormProps) {
  // ref is not a property supported by FormSelect
  const { ref: _ref, ...formWrapperProps } = useFormWrapperProps(languageField, props)

  const { formatMessage } = useLocales()

  return (
    <Label primary={formatMessage(reservationWidgetMessages.resWidgetPreferredLanguageLabel)}>
      <FormSelect searchable={false} field={languageField} {...props} {...formWrapperProps} />
    </Label>
  )
}
