import styled from 'styled-components'
import { VStack, LogoFooter } from '@sevenrooms/core/ui-kit/layout'
import type { PropsWithChildren } from 'react'

export function UpgradesContainer({ children }: PropsWithChildren<{}>) {
  return (
    <UpgradesWrapper>
      <VStack spacing="lm" pb="lm">
        {children}
        <LogoFooter showTopBorder={false} />
      </VStack>
    </UpgradesWrapper>
  )
}

const UPGRADES_MAIN_CONTENT_WIDTH = '792px'

const UpgradesWrapper = styled.div`
  width: ${UPGRADES_MAIN_CONTENT_WIDTH};
  @media (max-width: ${props => props.theme.breakpoints.m}px) {
    width: 100%;
  }
`
