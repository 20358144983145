import { type FlexProps, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { Skeleton } from '@sevenrooms/react-components/components/Skeleton'

export interface SkeletonBoxProps extends FlexProps {}

export function SkeletonBox({ width = '100%', height = '100%', ...props }: SkeletonBoxProps) {
  return (
    <CardSection width={width} height={height} data-test="skeleton-box" {...props}>
      <Skeleton variant="rectangular" width="100%" height="100%" />
    </CardSection>
  )
}
