import type { PhotoDict, ExperienceAvailabilitySingleDay, Fee } from '@sevenrooms/core/domain'
import { FormatService } from '@sevenrooms/core/locales'
import type { PrivateEventsExperience } from '../hooks/useAvailability'

export function getImageURlFromPhotoDict(photoDict: PhotoDict) {
  // it seems some bug on BE photoDict.large can be undefined, using raw in this case
  const photoDictURL = photoDict.large || photoDict.raw
  return photoDictURL ? `/.h/download/${photoDictURL}` : ''
}

export function formatFee(fee: Fee, currencyCode: string) {
  if (fee.feeAmount === null || fee.feeAmount === undefined) {
    return undefined
  }
  return fee.feeType === 'FIXED_AMOUNT' ? FormatService.formatCurrency(fee.feeAmount, currencyCode) : `${fee.feeAmount}%`
}

export function getTotalMinCapacity(experience: ExperienceAvailabilitySingleDay) {
  if (experience.pdrMinSeatedCapacity === null || experience.pdrMinSeatedCapacity === undefined) {
    return experience.pdrStandingMinCapacity ?? undefined
  }
  if (experience.pdrStandingMinCapacity === null || experience.pdrStandingMinCapacity === undefined) {
    return experience.pdrMinSeatedCapacity
  }
  return Math.min(experience.pdrMinSeatedCapacity, experience.pdrStandingMinCapacity)
}

export function getTotalMaxCapacity(experience: ExperienceAvailabilitySingleDay) {
  if (experience.pdrMaxSeatedCapacity === null || experience.pdrMaxSeatedCapacity === undefined) {
    return experience.pdrStandingCapacity ?? undefined
  }
  if (experience.pdrStandingCapacity === null || experience.pdrStandingCapacity === undefined) {
    return experience.pdrMaxSeatedCapacity
  }
  return Math.max(experience.pdrStandingCapacity, experience.pdrMaxSeatedCapacity)
}

function removeFilePathFromFileName(fileName: string) {
  return fileName.replace(/\..+$/, '')
}

export function getMenuFromExperience(experience: ExperienceAvailabilitySingleDay) {
  return experience.menuBlobId
    ? { name: removeFilePathFromFileName(experience.menuFilename), url: `/.h/download/${experience.menuBlobId}` }
    : undefined
}

export function sortExperiences(a: PrivateEventsExperience, b: PrivateEventsExperience) {
  const timeslotsCountA = a.availabilities?.length ?? 0
  const timeslotsContB = b.availabilities?.length ?? 0
  const maxPartySizeA = Math.max(a.standingCapacityMax || 0, a.seatedCapacityMax || 0)
  const maxPartySizeB = Math.max(b.standingCapacityMax || 0, b.seatedCapacityMax || 0)
  if (timeslotsCountA > timeslotsContB) {
    return -1
  } else if (timeslotsContB > timeslotsCountA) {
    return 1
  }
  if (maxPartySizeA > maxPartySizeB) {
    return -1
  } else if (maxPartySizeB > maxPartySizeA) {
    return 1
  }
  if (a.name > b.name) {
    return 1
  } else if (b.name > a.name) {
    return -1
  }
  return 0
}
