import { defineMessages } from '@sevenrooms/core/locales'

export const reservationAvailabilityMessages = defineMessages({
  resWidgetPerPersonLabel: {
    id: 'resWidgetPerPersonLabel',
    defaultMessage: 'person',
  },
  resWidgetPerReservationLabel: {
    id: 'resWidgetPerReservationLabel',
    defaultMessage: 'reservation',
  },
} as const)
