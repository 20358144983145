import { GuestFacingUpgradeService, RTKUtils } from '@sevenrooms/core/api'
import type { GuestFacingUpgrade } from '@sevenrooms/core/domain'
import { baseReservationWidgetApi } from './BaseReservationWidgetApi'

export const guestFacingUpgradeApi = baseReservationWidgetApi.injectEndpoints({
  endpoints: builder => ({
    getGuestFacingUpgrade: builder.query<GuestFacingUpgrade, string>({
      queryFn: RTKUtils.rtkQuery(GuestFacingUpgradeService.getGuestFacingUpgrades),
    }),
  }),
})

export const { useGetGuestFacingUpgradeQuery } = guestFacingUpgradeApi
