import styled from 'styled-components'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { Box, Flex } from '@sevenrooms/core/ui-kit/layout'
import type { Theme } from '@sevenrooms/core/ui-kit/theme'
import { ReservationAvailabilityGridLoader } from '../../components/PrimaryAvailability/ReservationAvailability'
import { ReservationBreadcrumbLoader } from '../../components/ReservationBreadcrumb'
import { SkeletonBox } from '../../components/SkeletonBox'
import { WidgetPortal } from '../../components/WidgetPortal'
import { ReservationSearchInputGroup } from './ReservationSearchInputGroup'
import { ReservationInputLoader } from './ReservationSelectForm'
import { SearchContainer } from './SearchContainer'

interface SearchLoaderProps {
  isModifyRoute: boolean
}

export function SearchLoader({ isModifyRoute }: SearchLoaderProps) {
  const { lineHeights } = useTheme() as Theme

  return (
    <>
      <SearchContainer.MainContent showBreadcrumbs>
        <div>
          {isModifyRoute && (
            <Flex alignItems="center" justifyContent="center" pt="xxl" pb="lm">
              <SkeletonBox height={lineHeights.xl} borderWidth="none" borderRadius="s" mt="xs" mb="xs" width="400px" />
            </Flex>
          )}
          {isModifyRoute ? (
            <ReservationSearchInputGroup>
              <ReservationInputLoader location="left" />
              <ReservationInputLoader location="center" />
              <ReservationInputLoader location="right" />
            </ReservationSearchInputGroup>
          ) : (
            <StyledReservationSearchInputGroup>
              <ReservationSearchInputGroup>
                <ReservationInputLoader location="left" />
                <ReservationInputLoader location="center" />
                <ReservationInputLoader location="right" />
              </ReservationSearchInputGroup>
            </StyledReservationSearchInputGroup>
          )}
          <Box mt="lm">
            <ReservationAvailabilityGridLoader />
          </Box>
        </div>
      </SearchContainer.MainContent>
      {isModifyRoute && (
        <WidgetPortal portalId="search-breadcrumbs">
          <ReservationBreadcrumbLoader />
        </WidgetPortal>
      )}
    </>
  )
}

const StyledReservationSearchInputGroup = styled.div`
  margin-top: -40px;
  position: sticky;
  z-index: 10;
  top: 72px;
`
