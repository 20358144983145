import { useLocales } from '@sevenrooms/core/locales'
import type { ModalHeaderProps } from '@sevenrooms/core/ui-kit/layout'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { CallVenueMessage } from '../../CallVenueMessage'
import { NotificationModal } from '../NotificationModal'

interface UnableModifyModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
}

export function UnableModifyModal({ closeHref }: UnableModifyModalProps) {
  const { formatMessage } = useLocales()

  return (
    <NotificationModal
      type="warning"
      title={formatMessage(reservationWidgetMessages.resCancelPageUnableModifyResHeader)}
      primary={<CallVenueMessage />}
      closeHref={closeHref}
    />
  )
}
