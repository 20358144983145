import styled, { css } from 'styled-components'
import { HStack } from '@sevenrooms/core/ui-kit/layout'

export const TimeSlotStack = styled(HStack)<{ isMobile: boolean }>`
  overflow-x: scroll;
  ${({ isMobile }) =>
    !isMobile &&
    css`
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    `}
`
