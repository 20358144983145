import { useLocales } from '@sevenrooms/core/locales'
import type { ModalHeaderProps } from '@sevenrooms/core/ui-kit/layout'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { CancelModalActionPanel } from '../CancelModalActionPanel'
import { NotificationModal } from '../NotificationModal'
import type { ReactNode } from 'react'

interface BaseCancelModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onCancelClick?: () => void
  isCancelDisabled?: boolean
  cancellationPolicy?: ReactNode
}

export function BaseCancelModal({ cancellationPolicy, isCancelDisabled, onCancelClick, closeHref }: BaseCancelModalProps) {
  const { formatMessage } = useLocales()

  return (
    <NotificationModal
      data-test="base-cancel-modal"
      title={formatMessage(reservationWidgetMessages.resCancelPageHeader)}
      secondary={cancellationPolicy}
      closeHref={closeHref}
      actionsPanel={<CancelModalActionPanel closeHref={closeHref} isCancelDisabled={isCancelDisabled} onCancelClick={onCancelClick} />}
    />
  )
}
