import { defineMessages } from '@sevenrooms/core/locales'

export const otherVenuesAvailabilityMessages = defineMessages({
  scrollLeftLabel: {
    id: 'othervenues.availability.leftlabel',
    defaultMessage: 'scroll {venue} left',
  },
  scrollRightLabel: {
    id: 'othervenues.availability.rightlabel',
    defaultMessage: 'scroll {venue} right',
  },
})
