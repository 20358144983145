import { useLocales } from '@sevenrooms/core/locales'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { Box, DividerLine, Flex, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import type { PropsWithChildren } from 'react'

export function ReservationCheckoutLoginSection({ children }: PropsWithChildren<{}>) {
  const { formatMessage } = useLocales()
  return (
    <VStack spacing="sm">
      <Box mb="sm">
        <Text>
          <HTMLContent content={formatMessage(reservationWidgetMessages.resWidgetLoginPageHeader)} allowedTags={false} />
        </Text>
      </Box>
      {children}
      <Box>
        <DividerLine
          ml="none"
          mr="none"
          content={
            <Flex alignSelf="center">
              <Text whiteSpace="nowrap" color="inactive">
                {formatMessage(reservationWidgetMessages.resWidgetGuestCheckoutDivider)}
              </Text>
            </Flex>
          }
        />
      </Box>
    </VStack>
  )
}
