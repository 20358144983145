import { useMemo } from 'react'
import type { BaseVenue } from '@sevenrooms/core/domain'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { EmbeddedMap } from '@sevenrooms/core/ui-kit/core'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { AutoFallbackImage, Box, type FlexProps, HStack, VStack, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { BrandedIconButton } from './BrandedIconButton'
import { venueDetailsMessages } from './venueDetailsMessages'

type Venue = Pick<
  BaseVenue,
  | 'name'
  | 'gmapsLink'
  | 'gmapsLinkByPlaceId'
  | 'website'
  | 'facebookLink'
  | 'instagramLink'
  | 'twitterLink'
  | 'address'
  | 'cityState'
  | 'postalCode'
  | 'phoneNumber'
  | 'countryCode'
  | 'latitude'
  | 'longitude'
>

export interface VenueDetailsProps extends FlexProps {
  image?: string
  imageHeight?: string
  gmapsEmbedUrl?: string
  venue: Venue
}

export function VenueDetails({ image, imageHeight = '150px', venue, gmapsEmbedUrl, ...props }: VenueDetailsProps) {
  const { formatMessage } = useLocales()
  const {
    name,
    gmapsLink,
    gmapsLinkByPlaceId,
    website,
    facebookLink,
    instagramLink,
    twitterLink,
    address,
    cityState,
    postalCode,
    phoneNumber,
    countryCode,
  } = venue
  const mapLink = gmapsLink || gmapsLinkByPlaceId

  const onMapClick = useMemo(() => {
    if (!mapLink) {
      // we return undefined here, so that the EmbeddedMap component doesn't render the click wrapper
      return undefined
    }
    const clickFunc = () => {
      window.open(mapLink, '_blank')
    }
    return clickFunc
  }, [mapLink])

  return (
    <CardSection data-test="venue-details" {...props}>
      <AutoFallbackImage
        data-test="venue-image"
        width="100%"
        alt={formatMessage(venueDetailsMessages.imageAltText)}
        fallback={
          gmapsEmbedUrl ? (
            <EmbeddedMap clickTestId="venue-map" embeddedMapUrl={gmapsEmbedUrl} width="100%" height={imageHeight} onMapClick={onMapClick} />
          ) : (
            <></>
          )
        }
        imageUrl={image}
      />
      <VStack pl="m" pr="m" pb="m" pt="s" spacing="s">
        <HStack justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <Box mt="s">
            <Text textStyle="h1" wordBreak="break-word" data-test="venue-name">
              {name}
            </Text>
          </Box>
          <HStack spacing="m" mt="s">
            {website && <BrandedIconButton icon="GX-link" aria-label="venue-website" data-test="venue-website" isExternal href={website} />}
            {facebookLink && (
              <BrandedIconButton icon="GX-facebook" aria-label="venue-facebook" data-test="venue-facebook" isExternal href={facebookLink} />
            )}
            {instagramLink && (
              <BrandedIconButton
                icon="GX-instagram"
                aria-label="venue-instagram"
                data-test="venue-instagram"
                isExternal
                href={instagramLink}
              />
            )}
            {twitterLink && (
              <BrandedIconButton icon="GX-x" aria-label="venue-twitter" data-test="venue-twitter" isExternal href={twitterLink} />
            )}
          </HStack>
        </HStack>
        <HStack spacing="s">
          <Box mt="s">
            <Icon name="GX-map-marker" aria-label="venue-map-marker" data-test="venue-map-marker" color="primaryIcons" size="lg" />
          </Box>
          <HStack spacing="s" flexWrap="wrap">
            <Box mt="s">
              <Text data-test="venue-address">{FormatService.formatAddress(address, cityState, postalCode)}</Text>
            </Box>
            {mapLink && (
              <Box mt="s">
                <Link color="secondaryFont" data-test="venue-directions-link" to={mapLink} isExternal>
                  {formatMessage(venueDetailsMessages.getDirections)}
                </Link>
              </Box>
            )}
          </HStack>
        </HStack>
        {phoneNumber && (
          <HStack spacing="s" mt="s">
            <Icon name="GX-phone" color="primaryIcons" size="lg" />
            <Link
              data-test="venue-phone-number"
              color="primaryFont"
              textDecoration="underline"
              to={`tel:${phoneNumber}`}
              isExternal
              target="_self"
            >
              {FormatService.formatPhoneNumber(phoneNumber, countryCode)}
            </Link>
          </HStack>
        )}
      </VStack>
    </CardSection>
  )
}
