import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import type { ModalHeaderProps } from '@sevenrooms/core/ui-kit/layout'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'

interface CancelModalActionPanelProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onCancelClick?: () => void
  isCancelDisabled?: boolean
}

export function CancelModalActionPanel({ closeHref, onCancelClick, isCancelDisabled }: CancelModalActionPanelProps) {
  const { formatMessage } = useLocales()

  return (
    <>
      <Button data-test="cancel-keep-reservation-button" fullWidth variant="secondary" href={closeHref}>
        {formatMessage(reservationWidgetMessages.resCancelPageKeepResButton)}
      </Button>
      <Button
        data-test="cancel-cancel-reservation-button"
        fullWidth
        variant="primary-warning"
        onClick={onCancelClick}
        disabled={isCancelDisabled}
      >
        {formatMessage(reservationWidgetMessages.resCancelPageCancelButtonLabel)}
      </Button>
    </>
  )
}
