import { useLocales } from '@sevenrooms/core/locales'
import { Button, IconButton } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Box, HStack, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { upgradesBannerMessages } from './upgradesBannerMessages'

export interface UpgradesBannerProps {
  onConfirm: () => void
}

export function UpgradesBanner({ onConfirm }: UpgradesBannerProps) {
  const { formatMessage } = useLocales()
  const isMobile = useMaxWidthBreakpoint('s')
  return (
    <CardSection
      data-test="upgrades-banner"
      pt={isMobile ? 'm' : 'sm'}
      pb={isMobile ? 'm' : 'sm'}
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <HStack width="100%" spacing="sm" pr="lm" pl="lm" alignItems="center" justifyContent="space-between">
        <Text color="primaryFont" data-test="title" textStyle={isMobile ? 'h3' : 'h2'} textOverflow={isMobile ? 3 : 2}>
          {formatMessage(upgradesBannerMessages.title)}
        </Text>
        {isMobile && (
          <IconButton
            borderType="round"
            onClick={onConfirm}
            icon="VMSWeb-chevron-right"
            size="s"
            aria-label="view-upgrades-button-mobile"
            data-test="view-upgrades-button-mobile"
          />
        )}
      </HStack>

      {!isMobile && (
        <Box mr="sm">
          <Button variant="primary" onClick={onConfirm} aria-label="view-upgrades-button" data-test="view-upgrades-button">
            {formatMessage(upgradesBannerMessages.viewUpgradesButton)}
          </Button>
        </Box>
      )}
    </CardSection>
  )
}
