import { AvailabilityService, RTKUtils } from '@sevenrooms/core/api'
import { baseReservationWidgetApi } from './BaseReservationWidgetApi'

const availabilityApiTags = ['Availability']

export const availabilityApi = baseReservationWidgetApi.enhanceEndpoints({ addTagTypes: availabilityApiTags }).injectEndpoints({
  endpoints: builder => ({
    getSingleDayAvailability: builder.query({
      providesTags: availabilityApiTags,
      queryFn: RTKUtils.rtkQuery(AvailabilityService.getSingleDayAvailability),
    }),
    getRangeAvailability: builder.query({
      providesTags: availabilityApiTags,
      queryFn: RTKUtils.rtkQuery(AvailabilityService.getRange),
    }),
  }),
})

export const { useGetSingleDayAvailabilityQuery, useGetRangeAvailabilityQuery } = availabilityApi
