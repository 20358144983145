import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly, TimeInterval } from '@sevenrooms/core/timepiece'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { priorityAlertDetailsMessages } from './priorityAlertDetailsMessages'
import { PriorityAlertDetailsRow } from './PriorityAlertDetailsRow'

export interface PriorityAlertDetailsProps {
  partySize?: string
  date?: string
  startTime?: string
  endTime?: string
}

export function PriorityAlertDetails({ date, startTime, endTime, partySize }: PriorityAlertDetailsProps) {
  const { formatMessage } = useLocales()
  const formattedDate = DateOnly.fromSafe(date)?.formatNYearFMonthNDayFWeek()
  const formattedTimeRange = TimeInterval.fromIso(startTime, endTime)?.formatSTime()

  return (
    <VStack spacing="m" data-test="alert-details">
      <Text textStyle="h3" data-test="alert-details-header">
        {formatMessage(priorityAlertDetailsMessages.header)}
      </Text>
      <VStack spacing="m">
        {formattedDate && <PriorityAlertDetailsRow icon="GX-calendar-range" text={formattedDate} data-test="alert-details-date" />}
        {formattedTimeRange && (
          <PriorityAlertDetailsRow icon="GX-clock-time-five" text={formattedTimeRange} data-test="alert-details-time-range" />
        )}
        {partySize && (
          <PriorityAlertDetailsRow
            icon="GX-account-multiple"
            text={`${formatMessage(priorityAlertDetailsMessages.partySize)} ${partySize}`}
            data-test="alert-details-party-size"
          />
        )}
      </VStack>
    </VStack>
  )
}
