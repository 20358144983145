import { type ForwardedRef, forwardRef, useState } from 'react'
import styled from 'styled-components'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { IconLink, type IconLinkProps } from '@sevenrooms/core/ui-kit/form'
import type { Theme } from '@sevenrooms/core/ui-kit/theme'
import { hexToRgba } from '@sevenrooms/core/ui-kit/utils'

export interface BrandedIconButtonProps extends Omit<IconLinkProps, 'iconColor' | 'size'> {}

function BrandedIconButtonComponent({ ...props }: BrandedIconButtonProps, ref: ForwardedRef<HTMLAnchorElement>) {
  const [isHover, setHover] = useState(false)
  const theme = useTheme() as Theme
  const backgroundColor = hexToRgba(theme.colors.brandColor, 0.1).cssString
  const hoverColor = hexToRgba(theme.colors.brandColor, 0.3).cssString

  return (
    <StyledIconLink
      ref={ref}
      size="s"
      iconColor="brandColor"
      iconSize="lg"
      $backgroundColor={isHover ? hoverColor : backgroundColor}
      onMouseOver={() => setHover(true)}
      onFocus={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onBlur={() => setHover(false)}
      {...props}
    />
  )
}

const StyledIconLink = styled(IconLink)<BrandedIconButtonProps & { $backgroundColor: string }>`
  &&& {
    background-color: ${props => props.$backgroundColor};
    border-color: ${props => props.$backgroundColor};
  }
`

export const BrandedIconButton = forwardRef(BrandedIconButtonComponent)
