import { defineMessages } from '@sevenrooms/core/locales'

export const upgradesGroupMessages = defineMessages({
  resWidgetUpgradePageEachLabel: {
    id: 'resWidgetUpgradePageEachLabel',
    defaultMessage: 'each',
  },
  resWidgetUpgradeViewMoreButton: {
    id: 'resWidgetUpgradeViewMoreButton',
    defaultMessage: 'View more',
  },
  resWidgetUpgradeViewLessButton: {
    id: 'resWidgetUpgradeViewLessButton',
    defaultMessage: 'View less',
  },
  resWidgetUpgradeSelectionRequired: {
    id: 'resWidgetUpgradeSelectionRequired',
    defaultMessage: 'A selection is required to check out',
  },
})
