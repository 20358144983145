import type { Upgrade } from '@sevenrooms/core/domain'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { reservationAvailabilityModalMessages } from './reservationAvailabilityModalMessages'

export interface ReservationAvailabilityModalUpgradesProps {
  upgrades?: Upgrade[]
  currencyCode: string
  isFeesInPriceDisplayed: boolean
}

export function ReservationAvailabilityModalUpgrades({
  upgrades,
  currencyCode,
  isFeesInPriceDisplayed = false,
}: ReservationAvailabilityModalUpgradesProps) {
  const { formatMessage } = useLocales()

  if (!upgrades?.length) {
    return null
  }
  return (
    <VStack spacing="xs" data-test="timeslot-upgrades">
      <Text textStyle="body1Bold" data-test="timeslot-upgrades-title">
        {formatMessage(reservationAvailabilityModalMessages.upgradesTitle)}
      </Text>
      <Text textStyle="body1" data-test="modal-secondary-text">
        <VStack spacing="xs">
          {upgrades.map(upgrade => (
            <HStack key={upgrade.id} data-test={`upgrade-${upgrade.id}`} spacing="sm">
              <Text textStyle="body1" data-test="upgrade-count">
                {formatMessage(reservationAvailabilityModalMessages.upgradesCount, {
                  count: upgrade.quantity ?? 1,
                })}
              </Text>
              <div>
                <Text textStyle="body1" data-test="upgrade-name-price">
                  {upgrade.isPriceHidden
                    ? upgrade.name
                    : formatMessage(reservationAvailabilityModalMessages.upgradeCost, {
                        name: upgrade.name,
                        price: FormatService.formatCurrency(upgrade.price + (isFeesInPriceDisplayed ? upgrade.fees ?? 0 : 0), currencyCode),
                      })}
                </Text>
                {isFeesInPriceDisplayed && upgrade.fees !== undefined && upgrade.fees > 0 && !upgrade.isPriceHidden && (
                  <SecondaryText data-test="upgrade-name-fees">
                    &nbsp;
                    {formatMessage(reservationWidgetMessages.resWidgetIncludesFees, {
                      fees: FormatService.formatCurrency(upgrade.fees, currencyCode),
                    })}
                  </SecondaryText>
                )}
              </div>
            </HStack>
          ))}
        </VStack>
      </Text>
    </VStack>
  )
}
