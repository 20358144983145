import { RTKUtils, ManageReservationService } from '@sevenrooms/core/api'
import type { ManageReservationCancelResult, ManageReservationDetails, UpdateGuestPreferencesRequest } from '@sevenrooms/core/domain'
import { baseReservationWidgetApi } from './BaseReservationWidgetApi'

export const ManageReservationApiTags = ['ManageReservationDetails']

export const manageReservationDetailsApi = baseReservationWidgetApi
  .enhanceEndpoints({ addTagTypes: ManageReservationApiTags })
  .injectEndpoints({
    endpoints: builder => ({
      getManageReservationDetails: builder.query<ManageReservationDetails, { token: string; lang?: string }>({
        providesTags: ManageReservationApiTags,
        queryFn: RTKUtils.rtkQuery(ManageReservationService.getReservationDetails),
      }),
      cancelReservation: builder.mutation<ManageReservationCancelResult, { token: string; lang?: string }>({
        queryFn: RTKUtils.rtkQuery(ManageReservationService.cancelReservation),
        async onQueryStarted({ token, lang }, { dispatch, queryFulfilled }) {
          try {
            const { data: result } = await queryFulfilled
            dispatch(
              manageReservationDetailsApi.util.updateQueryData('getManageReservationDetails', { token, lang }, details => {
                Object.assign(details, {
                  ...details,
                  actualFlags: result.actualFlags,
                })
              })
            )
          } catch {
            dispatch(manageReservationDetailsApi.util.invalidateTags(ManageReservationApiTags))
          }
        },
      }),
      updateGuestPreferences: builder.mutation<boolean, { token: string; lang?: string; data: UpdateGuestPreferencesRequest }>({
        queryFn: RTKUtils.rtkQuery(ManageReservationService.updateGuestPreferences),
        async onQueryStarted({ token, lang, data }, { dispatch, queryFulfilled }) {
          try {
            const resp = await queryFulfilled
            if (resp.data) {
              dispatch(
                manageReservationDetailsApi.util.updateQueryData('getManageReservationDetails', { token, lang }, details => {
                  Object.assign(details, {
                    actual: {
                      ...details.actual,
                      ...data,
                    },
                  })
                })
              )
            }
          } catch (e) {
            dispatch(manageReservationDetailsApi.util.invalidateTags(ManageReservationApiTags))
          }
        },
      }),
    }),
  })

export const {
  useGetManageReservationDetailsQuery,
  useLazyGetManageReservationDetailsQuery,
  useCancelReservationMutation,
  useUpdateGuestPreferencesMutation,
} = manageReservationDetailsApi
