import { useCallback } from 'react'
import { ClientPhotoService } from '@sevenrooms/core/api'
import type { ImageObject } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useSectionController } from '@sevenrooms/core/ui-kit/layout'
import { AccordionImageInput, useAccordionImage } from './AccordionInput'
import { guestPreferencesMessages } from './guestPreferencesMessages'

export interface GuestImageInputProps {
  sectionName: string
  actualId?: string
  clientId?: string
  venueId?: string
  clientPhotoUrl?: string
  clientDisplayName?: string
  saveText: string
  onError?: () => void
  'data-test'?: string
}

export function GuestImageInput({
  sectionName,
  actualId,
  clientId,
  venueId,
  clientDisplayName,
  clientPhotoUrl,
  saveText,
  onError,
  'data-test': dataTest,
}: GuestImageInputProps) {
  const { formatMessage } = useLocales()
  const guestPhoto = useCallback(async () => {
    if (!clientPhotoUrl) {
      return undefined
    }
    try {
      const photoFile = await ClientPhotoService.getPhoto({ photoUrl: clientPhotoUrl, clientDisplayName })
      return { fileData: photoFile, name: photoFile.name, rawUrl: URL.createObjectURL(photoFile) }
    } catch (e) {
      onError?.()
      return undefined
    }
  }, [clientDisplayName, clientPhotoUrl, onError])

  const savePhoto = useCallback(
    async (img: ImageObject) => {
      try {
        return ClientPhotoService.uploadPhoto({ clientId, image: img })
      } catch (e) {
        onError?.()
        return false
      }
    },
    [clientId, onError]
  )

  const deletePhoto = useCallback(async () => {
    try {
      return ClientPhotoService.deletePhoto({ actualId, venueId })
    } catch (e) {
      onError?.()
      return false
    }
  }, [actualId, venueId, onError])

  const { tryToggleCollapse, collapsed } = useSectionController(sectionName, { initiallyCollapsed: !clientPhotoUrl })

  const { accordionImage, isDirty, onAccordionImageChange, onAccordionImageDelete, onAccordionImageSave, onAccordionImageCancel } =
    useAccordionImage(guestPhoto, !!clientPhotoUrl, savePhoto, deletePhoto)

  return (
    <AccordionImageInput
      data-test={dataTest}
      collapsed={collapsed}
      onToggle={tryToggleCollapse}
      isDirty={isDirty}
      onImageChange={onAccordionImageChange}
      image={accordionImage}
      onSave={onAccordionImageSave}
      onDelete={onAccordionImageDelete}
      onCancel={onAccordionImageCancel}
      title={formatMessage(guestPreferencesMessages.guestImageHeader)}
      editText={formatMessage(guestPreferencesMessages.guestImageEditButton)}
      noImageEditText={formatMessage(guestPreferencesMessages.guestImageUploadButton)}
      saveText={saveText}
      deleteText={formatMessage(guestPreferencesMessages.guestImageDeleteButton)}
      replaceText={formatMessage(guestPreferencesMessages.guestImageReUploadButton)}
      cancelText={formatMessage(guestPreferencesMessages.guestPreferencesCancel)}
    />
  )
}
