import { type ManageReservationCalendarLinks, CalendarOptions, CalendarOptionTypes } from '@sevenrooms/core/domain'
import { Menu, MenuItem, IconButton } from '@sevenrooms/core/ui-kit/form'
import { Link } from '@sevenrooms/core/ui-kit/typography'

export interface ReservationDetailsCalendarProps {
  calendarLinks: ManageReservationCalendarLinks
  'data-test'?: string
}

export function ReservationDetailsCalendar({ 'data-test': dataTest, calendarLinks }: ReservationDetailsCalendarProps) {
  return (
    <Menu
      alignment="bottomRight"
      anchor={<IconButton borderType="none-round" data-test={dataTest} iconSize="lg" icon="GX-calendar-plus" />}
    >
      {CalendarOptions.filter(key => calendarLinks[key]).map(key => (
        <MenuItem data-test={`reservation-details-${key}-calendar-link`} key={key}>
          <Link isExternal to={calendarLinks[key]} color="primaryFont">
            {CalendarOptionTypes[key]}
          </Link>
        </MenuItem>
      ))}
    </Menu>
  )
}
