import { defineMessages } from '@sevenrooms/core/locales'

export const guestPreferencesMessages = defineMessages({
  guestPreferencesHeader: {
    id: 'preferences.header',
    defaultMessage: "If you'd like, tell us more about yourself and your preferences so we can provide a better experience.",
  },
  guestSpecialOccasion: {
    id: 'preferences.specialOccasion',
    defaultMessage: 'Is this a Special Occasion',
  },
  guestDietaryRestriction: {
    id: 'preferences.guest.dietaryRestrictions',
    defaultMessage: 'Do you have any dietary restrictions?',
  },
  partyDietaryRestriction: {
    id: 'preferences.party.dietaryRestrictions',
    defaultMessage: 'Does your party have any dietary restrictions?',
  },
  guestOtherInfo: {
    id: 'preferences.otherInfo',
    defaultMessage: 'Anything else we should know?',
  },
  guestImageHeader: {
    id: 'preferences.image.header',
    defaultMessage: 'Upload your profile photo so we can greet you upon your arrival.',
  },
  guestImageUploadButton: {
    id: 'preferences.image.upload',
    defaultMessage: 'Upload Photo From Device',
  },
  guestImageEditButton: {
    id: 'preferences.image.edit',
    defaultMessage: 'Edit Photo',
  },
  guestImageDeleteButton: {
    id: 'preferences.image.delete',
    defaultMessage: 'Remove Photo',
  },
  guestImageReUploadButton: {
    id: 'preferences.image.reupload',
    defaultMessage: 'Re-upload Photo',
  },
  guestChampagnePreference: {
    id: 'preferences.guest.champagne',
    defaultMessage: 'Champagne Preference',
  },
  guestLiquorPreference: {
    id: 'preferences.guest.liquor',
    defaultMessage: 'Liquor Preference',
  },
  guestPreferencesSave: {
    id: 'preferences.save',
    defaultMessage: 'Save',
  },
  guestPreferencesCancel: {
    id: 'preferences.cancel',
    defaultMessage: 'Cancel',
  },
})
