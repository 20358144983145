import type { ManageReservationRefundableCharge } from '@sevenrooms/core/domain'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { type ModalHeaderProps, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenue } from '../../../hooks'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { CancelModalActionPanel } from '../CancelModalActionPanel'
import { NotificationModal } from '../NotificationModal'
import type { ReactNode } from 'react'

interface RefundModalProps {
  charges: ManageReservationRefundableCharge[]
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onCancelClick?: () => void
  isCancelDisabled?: boolean
  cancellationPolicy?: ReactNode
}

export function RefundModal({ cancellationPolicy, charges, closeHref, onCancelClick, isCancelDisabled }: RefundModalProps) {
  const { formatMessage } = useLocales()
  const venue = useVenue()

  return (
    <NotificationModal
      type="info"
      title={formatMessage(reservationWidgetMessages.resCancelPageHeader)}
      data-test="refund-modal"
      showIcon
      primary={
        <VStack>
          {charges.map((charge, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Text key={index}>
              {formatMessage(reservationWidgetMessages.resCancelPageRefundText, {
                amount: FormatService.formatCurrency(charge.amountDecimal, venue.currency),
                card_details: FormatService.formatPaymentCard(charge.cardInformation.brand, charge.cardInformation.last4),
              })}
            </Text>
          ))}
        </VStack>
      }
      secondary={cancellationPolicy}
      closeHref={closeHref}
      actionsPanel={<CancelModalActionPanel closeHref={closeHref} onCancelClick={onCancelClick} isCancelDisabled={isCancelDisabled} />}
    />
  )
}
