import { AvailabilityService, RTKUtils } from '@sevenrooms/core/api'
import type { ListAvailabilityDatesRequest, ReservationWidget } from '@sevenrooms/core/domain'
import { Formats, legacyJSDateAdapter } from '@sevenrooms/core/timepiece'
import { baseReservationWidgetApi } from '../../store/hooks/BaseReservationWidgetApi'

const availabilityDateApiTags = ['AvailabilityDates']

export const { useListAvailabilityDatesQuery, useGroupAvailabilityDatesQuery } = baseReservationWidgetApi
  .enhanceEndpoints({ addTagTypes: availabilityDateApiTags })
  .injectEndpoints({
    endpoints: builder => ({
      listAvailabilityDates: builder.query<string[], ListAvailabilityDatesRequest>({
        providesTags: availabilityDateApiTags,
        queryFn: RTKUtils.rtkQuery(AvailabilityService.getDates),
      }),
      groupAvailabilityDates: builder.query<string[], { venuesInfo: ReservationWidget.VenueInfo; maxDaysOut: number }>({
        providesTags: availabilityDateApiTags,
        queryFn: RTKUtils.rtkQuery(({ venuesInfo, maxDaysOut }) => {
          const promises: Promise<string[]>[] = []
          venuesInfo?.venues.forEach(v => {
            promises.push(
              AvailabilityService.getDates({
                venue: v.urlKey,
                startDate: legacyJSDateAdapter(new Date(), Formats.MonthDayYearByDash),
                numDays: maxDaysOut,
              })
            )
          })

          return Promise.all(promises).then(availabilityForAllVenues => {
            const uniqueAvailability = new Set<string>()
            availabilityForAllVenues.forEach(venueAvailability => {
              venueAvailability.forEach(availabilityDate => {
                uniqueAvailability.add(availabilityDate)
              })
            })
            return Array.from(uniqueAvailability)
          })
        }),
      }),
    }),
  })
