import { useState } from 'react'
import sanitizeHtml from 'sanitize-html'
import styled from 'styled-components'
import { useLocales } from '@sevenrooms/core/locales'
import { HTMLContent, GFL_ALLOWED_TAGS } from '@sevenrooms/core/ui-kit/core'
import { CardButton } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, HStack, Window } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { SimpleModal } from './SimpleModal'
import { specialAttentionMessages } from './specialAttentionMessages'

interface SpecialAttentionProps {
  'data-test'?: string
}

export function SpecialAttention({ 'data-test': dataTest = 'special-attention' }: SpecialAttentionProps) {
  const { formatMessage } = useLocales()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const specialAttentionBodyMessageEmpty = !sanitizeHtml(formatMessage(specialAttentionMessages.body), { allowedTags: [] }).trim()
  const specialAttentionTitleMessageEmpty = !sanitizeHtml(formatMessage(specialAttentionMessages.header), { allowedTags: [] }).trim()

  if (specialAttentionBodyMessageEmpty && specialAttentionTitleMessageEmpty) {
    return null
  }
  return (
    <HStack justifyContent="center">
      {specialAttentionBodyMessageEmpty ? (
        <StyledBox
          backgroundColor="secondaryBackground"
          borderRadius="s"
          data-test={dataTest}
          p="s"
          tabIndex={0}
          aria-label={formatMessage(specialAttentionMessages.header)}
        >
          <HeaderText />
        </StyledBox>
      ) : (
        <CardButton data-test={dataTest} onClick={() => setIsModalOpen(true)} fullWidth={false}>
          <HStack width="100%" p="s sm s s" spacing="xs" justifyContent="space-between" alignItems="center">
            <Box>
              <HeaderText />
            </Box>
            <Box ml="s">
              <Icon name="GX-info-outline" />
            </Box>
          </HStack>
        </CardButton>
      )}
      <Window active={isModalOpen}>
        <SimpleModal
          data-test="special-attention-modal"
          title={formatMessage(specialAttentionMessages.header)}
          body={<HTMLContent allowedTags={GFL_ALLOWED_TAGS} content={formatMessage(specialAttentionMessages.body)} />}
          onClose={() => setIsModalOpen(false)}
        />
      </Window>
    </HStack>
  )
}

function HeaderText() {
  const { formatMessage } = useLocales()
  return (
    <Text textStyle="body1" textOverflow="twoLinesEllipsis" textAlign="center">
      {formatMessage(specialAttentionMessages.header)}
    </Text>
  )
}

const StyledBox = styled(Box)`
  &:focus-visible {
    outline: ${props => props.theme.borderWidth.m} solid ${props => props.theme.colors.primaryHover};
  }
`
