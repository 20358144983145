import { defineMessages } from '@sevenrooms/core/locales'

export const priorityAlertDetailsMessages = defineMessages({
  header: {
    id: 'priorityAlert.details.partySize.header',
    defaultMessage: 'You have a Priority Alert for:',
  },
  partySize: {
    id: 'priorityAlert.details.partySize',
    defaultMessage: 'Party of',
  },
})
