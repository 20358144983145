import styled from 'styled-components'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, type ButtonProps } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import type { CSSProperties } from 'react'

export interface AlertMeButtonProps {
  variant: 'primary' | 'secondary'
  width: CSSProperties['width']
  size: ButtonProps['size']
  onClick: () => void
}

export function AlertMeButton({ onClick, variant, width = '100%', size }: AlertMeButtonProps) {
  const { formatMessage } = useLocales()

  return (
    <StyledButton size={size} noPadding width={width} variant={variant} data-test="alert-me-button" onClick={onClick}>
      <Icon name="GX-bell-ring" size="lg" />
      <Box ml="xs">{formatMessage(reservationWidgetMessages.resWidgetPriorityAlertsSetAlertButton)}</Box>
    </StyledButton>
  )
}

const StyledButton = styled(Button)<{ width: CSSProperties['width'] }>`
  width: ${props => props.width};
`
