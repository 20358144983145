import { RTKUtils, RedemptionHubService } from '@sevenrooms/core/api'
import { baseReservationWidgetApi } from './BaseReservationWidgetApi'
import { ManageReservationApiTags } from './ManageReservationDetailsApi'

export const redemptionHubApi = baseReservationWidgetApi.enhanceEndpoints({ addTagTypes: ManageReservationApiTags }).injectEndpoints({
  endpoints: builder => ({
    checkGiftCardBalance: builder.mutation({
      queryFn: RTKUtils.rtkQuery(RedemptionHubService.checkBalance),
    }),
  }),
})

export const { useCheckGiftCardBalanceMutation } = redemptionHubApi
