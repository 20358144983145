import type {
  PromoCode,
  ReservationWidget,
  GuestFacingUpgradeCategory,
  AvailabilityTime,
  AvailabilityAutomaticUpsell,
  GuestFacingUpgradeInventory,
} from '@sevenrooms/core/domain'
import { type SchemaCategories, mapAvailabilityUpgrades } from '../../utils'

interface GetUpgradeTotalsParams {
  upgrades: {
    totalPrice: number
    isChargingServiceCharge: boolean
    isChargingTax: boolean
    isChargingGratuity: boolean
    serviceChargePercentage: number
    gratuityPercentage: number
    serviceChargeType?: GuestFacingUpgradeCategory['serviceChargeType']
    gratuityChargeType?: GuestFacingUpgradeCategory['gratuityChargeType']
    taxGroupId?: string | null
  }[]
  taxGroups: ReservationWidget.TaxGroup[]
  defaultServiceCharge?: number
  usedPromoAmount?: number
  clientSelectedGratuity: number | null
  defaultGratuity?: number
  promoCode?: PromoCode
  isRequiredUpgrade?: boolean
}

export const getUpgradeTotals = ({
  upgrades,
  taxGroups,
  defaultServiceCharge = 0,
  usedPromoAmount = 0,
  clientSelectedGratuity,
  defaultGratuity = 0,
  promoCode,
  isRequiredUpgrade = false,
}: GetUpgradeTotalsParams) =>
  upgrades.reduce<{
    upsellsBaseAmount: number
    upsellsPromoDiscount: number
    upsellsServiceCharge: number
    upsellsTax: number
    upsellsSelectedGratuity: number
    upsellsGratuity: number
    hasSelectableGratuity: boolean
  }>(
    (
      accumulator,
      {
        totalPrice,
        isChargingServiceCharge,
        isChargingTax,
        isChargingGratuity,
        serviceChargePercentage,
        serviceChargeType,
        taxGroupId,
        gratuityChargeType,
        gratuityPercentage,
      }
    ) => {
      accumulator.upsellsBaseAmount += totalPrice
      const upsellPromoDiscount = getPromoCodeDiscount({
        amount: totalPrice,
        usedAmount: accumulator.upsellsPromoDiscount + usedPromoAmount,
        promoCode,
        amountSource: isRequiredUpgrade ? 'BASE' : 'SELECTED',
      })
      accumulator.upsellsPromoDiscount += upsellPromoDiscount
      const upsellServiceCharge = isChargingServiceCharge
        ? ((totalPrice - upsellPromoDiscount) *
            (serviceChargeType === 'SPECIFIC_SERVICE_CHARGE' ? serviceChargePercentage : defaultServiceCharge)) /
          100
        : 0
      accumulator.upsellsServiceCharge += upsellServiceCharge
      if (isChargingTax) {
        const taxRate = getTaxRates({ taxGroupId, taxGroups }) / 100
        accumulator.upsellsTax += (totalPrice - upsellPromoDiscount + upsellServiceCharge) * taxRate
      }
      if (isChargingGratuity && gratuityChargeType) {
        switch (gratuityChargeType) {
          case 'CLIENT_GRATUITY':
            accumulator.hasSelectableGratuity = true
            accumulator.upsellsSelectedGratuity += ((totalPrice - upsellPromoDiscount) * (clientSelectedGratuity ?? 0)) / 100
            break
          case 'DEFAULT_GRATUITY':
            accumulator.upsellsGratuity += ((totalPrice - upsellPromoDiscount) * defaultGratuity) / 100
            break
          case 'SPECIFIC_GRATUITY':
          default:
            accumulator.upsellsGratuity += ((totalPrice - upsellPromoDiscount) * gratuityPercentage) / 100
            break
        }
      }
      return accumulator
    },
    {
      upsellsBaseAmount: 0,
      upsellsPromoDiscount: 0,
      upsellsServiceCharge: 0,
      upsellsTax: 0,
      upsellsSelectedGratuity: 0,
      upsellsGratuity: 0,
      hasSelectableGratuity: false,
    }
  )

export const getTaxRates = ({
  taxGroupId,
  taxGroups,
  defaultTax = 0,
}: {
  taxGroupId: string | null | undefined
  taxGroups: ReservationWidget.TaxGroup[]
  defaultTax?: number
}) => taxGroups.find(({ id }) => id === taxGroupId)?.taxRate ?? defaultTax

export const getTimeSlotTotals = ({
  selectedTimeSlot,
  partySize,
  promoCode,
  clientSelectedGratuity,
}: {
  selectedTimeSlot?: AvailabilityTime
  partySize?: number
  promoCode?: PromoCode
  clientSelectedGratuity: number | null
}) => {
  if (!selectedTimeSlot || !partySize) {
    return {}
  }
  const timeSlotBaseAmount = getTimeslotAmount({ selectedTimeSlot, partySize })
  const timeSlotPromoDiscount = getPromoCodeDiscount({ amount: timeSlotBaseAmount, usedAmount: 0, promoCode, amountSource: 'BASE' })
  const timeSlotServiceCharge = selectedTimeSlot.applyServiceCharge
    ? ((timeSlotBaseAmount - timeSlotPromoDiscount) * (selectedTimeSlot.serviceCharge ?? 0)) / 100
    : 0
  const timeSlotTax = ((timeSlotBaseAmount + timeSlotServiceCharge - timeSlotPromoDiscount) * (selectedTimeSlot.taxRate ?? 0)) / 100
  if (!selectedTimeSlot.applyGratuityCharge || !selectedTimeSlot.gratuityType) {
    return {
      timeSlotBaseAmount,
      timeSlotPromoDiscount,
      timeSlotServiceCharge,
      timeSlotTax,
    }
  }
  if (selectedTimeSlot.gratuityType === 'CLIENT_GRATUITY') {
    const timeSlotSelectedGratuity = ((timeSlotBaseAmount - timeSlotPromoDiscount) * (clientSelectedGratuity ?? 0)) / 100
    return {
      timeSlotBaseAmount,
      timeSlotPromoDiscount,
      timeSlotServiceCharge,
      timeSlotTax,
      timeSlotSelectedGratuity,
    }
  }
  const gratuity =
    (selectedTimeSlot.gratuityType === 'DEFAULT_GRATUITY' ? selectedTimeSlot?.defaultGratuity : selectedTimeSlot?.gratuity) ?? 0
  const timeSlotGratuity = ((timeSlotBaseAmount - timeSlotPromoDiscount) * gratuity) / 100
  return {
    timeSlotBaseAmount,
    timeSlotPromoDiscount,
    timeSlotServiceCharge,
    timeSlotTax,
    timeSlotGratuity,
  }
}

export const getPromoCodeDiscount = ({
  amount,
  usedAmount = 0,
  promoCode,
  amountSource,
}: {
  amount: number
  usedAmount?: number
  promoCode?: PromoCode
  amountSource?: 'BASE' | 'SELECTED'
}) => {
  if (!promoCode) {
    return 0
  }
  const { promoType, promoValue, promoValueCap, promoComponent } = promoCode
  if (promoComponent !== 'ALL') {
    if (promoComponent === 'BASE_PRICE' && amountSource === 'SELECTED') {
      return 0
    }
    if (promoComponent === 'UPGRADES' && amountSource === 'BASE') {
      return 0
    }
  }
  if (promoType === 'DOLLAR_DISCOUNT') {
    if (usedAmount >= promoValue) {
      return 0
    }
    return (promoValue < amount ? promoValue : amount) - usedAmount
  }
  if (promoType === 'PERCENT_DISCOUNT') {
    return (promoValue * amount) / 100
  }
  if (promoType === 'PERCENT_DISCOUNT_WITH_CAP' && promoValueCap) {
    const maxValue = (promoValue * amount) / 100
    if (usedAmount >= promoValueCap) {
      return 0
    }
    return maxValue > promoValueCap - usedAmount ? promoValueCap - usedAmount : maxValue
  }
  return 0
}

const DURATION_15_MINUTES = 15

export const getTimeslotAmount = ({
  selectedTimeSlot: { chargeType, duration, cost },
  partySize,
}: {
  selectedTimeSlot: AvailabilityTime
  partySize: number
}) => {
  if (!cost) {
    return 0
  }

  switch (chargeType) {
    case 'person':
      return cost * partySize
    case 'person_slot':
      return cost * partySize * (duration / DURATION_15_MINUTES)
    case 'reservation':
      return cost
    case 'reservation_slot':
      return cost * (duration / DURATION_15_MINUTES)
    default:
      return 0
  }
}

export const getSelectedUpgrades = ({
  categories,
  inventories,
}: {
  inventories: GuestFacingUpgradeInventory[]
  categories?: SchemaCategories
}) => {
  if (!inventories || inventories.length === 0) {
    return []
  }
  const upgradesQuantities = getSelectedUpgradesQuantities({ categories })
  const selectedUpgradesIds = Object.keys(upgradesQuantities)

  return inventories
    .filter(({ id }) => selectedUpgradesIds.includes(id))
    .map(inventory => {
      const category = categories?.[inventory.categoryId]
      const quantity = upgradesQuantities[inventory.id] ?? 1
      return {
        ...inventory,
        quantity,
        taxGroupId: category?.taxGroupId,
        isChargingTax: category?.isChargingTax ?? false,
        isChargingServiceCharge: category?.isChargingServiceCharge ?? false,
        isChargingGratuity: category?.isChargingGratuity ?? false,
        gratuityPercentage: category?.gratuityPercentage ?? 0,
        gratuityChargeType: category?.gratuityChargeType,
        serviceChargeType: category?.serviceChargeType,
        serviceChargePercentage: category?.serviceChargePercentage ?? 0,
        totalPrice: inventory.price * quantity,
      }
    })
    .filter(({ quantity }) => quantity)
}

export const getSelectedUpgradesQuantities = ({ categories }: { categories?: SchemaCategories }) => {
  if (!categories || Object.keys(categories).length === 0) {
    return {}
  }

  return Object.values(categories)
    .map(category => category.upgrades)
    .reduce((accumulator, currentValue) => ({ ...accumulator, ...currentValue }), {})
}

export const getIncludedUpgrades = ({
  partySize,
  upgrades,
  inventories,
  categories,
}: {
  partySize: number
  upgrades?: AvailabilityAutomaticUpsell[]
  inventories?: GuestFacingUpgradeInventory[]
  categories?: GuestFacingUpgradeCategory[]
}) =>
  mapAvailabilityUpgrades(partySize, upgrades, inventories).map(upgrade => {
    const upgradeCategory = categories?.find(category => category.id === upgrade.categoryId)
    return {
      ...upgrade,
      totalPrice: upgrade.price * upgrade.quantity,
      taxGroupId: upgradeCategory?.taxGroupId,
      isChargingTax: upgradeCategory?.isChargingTax ?? false,
      isChargingServiceCharge: upgradeCategory?.isChargingServiceCharge ?? false,
      isChargingGratuity: upgradeCategory?.isChargingGratuity ?? false,
      gratuityPercentage: upgradeCategory?.gratuityPercentage ?? 0,
      gratuityChargeType: upgradeCategory?.gratuityChargeType,
      serviceChargeType: upgradeCategory?.serviceChargeType,
      serviceChargePercentage: upgradeCategory?.serviceChargePercentage ?? 0,
    }
  })
