import { SkeletonBox } from '../SkeletonBox'

export function ReservationBreadcrumbLoader() {
  return (
    <SkeletonBox
      data-test="reservation-breadcrumb-loader"
      height="48px"
      width="100%"
      backgroundColor="primaryBackground"
      borderWidth="none"
      borderRadius="none"
    />
  )
}
