import { useTheme } from '@sevenrooms/core/ui-kit'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { Tag } from '@sevenrooms/core/ui-kit/typography'

export interface PreferenceOption {
  id: string
  label: string
  selected: boolean
  'data-test'?: string
}

export interface AccordionTagProps extends PreferenceOption {
  onClick: (id: string, selected: boolean) => void
}

export function AccordionTag({ id, label, selected, 'data-test': dataTest, onClick }: AccordionTagProps) {
  const { colors } = useTheme()
  return (
    <Box m="s">
      <Tag
        aria-label={`Select ${label}`}
        selected={selected}
        backgroundColor={selected ? colors.primaryButton : colors.secondaryBackground}
        onClick={() => onClick(id, !selected)}
        data-test={dataTest && `${dataTest}-${label}`}
      >
        {label}
      </Tag>
    </Box>
  )
}
