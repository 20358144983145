import React from 'react'
import styled from 'styled-components'
import { Label } from '@sevenrooms/core/ui-kit/form'
import { Stack, type StackProps } from '@sevenrooms/core/ui-kit/layout'
import { RadioTile, type RadioTileProps } from './RadioTile/RadioTile'

export interface RadioTileGroupProps<T extends number | string> {
  onChange: (value: T) => void
  value: T
  name: string
  options: Omit<RadioTileProps<T>, 'name' | 'onChange' | 'hideIcon' | 'showBorder'>[]
  label: string
  hideLegend?: boolean
  direction?: StackProps['flexDirection']
  spacing?: StackProps['spacing']
  hideIcon?: RadioTileProps<T>['hideIcon']
  showBorder?: boolean
  'data-test'?: string
}

export function RadioTileGroup<T extends number | string>({
  name,
  onChange,
  value,
  direction = 'column',
  spacing,
  options,
  label,
  hideLegend,
  hideIcon,
  showBorder = true,
  'data-test': testId,
}: RadioTileGroupProps<T>) {
  return (
    <RadioTileFieldset role="radiogroup" aria-label={label} data-test={testId}>
      {!hideLegend && <Label primary={label} />}
      <Stack flexDirection={direction} spacing={spacing}>
        {options.map(option => (
          <RadioTile
            {...option}
            autoFocus={option.value === value}
            aria-checked={option.value === value}
            checked={option.value === value}
            id={option.id}
            key={option.id}
            label={option.label}
            name={name}
            onChange={onChange}
            value={option.value}
            hideIcon={hideIcon}
            showBorder={showBorder}
          />
        ))}
      </Stack>
    </RadioTileFieldset>
  )
}

const RadioTileFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`
