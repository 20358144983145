import { useLocales } from '@sevenrooms/core/locales'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Text, TruncatingText } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export interface WidgetTitleProps {}

export function WidgetTitle() {
  const { formatMessage } = useLocales()
  const isMobile = useMaxWidthBreakpoint('m')
  const message = formatMessage(reservationWidgetMessages.resWidgetTitle)

  if (!message) {
    return null
  }

  if (!isMobile) {
    return (
      <Text data-test="reservation-widget-title" textAlign="center">
        {message}
      </Text>
    )
  }
  return (
    <TruncatingText
      message={message}
      data-test="reservation-widget-title"
      viewMoreButtonText={formatMessage(reservationWidgetMessages.resConfirmPageViewMore)}
      viewLessButtonText={formatMessage(reservationWidgetMessages.resConfirmPageViewLess)}
      textAlign="center"
      alignItems="center"
    />
  )
}
