import { useMemo } from 'react'
import { z, type ZodSchema } from '@sevenrooms/core/form'

export type SearchForm = ZodSchema<typeof useSearchFormSchema>
export const useSearchFormSchema = () =>
  useMemo(
    () =>
      z.object({
        partySize: z.number(),
        startTime: z.string(),
        haloTimeIntervalMinutes: z.number(),
        startDate: z.date(),
      }),
    []
  )
