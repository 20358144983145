import { useCallback, useMemo } from 'react'
import type { LanguageCode } from '@sevenrooms/core/api'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { FacebookButton, GoogleButton, type GoogleJWT } from '@sevenrooms/core/ui-kit/core'
import { ErrorBoundary } from '@sevenrooms/core/ui-kit/core/ErrorBoundary'
import { useMaxWidthBreakpoint, useThrottledResizeObserver } from '@sevenrooms/core/ui-kit/hooks'
import { Box, StackResponsive } from '@sevenrooms/core/ui-kit/layout'

export interface SocialMediaPanelProps {
  googleApiClientId: string
  locale?: LanguageCode
  onGoogleLogin?: (data: GoogleJWT) => void
  onFacebookClick?: () => void
  disabled?: boolean
}

const GOOGLE_BUTTON_MAX_WIDTH = 400
const GOOGLE_BUTTON_HEIGHT = '40px'
const BUTTON_MIN_WIDTH = 100

export function SocialMediaPanel({ googleApiClientId, locale = 'en', onFacebookClick, onGoogleLogin, disabled }: SocialMediaPanelProps) {
  const theme = useTheme()
  const buttonSpacing = 'sm'
  const { ref: wrapperRef, width: socialMediaContainerWidth } = useThrottledResizeObserver(50, 'border-box')
  const isSmallMobile = useMaxWidthBreakpoint('s')
  const socialMediaButtonWidth = useMemo(() => {
    let width = socialMediaContainerWidth || 0
    if (!isSmallMobile) {
      width = Math.max((width - parseInt(theme.spacing[buttonSpacing])) / 2, BUTTON_MIN_WIDTH)
    }
    return `${Math.min(width, GOOGLE_BUTTON_MAX_WIDTH)}px`
  }, [isSmallMobile, socialMediaContainerWidth, theme.spacing])

  const onGoogleButtonError = useCallback((error: Error) => {
    // eslint-disable-next-line no-console
    console.error(error)
  }, [])

  return (
    <StackResponsive
      ref={wrapperRef}
      justifyContent="center"
      alignItems="center"
      spacing={buttonSpacing}
      breakpoint="s"
      data-test="social-media-panel"
    >
      <ErrorBoundary fallback={<></>} onError={error => onGoogleButtonError(error)}>
        <GoogleButton width={parseInt(socialMediaButtonWidth)} gapiClientId={googleApiClientId} locale={locale} onLogin={onGoogleLogin} />
      </ErrorBoundary>
      <Box width={socialMediaButtonWidth} maxWidth={socialMediaButtonWidth}>
        <FacebookButton onClick={onFacebookClick} fullWidth height={GOOGLE_BUTTON_HEIGHT} disabled={disabled} data-test="facebook-button" />
      </Box>
    </StackResponsive>
  )
}
