import { useMemo } from 'react'
import type { ManageReservationCharge } from '@sevenrooms/core/domain'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { type FlexProps, HStack, VStack, DividerLine, Flex } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { paymentDetailsMessages } from './paymentDetailsMessages'
import { PaymentHistory } from './PaymentHistory'

interface PaymentDetailsProps extends FlexProps {
  lang?: string
  currencyCode: string
  charges?: ManageReservationCharge[]
}

export function PaymentDetails({ lang, currencyCode, charges, ...props }: PaymentDetailsProps) {
  const { formatMessage } = useLocales()
  const displayedCharges = useMemo(() => charges?.filter(charge => charge.isCharge || charge.isRefund) || [], [charges])

  const totalAmount = useMemo(
    () =>
      displayedCharges.reduce((total, charge) => {
        if (charge.isCharge) {
          return total + charge.amount
        } else if (charge.isRefund) {
          return total - charge.amount
        }
        return total
      }, 0),
    [displayedCharges]
  )

  if (!displayedCharges.length) {
    return null
  }

  return (
    <VStack width="100%" spacing="m" data-test="payment-details-card" {...props}>
      <VStack spacing="s">
        <Flex justifyContent="left">
          <Text textStyle="body1Bold" data-test="payment-details-card-title">
            {formatMessage(paymentDetailsMessages.title)}
          </Text>
        </Flex>
        <DividerLine margin="none" />
        <HStack justifyContent="space-between">
          <Text color="secondaryFont" data-test="payment-details-total-title">
            {formatMessage(paymentDetailsMessages.total)}
          </Text>
          <Text color="secondaryFont" data-test="payment-details-total-amount">
            {FormatService.formatCurrencyFromCents(totalAmount, currencyCode)}
          </Text>
        </HStack>
      </VStack>
      <PaymentHistory charges={displayedCharges} lang={lang} currencyCode={currencyCode} />
    </VStack>
  )
}
