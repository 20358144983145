import type { IconButtonProps } from '@sevenrooms/core/ui-kit/form'
import {
  VStack,
  StackResponsive,
  type NotificationBannerType,
  NotificationBanner,
  Modal,
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { ReactNode } from 'react'

export interface NotificationModalProps {
  onClose?: IconButtonProps['onClick']
  closeHref?: string
  type?: NotificationBannerType
  showIcon?: boolean
  title: string
  primary?: ReactNode
  secondary?: ReactNode
  actionsPanel?: ReactNode
  'data-test'?: string
}

export function NotificationModal({
  type,
  title,
  primary,
  showIcon,
  secondary,
  actionsPanel,
  closeHref,
  'data-test': dataTest,
  onClose,
}: NotificationModalProps) {
  return (
    <Modal ariaLabel="Modal" width="100%" data-test={dataTest}>
      <ModalHeader {...(closeHref ? { closeHref } : { onClose })}>
        <ModalTitle title={title} />
      </ModalHeader>
      <ModalBody>
        <VStack mb="lm" spacing="lm">
          {type && primary && <NotificationBanner type={type} showIcon={showIcon} content={primary} data-test="modal-primary-text" />}
          {secondary && (
            <Text color="secondaryFont" data-test="modal-secondary-text" wordBreak="break-word">
              {secondary}
            </Text>
          )}
          {actionsPanel && (
            <StackResponsive spacing="sm" data-test="modal-actions-panel">
              {actionsPanel}
            </StackResponsive>
          )}
        </VStack>
      </ModalBody>
    </Modal>
  )
}
