import { useEffect, useState } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Box, BaseSection, VStack, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useReservationNavigation, type AvailabilityTimeWithUpSellCost, useVenue, useReservationsRoute } from '../../hooks'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import { OtherVenueAvailability, type OtherVenueInfo } from './OtherAvailability'

export interface OtherVenuesAvailabilityProps {
  title: string
  collapsed: boolean
  setCollapsed: (collapsed: boolean) => void
  'data-test'?: string
  otherVenues: OtherVenueInfo[]
  startDate: string
  startTime: string
  haloTimeIntervalMinutes: number
  defaultSearchTime: string
  partySize: number
  selectedLangCode?: string
  startOfDayTime: string
  currencyCode: string
  clientId?: string
  onTimeSlotClick: (slot: AvailabilityTimeWithUpSellCost, venue: OtherVenueInfo) => void
}

export function OtherVenuesAvailability({
  title,
  collapsed = true,
  setCollapsed,
  otherVenues,
  startDate,
  startTime,
  defaultSearchTime,
  haloTimeIntervalMinutes,
  partySize,
  selectedLangCode = 'en',
  startOfDayTime,
  currencyCode,
  onTimeSlotClick,
  clientId,
}: OtherVenuesAvailabilityProps) {
  const { navigateToOtherVenue } = useReservationNavigation()
  const { primaryVenueId } = useReservationsRoute()
  const { formatMessage } = useLocales()
  const venue = useVenue()
  const [venuesWithNoAvailabilities, setVenuesWithNoAvailabilities] = useState<string[]>([])
  const isMobile = useMaxWidthBreakpoint('s')
  const baseSpacing = isMobile ? 'sm' : 'lm'

  useEffect(() => {
    setVenuesWithNoAvailabilities([])
  }, [startTime, startDate, partySize, haloTimeIntervalMinutes])

  return (
    <Box pt="lm">
      <BaseSection
        title={title}
        collapsed={collapsed}
        onToggleCollapse={v => {
          setCollapsed(v)
        }}
        data-test="other-venues-availability"
        backgroundColor={isMobile ? 'secondaryBackground' : undefined}
        padding="none"
        ml={baseSpacing}
        mr={baseSpacing}
        mt="sm"
        mb="sm"
        maxWidth="100%"
        type="h3"
      >
        <>
          {isMobile && <DividerLine color="borders" />}
          {otherVenues.length > 0 && venuesWithNoAvailabilities.length === otherVenues.length ? (
            <Box p="lm">
              <Text textStyle="body1">{formatMessage(reservationWidgetMessages.resWidgetOtherLocationsNoAvail)}</Text>
            </Box>
          ) : (
            <VStack pt="sm" pb={baseSpacing} pl={baseSpacing} pr={baseSpacing} spacing="lm">
              {otherVenues.map(v => (
                <OtherVenueAvailability
                  key={v.urlKey}
                  venue={v}
                  partySize={partySize}
                  startTime={startTime}
                  startDate={startDate}
                  defaultSearchTime={defaultSearchTime}
                  haloTimeIntervalMinutes={haloTimeIntervalMinutes}
                  clientId={clientId}
                  currencyCode={currencyCode}
                  selectedLangCode={selectedLangCode}
                  startOfDayTime={startOfDayTime}
                  collapsed={collapsed}
                  isMobile={isMobile}
                  isGroupWidget={false}
                  onTimeSlotClick={onTimeSlotClick}
                  onTitleClick={(venueUrlKey: string) => {
                    if (primaryVenueId) {
                      // If primary_venue_id exists, we keep the existing ID to give credit to the original venue.
                      navigateToOtherVenue(venueUrlKey)
                    } else {
                      navigateToOtherVenue(venueUrlKey, {
                        primary_venue_id: venue.id,
                      })
                    }
                  }}
                  onNoAvailabilities={(venueId: string) => {
                    if (!venuesWithNoAvailabilities.includes(venueId)) {
                      setVenuesWithNoAvailabilities([...venuesWithNoAvailabilities, venueId])
                    }
                  }}
                />
              ))}
            </VStack>
          )}
        </>
      </BaseSection>
    </Box>
  )
}
