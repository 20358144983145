import { useMemo } from 'react'
import { UpgradeGratuityCharges, UpgradesMaxQuantities, UpgradesMinQuantities } from '@sevenrooms/core/api'
import { z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export type UpgradesForm = z.infer<ReturnType<typeof useUpgradesFormSchema>>

export const useUpgradesFormSchema = () => {
  const { formatMessage } = useLocales()

  return useMemo(
    () =>
      z.object({
        categories: z.record(
          z
            .object({
              minType: z.enum(UpgradesMinQuantities),
              minAmount: z.number(),
              maxType: z.enum(UpgradesMaxQuantities),
              maxAmount: z.number(),
              upgrades: z.record(z.number().nonnegative().default(0)),

              // not really used for validation, just useful for keeping typesc consistent
              id: z.string(),
              name: z.string(),
              sortOrder: z.number(),
              venueId: z.string(),
              gratuityChargeType: z.enum(UpgradeGratuityCharges),
              gratuityPercentage: z.number().nullable(),
              isChargingGratuity: z.boolean(),
              isChargingServiceCharge: z.boolean(),
              isChargingTax: z.boolean(),
              requireGratuityCharge: z.boolean(),
              maxQuantityInt: z.number(),
              maxQuantityType: z.enum(UpgradesMaxQuantities),
              minQuantityInt: z.number(),
              minQuantityType: z.enum(UpgradesMinQuantities),
              serviceChargePercentage: z.number().nullable(),
              serviceChargeType: z.enum(['DEFAULT_SERVICE_CHARGE', 'SPECIFIC_SERVICE_CHARGE']),
              showCategoryFlag: z.boolean(),
              taxGroupId: z.string().nullable(),
            })
            .superRefine(({ minType, minAmount, maxType, maxAmount, upgrades }, ctx) => {
              const upgradeValues = Object.values(upgrades ?? {})
              const numberOfUpgradesAdded = upgradeValues.reduce(
                (accumulator: number, currentNumber: number) => accumulator + currentNumber,
                0
              )
              if (minType !== 'NONE_REQUIRED' && numberOfUpgradesAdded < minAmount) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: formatMessage(reservationWidgetMessages.resUpgradesPageNotEnoughSelected),
                })
              }
              if (maxType !== 'UNLIMITED' && numberOfUpgradesAdded > maxAmount) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: formatMessage(reservationWidgetMessages.resUpgradesPageEnoughSelected),
                })
              }
            })
        ),
      }),
    [formatMessage]
  )
}
