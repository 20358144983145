import { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import type { ManageReservationCharge } from '@sevenrooms/core/domain'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { CollapseButton } from '@sevenrooms/core/ui-kit/form'
import { type FlexProps, HStack, VStack, Collapsible, Box, DividerLine, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { paymentHistoryMessages } from './paymentHistoryMessages'

interface PaymentHistoryProps extends FlexProps {
  lang?: string
  currencyCode: string
  charges: ManageReservationCharge[]
}

export function PaymentHistory({ charges, currencyCode, lang = 'en', ...props }: PaymentHistoryProps) {
  const { formatMessage } = useLocales()
  const [collapsed, setCollapsed] = useState(true)
  const displayedCharges = useMemo(() => charges.filter(charge => charge.isCharge || charge.isRefund), [charges])

  const handleContainerClick = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed])

  return (
    <CardSection variant="secondary" width="100%" pl="sm" pr="sm" data-test="payment-history" onClick={handleContainerClick} {...props}>
      <TitleContainer pt="xs" pb="xs" justifyContent="space-between" alignItems="center" data-test="title-container">
        <Text textStyle="body1">{formatMessage(paymentHistoryMessages.title)}</Text>
        <Box ml="s">
          <CollapseButton size="s" collapsed={collapsed} onToggle={setCollapsed} data-test="collapse-button" />
        </Box>
      </TitleContainer>
      <Collapsible collapsed={collapsed}>
        <VStack spacing="m" pb="sm">
          {displayedCharges.map(charge => {
            const cardInfo = `${
              charge.isCharge
                ? formatMessage(paymentHistoryMessages.chargedText)
                : formatMessage(paymentHistoryMessages.refundedText, { amount: '' })
            } ${FormatService.formatPaymentCard(charge.brand, charge.last4)}`
            const formattedAmount = FormatService.formatCurrencyFromCents(charge.amount, currencyCode)
            return (
              <VStack key={charge.id} data-test={`charge-${charge.id}`}>
                <Box mb="m">
                  <DividerLine margin="none" />
                </Box>
                <HStack justifyContent="space-between">
                  <HStack spacing="xs">
                    <Text data-test="charge-date" textStyle="body1">
                      {DateOnly.from(charge.charged).formatNYearNMonthNDay()}
                    </Text>
                    <Link data-test="charge-receipt-link" to={`/direct/receipt/${charge.id}/?lang=${lang}`} isExternal>
                      {formatMessage(paymentHistoryMessages.receiptLink)}
                    </Link>
                  </HStack>
                  <Text data-test="charge-amount" textStyle="body1">
                    {charge.isCharge ? formattedAmount : `(${formattedAmount})`}
                  </Text>
                </HStack>
                <Box ml="sm">
                  <Text data-test="charge-type" color="secondaryFont">
                    {cardInfo}
                  </Text>
                </Box>
              </VStack>
            )
          })}
        </VStack>
      </Collapsible>
    </CardSection>
  )
}

const TitleContainer = styled(HStack)`
  cursor: pointer;
`
