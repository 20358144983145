import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export interface PostalCodeFormProps {
  disabled: boolean
  postalCodeField: Field<string>
  required: boolean
}

export function PostalCodeForm({ disabled, postalCodeField, required }: PostalCodeFormProps) {
  const { formatMessage } = useLocales()

  const postalCodeMessage = formatMessage(reservationWidgetMessages.resWidgetPostalCodeLabel)

  return (
    <Label primary={`${postalCodeMessage}${required ? '*' : ''}`}>
      <FormInput
        aria-required="false"
        autoComplete="postal-code"
        data-test="sr-reservation-postal-code"
        disabled={disabled}
        field={postalCodeField}
        inputMode="text"
        placeholder={postalCodeMessage}
        type="text"
        fullWidth
      />
    </Label>
  )
}
