import { useCallback } from 'react'
import { ReservationWidget } from '@sevenrooms/core/domain/constants'
import { useLocales } from '@sevenrooms/core/locales'
import { useSingleDayAvailability, type AvailabilityTimeWithUpSellCost } from '../../../../hooks'
import { reservationWidgetMessages } from '../../../../reservationWidgetMessages'
import { OtherAvailability } from '../OtherAvailability'
import { otherVenuesAvailabilityMessages } from './otherVenuesAvailabilityMessages'

export interface OtherVenueInfo {
  venueId: string
  urlKey: string
  venueName: string
  venueColor?: string
  colorPrimary?: string
  languageStrings: { [key: string]: { [key: string]: string } }
  countryCode: string
}

export interface OtherVenueAvailabilityProps {
  currencyCode: string
  collapsed: boolean
  venue: OtherVenueInfo
  startDate: string
  startTime: string
  haloTimeIntervalMinutes: number
  defaultSearchTime: string
  partySize: number
  selectedLangCode?: string
  startOfDayTime: string
  isMobile: boolean
  onTitleClick: (venueUrlKey: string) => void
  onTimeSlotClick: (slot: AvailabilityTimeWithUpSellCost, venue: OtherVenueInfo) => void
  onNoAvailabilities: (venueId: string) => void
  clientId?: string
  isGroupWidget: boolean
}

export function OtherVenueAvailability({
  venue,
  currencyCode,
  collapsed,
  startDate,
  startTime,
  defaultSearchTime,
  haloTimeIntervalMinutes,
  partySize,
  selectedLangCode = 'en',
  isMobile,
  startOfDayTime,
  onTimeSlotClick,
  onTitleClick,
  onNoAvailabilities,
  clientId,
  isGroupWidget,
}: OtherVenueAvailabilityProps) {
  const { formatMessage } = useLocales()
  const { isFetching, data } = useSingleDayAvailability({
    venueId: venue.venueId,
    venue: venue.urlKey,
    startDate,
    startTime,
    partySize,
    selectedLangCode,
    haloTimeIntervalMinutes,
    channel: ReservationWidget.SevenRoomsWidgetChannel,
    haloSizeInterval: ReservationWidget.WholeDayHaloSizeInterval,
    timeSlot: defaultSearchTime,
    startOfDayTime,
    skipRequests: collapsed,
    clientId,
  })

  const onVenueClick = useCallback(() => onTitleClick(venue.urlKey), [onTitleClick, venue.urlKey])
  const onTimeSlotClickCallback = useCallback(
    (timeslot: AvailabilityTimeWithUpSellCost) => onTimeSlotClick(timeslot, venue),
    [onTimeSlotClick, venue]
  )

  const venueShortDescription =
    venue.languageStrings[selectedLangCode]?.resWidgetCrossSellVenueShortDescription ||
    venue.languageStrings.en?.resWidgetCrossSellVenueShortDescription

  if (!isFetching && data?.reservationTimesInHalo.length === 0) {
    // Call onNoAvailabilities() asynchronously so that the rendering of this component completes
    // first before calling the method. This callback function can now change the state of the
    // parent without any problems.
    setTimeout(() => {
      onNoAvailabilities(venue.venueId)
    })

    if (!isGroupWidget) {
      return null
    }
  }

  return (
    <OtherAvailability
      title={venue.venueName}
      subtitle={venueShortDescription}
      scrollLeftLabel={formatMessage(otherVenuesAvailabilityMessages.scrollLeftLabel, { venue: venue.venueName })}
      scrollRightLabel={formatMessage(otherVenuesAvailabilityMessages.scrollRightLabel, { venue: venue.venueName })}
      currencyCode={currencyCode}
      isMobile={isMobile}
      onTitleClick={onVenueClick}
      onTimeSlotClick={onTimeSlotClickCallback}
      timeslots={data?.reservationTimesInHalo || []}
      titleDataTest={`other-venue-availability-${venue.urlKey}`}
      isFetching={isFetching}
      noAvailabilityMessage={formatMessage(reservationWidgetMessages.resWidgetNoAdditionalAvail)}
      shouldUsePrimaryColor={isGroupWidget}
    />
  )
}
