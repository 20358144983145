import { useLocales } from '@sevenrooms/core/locales'
import type { ModalHeaderProps } from '@sevenrooms/core/ui-kit/layout'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { CallVenueMessage } from '../../CallVenueMessage'
import { NotificationModal } from '../NotificationModal'

interface UnableCancelModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
}

export function UnableCancelModal({ closeHref }: UnableCancelModalProps) {
  const { formatMessage } = useLocales()

  return (
    <NotificationModal
      type="warning"
      title={formatMessage(reservationWidgetMessages.resCancelPageUnableCancelModifyResHeader)}
      primary={<CallVenueMessage />}
      closeHref={closeHref}
      data-test="unable-cancel-modal"
    />
  )
}
