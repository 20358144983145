import { useState, useMemo, useRef, useCallback, useEffect } from 'react'
import type { ImageObject } from '@sevenrooms/core/domain'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Spinner } from '@sevenrooms/core/ui-kit/layout'
import type { PreferenceOption } from './AccordionTag'

export const useAccordionOptions = (options: PreferenceOption[], onSave: (newOptions: PreferenceOption[]) => Promise<boolean>) => {
  const [internalOptions, setInternalOptions] = useState(options.map(option => ({ ...option })))
  const [isDirty, setIsDirty] = useState(false)
  const dirtyIds = useRef<string[]>([])
  const onAccordionSave = useCallback(async () => {
    const success = await onSave(internalOptions)
    if (success) {
      dirtyIds.current = []
      setIsDirty(false)
    }
    return success
  }, [internalOptions, onSave])
  const onOptionsChange = useCallback(
    (id: string, selected: boolean) => {
      const updatedOptions = [...internalOptions]
      const updatedOption = updatedOptions.find(option => option.id === id)
      if (updatedOption) {
        updatedOption.selected = selected
        setInternalOptions(updatedOptions)
        const optionIdx = dirtyIds.current.indexOf(id)
        if (optionIdx !== -1) {
          dirtyIds.current.splice(optionIdx, 1)
        } else {
          dirtyIds.current.push(id)
        }
        const newDirty = dirtyIds.current.length !== 0
        if (newDirty !== isDirty) {
          setIsDirty(newDirty)
        }
      }
    },
    [internalOptions, isDirty]
  )

  return {
    isDirty,
    onOptionsChange,
    onAccordionSave,
    accordionOptions: internalOptions,
  }
}

export const useAccordionImage = (
  image: ImageObject | (() => Promise<ImageObject | undefined>) | undefined,
  hasInitial: boolean,
  onSave: (newImage: ImageObject) => Promise<boolean>,
  onDelete: () => Promise<boolean>
) => {
  const [isDirty, setIsDirty] = useState(false)
  const [internalImage, setInternalImage] = useState<ImageObject | undefined>(
    hasInitial
      ? {
          name: '',
          rawUrl: '',
        }
      : undefined
  )
  const previousAccordionImage = useRef<ImageObject>()
  const getImage = useCallback(async () => {
    if (typeof image === 'function') {
      return image()
    }
    return image
  }, [image])

  useEffect(() => {
    getImage().then(newImage => {
      setIsDirty(false)
      setInternalImage(newImage)
      previousAccordionImage.current = newImage
    })
  }, [getImage])

  const onAccordionImageSave = useCallback(
    async (newImage: ImageObject) => {
      const success = await onSave(newImage)
      if (success) {
        setInternalImage(newImage)
        previousAccordionImage.current = newImage
        setIsDirty(false)
      }
      return success
    },
    [onSave]
  )

  const onAccordionImageChange = useCallback((newImage: ImageObject) => {
    setInternalImage(newImage)
    setIsDirty(true)
  }, [])

  const onAccordionImageDelete = useCallback(async () => {
    const success = await onDelete()
    if (success) {
      setInternalImage(undefined)
      previousAccordionImage.current = undefined
      setIsDirty(false)
    }
    return success
  }, [onDelete])

  const onAccordionImageCancel = useCallback(() => {
    setInternalImage(previousAccordionImage.current)
    setIsDirty(false)
  }, [])

  return {
    isDirty,
    accordionImage: internalImage,
    onAccordionImageChange,
    onAccordionImageSave,
    onAccordionImageDelete,
    onAccordionImageCancel,
  }
}

export type ModifyStatus = 'idle' | 'modifying' | 'modified'

export function useAccordionStatus<T extends unknown[], V extends unknown[]>(
  isDirty: boolean,
  onSave: (...opts: T) => Promise<boolean>,
  defaultStatus?: ModifyStatus,
  onDelete?: (...opts: V) => Promise<boolean>
) {
  const isModifying = useRef(false)
  const [saveStatus, setSaveStatus] = useState<ModifyStatus>(defaultStatus || 'idle')

  const onAccordionSave = useCallback(
    async (...opts: T) => {
      if (!isDirty || isModifying.current) {
        return undefined
      }
      try {
        isModifying.current = true
        setSaveStatus('modifying')
        const success = await onSave(...opts)
        setSaveStatus(success ? 'modified' : 'idle')
        isModifying.current = false
        return success
      } catch (err) {
        isModifying.current = false
        setSaveStatus('idle')
        throw err
      }
    },
    [isDirty, onSave]
  )

  const onAccordionDelete = useCallback(
    async (...opts: V) => {
      if (isModifying.current || !onDelete) {
        return undefined
      }
      try {
        isModifying.current = true
        setSaveStatus('modifying')
        const success = await onDelete(...opts)
        setSaveStatus(success ? 'modified' : 'idle')
        isModifying.current = false
        return success
      } catch (err) {
        isModifying.current = false
        setSaveStatus('idle')
        throw err
      }
    },
    [onDelete]
  )
  const icon = useMemo(() => SetIcon(saveStatus, isDirty), [saveStatus, isDirty])
  return {
    icon,
    saveStatus,
    onAccordionSave,
    onAccordionDelete,
  }
}

function SetIcon(saveStatus: ModifyStatus, isDirty: boolean) {
  switch (saveStatus) {
    case 'modified':
      if (isDirty) {
        return undefined
      }
      return <Icon name="VMSWeb-check" color="success" size="lg" />
    case 'modifying':
      return <Spinner size="xs" />
    case 'idle':
    default:
      return undefined
  }
}
