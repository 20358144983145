import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

export interface PolicyWrapperProps {
  header?: string
  content?: string
  dataTest?: string
}

export function PolicyWrapper({ header, content, dataTest }: PolicyWrapperProps) {
  if (!header && !content) {
    return null
  }
  return (
    <VStack spacing="m" data-test={dataTest}>
      {header && (
        <Text color="primaryFont" textStyle="body1Bold" data-test="header">
          {header}
        </Text>
      )}
      {content && (
        <Text lineHeight="m" color="secondaryFont" data-test="content">
          <HTMLContent content={content} allowedTags={false} />
        </Text>
      )}
    </VStack>
  )
}
