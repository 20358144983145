import { defineMessages } from '@sevenrooms/core/locales'

export const paymentDetailsMessages = defineMessages({
  title: {
    id: 'payment.details.title',
    defaultMessage: 'Payment Details',
  },
  total: {
    id: 'payment.details.total',
    defaultMessage: 'Total',
  },
})
