import { defineMessages } from '@sevenrooms/core/locales'

export const reservationAvailabilityModalMessages = defineMessages({
  confirmButton: {
    id: 'reservationAvailability.modal.button.select.title',
    defaultMessage: 'Select',
  },
  perPerson: {
    id: 'reservationAvailability.modal.cost.perPerson',
    defaultMessage: 'person',
  },
  perReservation: {
    id: 'reservationAvailability.modal.cost.perReservation',
    defaultMessage: 'reservation',
  },
  cancellationPolicyHeader: {
    id: 'reservationAvailability.modal.cancellation.header',
    defaultMessage: 'Cancellation Policy',
  },
  upgradesTitle: {
    id: 'reservationAvailability.modal.upgrades.title',
    defaultMessage: 'Included in the reservation:',
  },
  descriptionTitle: {
    id: 'reservationAvailability.modal.description.title',
    defaultMessage: 'About',
  },

  // Not currently localised
  imageAlt: {
    id: 'reservationAvailability.modal.image.alt',
    defaultMessage: 'Public photo',
  },
  cost: {
    id: 'reservationAvailability.modal.cost',
    defaultMessage: '{cost}/{text}',
  },
  upgradesCount: {
    id: 'reservationAvailability.modal.upgrades.count',
    defaultMessage: '{count}x',
  },
  upgradeCost: {
    id: 'reservationAvailability.modal.upgrade.cost',
    defaultMessage: '{name} - {price}',
  },
})
