import type { ManageReservationCalendarLinks } from '@sevenrooms/core/domain'
import { VStack, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ReservationDetailsCalendar } from './ReservationDetailsCalendar'

export interface ReservationDetailsHeaderProps {
  venueName: string
  reservationReference?: string
  calendarLinks?: ManageReservationCalendarLinks
}

export function ReservationDetailsHeader({ reservationReference, venueName, calendarLinks }: ReservationDetailsHeaderProps) {
  return (
    <VStack spacing="s">
      <HStack spacing="m" alignItems="center" justifyContent="space-between">
        <Text data-test="reservation-details-venue-name" textStyle="h1" overflowWrap="anywhere">
          {venueName}
        </Text>
        {calendarLinks && <ReservationDetailsCalendar data-test="reservation-details-calendar" calendarLinks={calendarLinks} />}
      </HStack>
      {reservationReference && (
        <Text textStyle="body1" color="secondaryFont" data-test="reservation-details-reference">
          {reservationReference}
        </Text>
      )}
    </VStack>
  )
}
