import { ReservationService, RTKUtils } from '@sevenrooms/core/api'
import { baseReservationWidgetApi } from './BaseReservationWidgetApi'
import { ManageReservationApiTags } from './ManageReservationDetailsApi'

export const reservationApi = baseReservationWidgetApi.enhanceEndpoints({ addTagTypes: ManageReservationApiTags }).injectEndpoints({
  endpoints: builder => ({
    createReservation: builder.mutation({
      queryFn: RTKUtils.rtkQuery(ReservationService.createReservation),
    }),

    editReservation: builder.mutation({
      invalidatesTags: ManageReservationApiTags,
      queryFn: RTKUtils.rtkQuery(ReservationService.editReservation),
    }),
  }),
})

export const { useCreateReservationMutation, useEditReservationMutation } = reservationApi
