import { defineMessages } from '@sevenrooms/core/locales'

export const paymentHistoryMessages = defineMessages({
  title: {
    id: 'payment.history.title',
    defaultMessage: 'Transaction History',
  },
  chargedText: {
    id: 'payment.history.charged',
    defaultMessage: 'Charged',
  },
  refundedText: {
    id: 'payment.history.refunded',
    defaultMessage: '{amount} refunded',
  },
  receiptLink: {
    id: 'payment.history.link',
    defaultMessage: 'Link to receipt',
  },
})
