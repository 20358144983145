import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenue, useWidgetSettings } from '../../hooks'
import { VenueDetails } from '../VenueDetails'
import type { PropsWithChildren } from 'react'

interface ReservationStatusProps {
  header: string
}

export function ReservationStatus({ header, children }: PropsWithChildren<ReservationStatusProps>) {
  const venue = useVenue()
  const widgetSettings = useWidgetSettings()
  const isMobile = useMaxWidthBreakpoint('s')
  const imageUrl = `/.h/download/${widgetSettings.headerImgPhotoDict.mega || widgetSettings.headerImgPhotoDict.medium}`

  return (
    <>
      <VStack width="100%" maxWidth={isMobile ? '100%' : '367px'} spacing="lm">
        {isMobile && header && <Header text={header} />}
        <VenueDetails image={imageUrl} imageHeight="280px" venue={venue} gmapsEmbedUrl={venue.gmapsEmbedUrl} />
      </VStack>
      <VStack width="100%" spacing="l" alignSelf="left">
        {!isMobile && <Header text={header} />}
        {children}
      </VStack>
    </>
  )
}

function Header({ text }: { text: string }) {
  return (
    <Text textStyle="h1" data-test="reservation-status-header">
      {text}
    </Text>
  )
}
