import { useMemo } from 'react'
import { Flex } from '@sevenrooms/core/ui-kit/layout'
import { AccordionInput, type AccordionInputProps } from './AccordionInput'
import { AccordionTag, type PreferenceOption } from './AccordionTag'

export interface AccordionSelectProps extends Omit<AccordionInputProps, 'onSave' | 'subTitle'> {
  accordionOptions: PreferenceOption[]
  onSave: () => Promise<boolean>
  onOptionChange: (id: string, selected: boolean) => void
  collapsed: boolean
  onToggle: (collapsed: boolean) => void
}

/**
 * When using this component please use useAccordionOptions from AccordionUtils to help manage props
 */
export function AccordionSelect({
  onSave,
  accordionOptions,
  onOptionChange,
  collapsed,
  onToggle,
  'data-test': dataTest,
  ...accordionInputProps
}: AccordionSelectProps) {
  const displayedOptions = useMemo(
    () =>
      accordionOptions.sort((firstOption, secondOption) => (firstOption.label.toLowerCase() > secondOption.label.toLowerCase() ? 1 : -1)),
    [accordionOptions]
  )
  const selectedOptions = useMemo(() => displayedOptions.filter(value => value.selected), [displayedOptions])
  const subTitle = useMemo(() => selectedOptions.map(value => value.label).join(', '), [selectedOptions])
  return (
    <AccordionInput
      onSave={onSave}
      data-test={dataTest}
      defaultStatus={selectedOptions.length ? 'modified' : 'idle'}
      {...accordionInputProps}
      subTitle={subTitle}
      collapsed={collapsed}
      onToggle={onToggle}
    >
      <Flex flexWrap="wrap">
        {displayedOptions.map(option => (
          <AccordionTag key={option.id} {...option} data-test={dataTest} onClick={onOptionChange} />
        ))}
      </Flex>
    </AccordionInput>
  )
}
