import { TextArea } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { AccordionInput, type AccordionInputProps } from './AccordionInput'

export interface AccordionTextInputProps extends Omit<AccordionInputProps, 'onSave'> {
  value?: string
  onChange: (newValue?: string) => void
  onSave: (newValue?: string) => Promise<boolean>
  collapsed: boolean
  onToggle: (collapsed: boolean) => void
}

export function AccordionTextInput({
  value,
  onSave,
  onChange,
  'data-test': dataTest,
  collapsed,
  onToggle,
  ...accordionInputProps
}: AccordionTextInputProps) {
  return (
    <AccordionInput
      onSave={() => onSave(value)}
      data-test={dataTest}
      defaultStatus={value ? 'modified' : 'idle'}
      subTitle={value}
      collapsed={collapsed}
      onToggle={onToggle}
      {...accordionInputProps}
    >
      <Box ml="s" width="100%">
        <TextArea
          aria-label={`${accordionInputProps.title} text area`}
          rows={2}
          resize="none"
          value={value}
          data-test={dataTest && `${dataTest}-text-area`}
          onChange={ev => {
            onChange(ev.target.value)
          }}
        />
      </Box>
    </AccordionInput>
  )
}
