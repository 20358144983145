import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { type FlexProps, HStack } from '@sevenrooms/core/ui-kit/layout'
import type { Theme } from '@sevenrooms/core/ui-kit/theme'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { SkeletonBox } from '../../../components/SkeletonBox'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'

export interface UpgradesFooterProps extends FlexProps {
  currencyCode: string
  disabled: boolean
  totalSum?: number
  onClick?: () => void
  isLoading?: boolean
}

export function UpgradesFooter({ currencyCode, disabled, totalSum = 0, onClick, isLoading = false }: UpgradesFooterProps) {
  const { formatMessage } = useLocales()
  const { fieldHeight, lineHeights } = useTheme() as Theme
  const isMobile = useMaxWidthBreakpoint('s')

  if (isLoading) {
    return (
      <HStack
        data-test="upgrades-footer-loader"
        justifyContent={isMobile ? 'space-between' : 'flex-end'}
        alignItems="center"
        spacing="m"
        pt="m"
        pb="lm"
      >
        <SkeletonBox width="130px" height={lineHeights.l} borderWidth="none" borderRadius="s" />
        <SkeletonBox width="100px" height={fieldHeight.m} borderWidth="none" borderRadius="s" />
      </HStack>
    )
  }

  return (
    <HStack justifyContent={isMobile ? 'space-between' : 'flex-end'} alignItems="center" spacing="m" pt="m" pb="lm">
      <Text data-test="upgrades-total-amount" textStyle="h3">
        {formatMessage(reservationWidgetMessages.commonTotalText)}: {FormatService.formatCurrency(totalSum, currencyCode)}
      </Text>
      <Button variant="primary" data-test="upgrades-button-next" size="m" disabled={disabled} onClick={onClick}>
        {formatMessage(reservationWidgetMessages.resConfirmPageNextButton)}
      </Button>
    </HStack>
  )
}
