import styled from 'styled-components'
import type { GuestFacingUpgradeCategory } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useContrastColor, useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import type { ThemeColor } from '@sevenrooms/core/ui-kit/theme'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { upgradesHeaderMessages } from './upgradesHeaderMessages'

export interface UpgradesHeaderProps {
  category: GuestFacingUpgradeCategory
  partySize: number | undefined
}

export function UpgradesHeader({ category, partySize }: UpgradesHeaderProps) {
  const isMobile = useMaxWidthBreakpoint('s')
  const fontColor = useContrastColor('venueColor', 'lightFont', 'darkFont')
  const { minQuantityType, minQuantityInt, maxQuantityInt, name } = category
  const isRequired = minQuantityType !== 'NONE_REQUIRED'

  return (
    <StyledUpgradesHeader backgroundColor="venueColor" p={isMobile ? 's m' : 'sm lm'}>
      {isMobile ? (
        <>
          <Text textStyle="h3" color={fontColor}>
            {name}
          </Text>
          {isRequired && (
            <div>
              <UpgradesHeaderLabel
                fontColor={fontColor}
                maxQuantityInt={maxQuantityInt}
                minQuantityInt={minQuantityInt}
                minQuantityType={minQuantityType}
                partySize={partySize}
              />
            </div>
          )}
        </>
      ) : (
        <HStack justifyContent="space-between" alignItems="center">
          <Text textStyle="h3" color={fontColor}>
            {name}
          </Text>
          {isRequired && (
            <UpgradesHeaderLabel
              fontColor={fontColor}
              maxQuantityInt={maxQuantityInt}
              minQuantityInt={minQuantityInt}
              minQuantityType={minQuantityType}
              partySize={partySize}
            />
          )}
        </HStack>
      )}
    </StyledUpgradesHeader>
  )
}

function UpgradesHeaderLabel({
  fontColor,
  maxQuantityInt,
  minQuantityInt,
  minQuantityType,
  partySize,
}: {
  fontColor: ThemeColor
  maxQuantityInt: number
  minQuantityType: 'NONE_REQUIRED' | 'NUMBER_OF_GUESTS' | 'SPECIFIC_NUMBER'
  partySize: number | undefined
  minQuantityInt: number
}) {
  const { formatMessage } = useLocales()
  let message = formatMessage(upgradesHeaderMessages.resWidgetUpgradePageMustSelectAtLeastLabel, { quantity: minQuantityInt })
  if (minQuantityInt === maxQuantityInt) {
    message = formatMessage(upgradesHeaderMessages.resWidgetUpgradePageMustSelectLabel, { quantity: minQuantityInt })
  }
  if (minQuantityType === 'NUMBER_OF_GUESTS') {
    message = formatMessage(upgradesHeaderMessages.resWidgetUpgradePageMustSelectLabel, { quantity: partySize })
  }
  return (
    <Text color={fontColor} fontStyle="italic">
      {formatMessage(upgradesHeaderMessages.resWidgetUpgradePageRequiredLabel)} &mdash; {message}
    </Text>
  )
}

const StyledUpgradesHeader = styled(Box)`
  border-bottom: ${props => props.theme.borderWidth.s} ${props => props.theme.colors.borders} solid;
`
