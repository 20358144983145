import { useLocales } from '@sevenrooms/core/locales'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export interface BookingPolicyProps {
  policyHolder: string
  termsOfServiceLink: string
  privacyPolicyLink?: string
  privacyPolicyHolder?: string
  gdprPolicyLink: string
  showSmsPolicy?: boolean
}

export function BookingPolicy({
  policyHolder,
  termsOfServiceLink,
  privacyPolicyLink,
  privacyPolicyHolder,
  gdprPolicyLink,
  showSmsPolicy,
}: BookingPolicyProps) {
  const { formatMessage } = useLocales()

  return (
    <VStack spacing="m">
      <Text textStyle="body2" data-test="booking-policy-disclaimer" wordBreak="break-word">
        {`${formatMessage(reservationWidgetMessages.resWidgetPolicyDisclaimerText)} ${policyHolder} `}
        <Link textStyle="body2" to={termsOfServiceLink} isExternal data-test="booking-policy-term-service-link">
          {formatMessage(reservationWidgetMessages.resWidgetTermsOfServiceLinkLabel)}
        </Link>
        {` ${formatMessage(reservationWidgetMessages.commonAndText)} `}
        <Link textStyle="body2" to={gdprPolicyLink} isExternal data-test="booking-policy-gdpr-link">
          {formatMessage(reservationWidgetMessages.resWidgetCustomGdprPolicyLinkLabel)}
        </Link>
        {privacyPolicyLink && (
          <Text textStyle="body2" data-test="booking-policy-custom-privacy">
            {` ${formatMessage(reservationWidgetMessages.commonAndText)} ${privacyPolicyHolder} `}
            <Link textStyle="body2" to={privacyPolicyLink} isExternal data-test="booking-policy-custom-privacy-link">
              {formatMessage(reservationWidgetMessages.resWidgetCustomPrivacyPolicyLinkLabel)}
            </Link>
          </Text>
        )}
        .
      </Text>
      {showSmsPolicy && (
        <Text textStyle="body2" data-test="booking-policy-twilio-consent">
          {
            // Remove formatting as we do not want to italicize the text
            formatMessage(reservationWidgetMessages.policyUsTwilioSmsOptInSubfooter, {
              i: (chunks: string) => chunks,
            })
          }
        </Text>
      )}
    </VStack>
  )
}
