import type {
  GuestFacingUpgradeInventory,
  GuestFacingUpgradeCategory,
  ReservationRequestUpsells,
  ManageReservationActual,
} from '@sevenrooms/core/domain'
import { calculateCategoryFees } from './calculateFees'

export const mapUpgradesToCategories = (
  inventory: GuestFacingUpgradeInventory[] = [],
  categories: GuestFacingUpgradeCategory[] = [],
  categoryFilter: string[] = [],
  defaultServiceCharge?: number,
  defaultGratuity?: number
): Categories =>
  categories
    .filter(({ id }) => categoryFilter.includes(id))
    .map(category => ({
      category,
      upgrades: inventory
        .filter(inventory => inventory.categoryId === category.id)
        .map(upgrade => ({
          ...upgrade,
          fees: calculateCategoryFees(upgrade.price, category, defaultServiceCharge, defaultGratuity),
        }))
        .sort((a, b) => b.sortOrder - a.sortOrder),
    }))
    .sort((a, b) => b.category.sortOrder - a.category.sortOrder)

export type Categories = {
  category: GuestFacingUpgradeCategory
  upgrades: GuestFacingUpgradeInventory[]
}[]

export const transformUpgradesDataToZodSchema = ({
  categories,
  partySize,
  formCategories,
  actualSelectedUpsellsInformation,
}: {
  categories: Categories
  partySize: number
  formCategories: SchemaCategories
  actualSelectedUpsellsInformation?: ManageReservationActual['selectedUpsellsInformation']
}): SchemaCategories =>
  Object.fromEntries(
    categories?.map(({ category, upgrades }) => {
      const formCategory = formCategories[category.id]
      return [
        category.id,
        {
          ...category,
          minType: category.minQuantityType ?? 'NONE_REQUIRED',
          minAmount: category.minQuantityType === 'NUMBER_OF_GUESTS' ? partySize : category.minQuantityInt ?? 0,
          maxType: category.maxQuantityType ?? 'NONE_REQUIRED',
          maxAmount: category.maxQuantityType === 'NUMBER_OF_GUESTS' ? partySize : category.maxQuantityInt ?? 0,
          upgrades: Object.fromEntries(
            upgrades.map(upgrade => [
              upgrade.id,
              formCategory?.upgrades[upgrade.id] ||
                actualSelectedUpsellsInformation
                  ?.filter(actualUpgrade => actualUpgrade.price === 0)
                  .find(actualUpgrade => actualUpgrade.id === upgrade.id)?.quantity ||
                0,
            ])
          ),
        },
      ]
    }) ?? []
  )

export const transformUpgradesDataToCheckout = (
  categories: SchemaCategories = {},
  inventories: GuestFacingUpgradeInventory[] = []
): ReservationRequestUpsells => {
  const categoriesForSubmit: ReservationRequestUpsells = { selectedCategories: {}, selectedInventories: {} }
  const inventoriesMap = inventories.reduce((accumulator, currentValue) => {
    accumulator[currentValue.id] = currentValue
    return accumulator
  }, {} as Record<string, GuestFacingUpgradeInventory>)
  Object.keys(categories).forEach(categoryKey => {
    const category = categories[categoryKey] as SchemaCategories[string]
    const upgrades = category?.upgrades || {}
    Object.keys(upgrades).forEach(inventoryKey => {
      const quantity = upgrades[inventoryKey]
      const inventoryData = inventoriesMap[inventoryKey]
      if (quantity && inventoryData) {
        categoriesForSubmit.selectedInventories[inventoryKey] = {
          price: inventoryData.price,
          quantity,
        }
        if (!categoriesForSubmit.selectedCategories[categoryKey]) {
          categoriesForSubmit.selectedCategories[categoryKey] = {
            gratuityChargeType: category.gratuityChargeType,
            gratuityPercentage: category.gratuityPercentage,
            isChargingGratuity: category.isChargingGratuity,
            isChargingTax: category.isChargingTax,
            taxGroupId: category.taxGroupId,
          }
        }
      }
    })
  })
  return categoriesForSubmit
}

export interface SchemaCategories {
  [categoryId: string]: GuestFacingUpgradeCategory & {
    upgrades: { [upgradeId: string]: number }
    minType: 'NONE_REQUIRED' | 'NUMBER_OF_GUESTS' | 'SPECIFIC_NUMBER'
    maxType: 'NUMBER_OF_GUESTS' | 'SPECIFIC_NUMBER' | 'UNLIMITED'
    minAmount: number
    maxAmount: number
  }
}

export const getSelectedUpgradesTotal = (
  categoriesData: Categories,
  selectedCategories: SchemaCategories,
  isFeesInPriceDisplayed = false
) => {
  let totalPrice = 0
  categoriesData.forEach(({ category, upgrades }) => {
    const selectedCategory = selectedCategories[category.id]
    if (selectedCategory) {
      upgrades.forEach(({ id, price, fees = 0 }) => {
        const selectedUpgradeCount = selectedCategory.upgrades[id]
        if (selectedUpgradeCount) {
          totalPrice += (price + (isFeesInPriceDisplayed ? fees : 0)) * selectedUpgradeCount
        }
      })
    }
  })
  return totalPrice
}
