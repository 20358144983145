import { useLocales } from '@sevenrooms/core/locales'
import type { ModalHeaderProps } from '@sevenrooms/core/ui-kit/layout'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { NotificationModal } from '../NotificationModal'

interface UnableAddUpgradesModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
}

export function UnableAddUpgradesModal({ closeHref }: UnableAddUpgradesModalProps) {
  const { formatMessage } = useLocales()

  return (
    <NotificationModal
      type="error"
      showIcon
      title={formatMessage(reservationWidgetMessages.resConfirmPageError)}
      primary={formatMessage(reservationWidgetMessages.resConfirmPageAdditionalUpgradesUnavailable)}
      closeHref={closeHref}
    />
  )
}
