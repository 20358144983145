import type { ManageReservationCharge } from '@sevenrooms/core/domain'

export const mockCharge = {
  id: 'TEST_CHARGE_ID',
  amount: 99,
  isCharge: true,
  isRefund: false,
  brand: 'VISA',
  last4: '4242',
  charged: '2022-09-16T16:16:03.306075+00:00',
} as ManageReservationCharge

export const mockRefund = {
  id: 'TEST_REFUND_ID',
  amount: 77,
  isCharge: false,
  isRefund: true,
  brand: 'MASTERCARD',
  last4: '4242',
  charged: '2022-12-16T16:11:23.306075+00:00',
} as ManageReservationCharge

export const mockPaymentLink = {
  id: 'TEST_PAYMENT_LINK_ID',
  amount: 0,
  isCharge: false,
  isRefund: false,
  brand: 'VISA',
  last4: '4242',
  charged: '2022-09-16T16:16:03.306075+00:00',
} as ManageReservationCharge
