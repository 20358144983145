import sanitizeHtml from 'sanitize-html'
import styled from 'styled-components'
import type { GuestFacingUpgradeInventory } from '@sevenrooms/core/domain'
import { useWatch, type Field } from '@sevenrooms/core/form'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { Counter, Description, IconButton } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { HStack, Image, Box } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text, TruncatingText } from '@sevenrooms/core/ui-kit/typography'
import { useWidgetSettings } from '../../../../../hooks'
import { reservationWidgetMessages } from '../../../../../reservationWidgetMessages'
import { upgradesGroupMessages } from '../upgradesGroupMessages'

export interface UpgradesCardProps extends Omit<GuestFacingUpgradeInventory, 'categoryId' | 'reservationTags' | 'sortOrder' | 'venueId'> {
  currencyCode: string
  perEachLabel: string
  fees?: number
  field: Field<number>
  categoryMaxRemaining?: number
}

export const DESKTOP_IMAGE_WIDTH = '175px'
export const DESKTOP_IMAGE_HEIGHT = '130px'
export const MOBILE_IMAGE_WIDTH = '130px'
export const MOBILE_IMAGE_HEIGHT = '97px'

export function UpgradesCard({
  id,
  currencyCode,
  perEachLabel,
  price,
  isPriceHidden,
  categoryMaxRemaining,
  field,
  description,
  name,
  previewImageUrlKey,
  fees = 0,
}: UpgradesCardProps) {
  const widgetSettings = useWidgetSettings()
  const { formatMessage } = useLocales()
  const isMobile = useMaxWidthBreakpoint('s')
  const priceLabel = isPriceHidden
    ? undefined
    : `${FormatService.formatCurrency(price + (widgetSettings.isFeesInPriceDisplayed ? fees : 0), currencyCode)} / ${perEachLabel}`
  const priceDescription =
    widgetSettings.isFeesInPriceDisplayed && fees > 0
      ? formatMessage(reservationWidgetMessages.resWidgetIncludesFees, { fees: FormatService.formatCurrency(fees, currencyCode) })
      : undefined
  const upgradeValue = useWatch(field)
  const descriptionMessageEmpty = !sanitizeHtml(description, { allowedTags: [] }).trim()

  const upgradeMaxRemaining = categoryMaxRemaining !== undefined ? upgradeValue + categoryMaxRemaining : undefined

  return (
    <Box data-test={`${id}-upgrade`} mt="lm" mb="lm" ml={isMobile ? 'm' : 'lm'} mr={isMobile ? 'm' : 'lm'}>
      <HStack alignItems="center" justifyContent="space-between" mb="s">
        <Text textStyle="h3">{name}</Text>
        <Box width="130px">
          <Counter
            fullWidth
            buttonIncrement={<IconButton icon="GX-plus" borderType="square" size="s" />}
            buttonDecrement={<IconButton icon="GX-minus" borderType="square" size="s" />}
            max={upgradeMaxRemaining}
            field={field}
          />
        </Box>
      </HStack>
      <HStack spacing={isMobile ? 'sm' : 'l'} justifyContent={previewImageUrlKey ? 'space-between' : undefined}>
        <div>
          {!descriptionMessageEmpty && (
            <Box mb={isMobile ? 'sm' : 'm'}>
              <TruncatingText
                textStyle="body1"
                spacing="xs"
                textOverflow={2}
                message={
                  <HTMLContent
                    content={description}
                    allowedTags={['p', 'div', 'br', 'u', 'b', 'strong', 'i', 'em', 'span', 'a', 'ol', 'ul', 'li']}
                    data-test="upgrade-description"
                  />
                }
                hasBlockHtmlElements
                viewLessButtonText={formatMessage(upgradesGroupMessages.resWidgetUpgradeViewLessButton)}
                viewMoreButtonText={formatMessage(upgradesGroupMessages.resWidgetUpgradeViewMoreButton)}
              />
            </Box>
          )}
          {priceLabel && <Text>{priceLabel}</Text>}
          {priceDescription && (
            <Description>
              <SecondaryText>{priceDescription}</SecondaryText>
            </Description>
          )}
        </div>
        {previewImageUrlKey && (
          <StyledRightFlexItem $isMobile={isMobile}>
            <Image
              centered
              width={isMobile ? MOBILE_IMAGE_WIDTH : DESKTOP_IMAGE_WIDTH}
              height={isMobile ? MOBILE_IMAGE_HEIGHT : DESKTOP_IMAGE_HEIGHT}
              alt={`${name}:${description}`}
              src={previewImageUrlKey}
              borderRadius="s"
            />
          </StyledRightFlexItem>
        )}
        {!previewImageUrlKey && !isMobile && <StyledRightFlexItem $isMobile={isMobile} />}
      </HStack>
    </Box>
  )
}

const StyledRightFlexItem = styled.div<{ $isMobile: boolean }>`
  flex: 0 0 ${props => (props.$isMobile ? MOBILE_IMAGE_WIDTH : DESKTOP_IMAGE_WIDTH)};
`
