import type { Field } from '@sevenrooms/core/form'
import { FirstNameForm, LastNameForm, PhoneNumberForm, EmailAddressForm, SalutationForm } from '../../../components'
import { useClientLogin, useVenueGroup, useWidgetSettings, useSalutationOption } from '../../../hooks'
import { SevenroomsGlobalClientDetails } from './SevenRoomsGlobalClientDetails'
import type { CheckoutForm } from '../Checkout.zod'

export interface UserContactDetailsProps {
  field: Field<CheckoutForm>
  disabled: boolean
}

export function UserContactDetails({ field, disabled }: UserContactDetailsProps) {
  const { salutationType } = useWidgetSettings()
  const { salutationOptions } = useSalutationOption()
  const { provider } = useClientLogin()
  const { lockedFields } = useVenueGroup()
  if (provider === 'sevenrooms') {
    return <SevenroomsGlobalClientDetails field={field} />
  }
  return (
    <>
      {salutationType !== 'Hidden' ? (
        <SalutationForm
          required={salutationType === 'Required'}
          salutationField={field.prop('salutation')}
          disabled={disabled}
          salutationOptions={salutationOptions}
        />
      ) : null}
      <FirstNameForm firstNameField={field.prop('firstName')} disabled={disabled || lockedFields.firstName} />
      <LastNameForm lastNameField={field.prop('lastName')} disabled={disabled || lockedFields.lastName} />
      <EmailAddressForm emailAddressField={field.prop('emailAddress')} disabled={disabled || lockedFields.email} />
      <PhoneNumberForm
        phoneNumberField={field.prop('phoneNumber')}
        phoneDialCodeField={field.prop('phoneDialCode')}
        phoneCountryCodeField={field.prop('phoneCountryCode')}
        disabled={disabled || !!lockedFields.phoneNumber}
      />
    </>
  )
}
