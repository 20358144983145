import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export interface FirstNameFormProps {
  disabled: boolean
  firstNameField: Field<string>
}

export function FirstNameForm({ disabled, firstNameField }: FirstNameFormProps) {
  const { formatMessage } = useLocales()

  const firstNameMessage = formatMessage(reservationWidgetMessages.resWidgetFirstNameLabel)

  return (
    <Label primary={`${firstNameMessage}*`}>
      <FormInput
        aria-required="true"
        autoComplete="given-name"
        data-test="sr-reservation-first-name"
        disabled={disabled}
        field={firstNameField}
        fullWidth
        placeholder={firstNameMessage}
      />
    </Label>
  )
}
