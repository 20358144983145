import { useForm } from '@sevenrooms/core/form'
import { useUpgradesFormSchema } from './Upgrades.zod'
import type { SchemaCategories } from '../../utils'

export const useUpgradesForm = ({ categories }: { categories: SchemaCategories }) => {
  const upgradesSchema = useUpgradesFormSchema()

  return useForm(upgradesSchema, {
    mode: 'all',
    defaultValues: { categories },
  })
}
