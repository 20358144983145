import { type ForwardedRef, forwardRef, type PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Grid } from '@sevenrooms/core/ui-kit/layout'

export type ReservationSearchInputGroupProps = PropsWithChildren<{
  columnsCount?: number
}>

export function ReservationSearchInputGroupComponent(
  { columnsCount = 3, children }: ReservationSearchInputGroupProps,
  ref: ForwardedRef<HTMLDivElement>
) {
  return (
    <Grid data-test="sr-reservation-search-input-group" gridTemplateColumns={`repeat(${columnsCount}, 1fr)`} alignItems="stretch" ref={ref}>
      {children}
    </Grid>
  )
}

export const StyledSearchInputGridContainer = styled.div``

export const ReservationSearchInputGroup = forwardRef<HTMLDivElement, ReservationSearchInputGroupProps>(
  ReservationSearchInputGroupComponent
)
