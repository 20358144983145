import { useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Flex, Box, VStack, type FlexProps } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'

export interface ManageReservationHeaderProps extends FlexProps {
  flowType?: 'confirmation' | 'create' | 'update'
  guestContact?: string | null
  bookingConfirmationEnabled?: boolean
}

export function ManageReservationHeader({
  flowType,
  guestContact,
  bookingConfirmationEnabled = true,
  ...props
}: ManageReservationHeaderProps) {
  const { formatMessage } = useLocales()
  const isSmallMobile = useMaxWidthBreakpoint('s')
  const isExtraSmallMobile = useMaxWidthBreakpoint('xs')
  const headerStyle = useMemo(() => {
    if (isExtraSmallMobile) {
      return 'body1Bold'
    }
    if (isSmallMobile) {
      return 'h2'
    }

    return 'h1'
  }, [isExtraSmallMobile, isSmallMobile])

  const textAlign = isSmallMobile ? 'center' : 'left'

  const headerText = {
    create: formatMessage(reservationWidgetMessages.resConfirmPageSubHeader),
    update: formatMessage(reservationWidgetMessages.resWidgetUpdateBody),
    confirmation: formatMessage(reservationWidgetMessages.resConfirmPageHeader),
  }

  if (!flowType) {
    return null
  }

  return (
    <Flex {...props}>
      <Box mt="xs" mb="s" mr="s">
        <Icon name="GX-checkbox-marked-circle" color="success" size="lg" />
      </Box>
      <VStack {...props}>
        <Text data-test="confirm-header" textStyle={headerStyle} textAlign={textAlign}>
          {headerText[flowType]}
        </Text>
        {flowType === 'confirmation' ? (
          <Text data-test="confirm-subheader" textStyle={headerStyle} textAlign={textAlign}>
            {formatMessage(reservationWidgetMessages.resConfirmPageSubHeader)}
          </Text>
        ) : (
          guestContact &&
          bookingConfirmationEnabled && (
            <Text data-test="confirm-subheader" textStyle={isSmallMobile ? 'body1' : 'h3'} textAlign={textAlign}>
              {formatMessage(reservationWidgetMessages.resWidgetConfirmEmailSent, {
                booking_conf_destination: (
                  <Text textStyle={isSmallMobile ? 'body1' : 'h3'} whiteSpace="nowrap">
                    {guestContact}
                  </Text>
                ),
              })}
            </Text>
          )
        )}
      </VStack>
    </Flex>
  )
}
