import { useMemo } from 'react'
import { FormatService, useLocales } from '@sevenrooms/core/locales'
import { EmbeddedMap, HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Link, SecondaryText, Text, TruncatingText } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'

const MAP_HEIGHT = '160px'
const DEFAULT_VENUE_DESCRIPTION_LINE_COUNT = 10

export interface VenueProfileProps {
  name: string
  description?: string
  gmapsEmbedUrl?: string
  mapLink: string
  address?: string
  cityState?: string
  postalCode?: string
  width?: string
}

export function VenueProfile({
  width = '100%',
  name,
  description,
  gmapsEmbedUrl,
  mapLink,
  address,
  cityState,
  postalCode,
}: VenueProfileProps) {
  const { formatMessage } = useLocales()
  const formattedAddress = FormatService.formatAddress(address, cityState, postalCode)

  const onMapClick = useMemo(() => {
    if (!mapLink) {
      // we return undefined here, so that the EmbeddedMap component doesn't render the click wrapper
      return undefined
    }
    const clickFunc = () => {
      window.open(mapLink, '_blank')
    }
    return clickFunc
  }, [mapLink])

  return (
    <VStack width={width} data-test="venue-profile" spacing="lm">
      <Text textStyle="h1" wordBreak="break-word" data-test="venue-name">
        {name}
      </Text>
      {description && (
        <TruncatingText
          textStyle="body1"
          color="secondaryFont"
          whiteSpace="break-spaces"
          spacing="xs"
          textOverflow={DEFAULT_VENUE_DESCRIPTION_LINE_COUNT}
          hasBlockHtmlElements
          message={<HTMLContent content={description} allowedTags={[]} data-test="venue-description" />}
          viewLessButtonText={formatMessage(reservationWidgetMessages.resConfirmPageViewLess)}
          viewMoreButtonText={formatMessage(reservationWidgetMessages.resConfirmPageViewMore)}
        />
      )}
      {gmapsEmbedUrl ? (
        <EmbeddedMap clickTestId="venue-map" embeddedMapUrl={gmapsEmbedUrl} width="100%" height={MAP_HEIGHT} onMapClick={onMapClick} />
      ) : null}
      {formattedAddress &&
        (mapLink ? (
          <Link
            wordBreak="break-word"
            textStyle="body1"
            color="secondaryFont"
            textDecoration="underline"
            data-test="venue-address"
            to={mapLink}
            isExternal
            target="_blank"
          >
            {formattedAddress}
          </Link>
        ) : (
          <SecondaryText wordBreak="break-word" textStyle="body1" data-test="venue-address-text">
            {formattedAddress}
          </SecondaryText>
        ))}
    </VStack>
  )
}
