import type { AvailabilityTime, ChargePerType, GuestFacingUpgradeCategory } from '@sevenrooms/core/domain'

export const calculateFees = (
  isFeesInPriceDisplayed: boolean,
  price?: number | null,
  serviceChargePercentage?: number | null,
  gratuityPercentage?: number | null,
  chargeType?: ChargePerType | null,
  duration?: number
): number => {
  let fees = 0
  if (!isFeesInPriceDisplayed || !price) {
    return 0
  }
  if (serviceChargePercentage) {
    fees += (serviceChargePercentage * price) / 100
  }
  if (gratuityPercentage) {
    fees += (gratuityPercentage * price) / 100
  }
  if (duration && chargeType && chargeType.endsWith('slot')) {
    fees *= duration / 15
  }
  return fees
}

export function calculateReservationFees(isFeesInPriceDisplayed: boolean, availabilityTimeSlot: AvailabilityTime) {
  const {
    cost,
    applyServiceCharge,
    serviceChargeType,
    serviceCharge,
    defaultServiceCharge,
    applyGratuityCharge,
    gratuityType,
    gratuity,
    defaultGratuity,
  } = availabilityTimeSlot
  const serviceChargePercentage = getServiceChargePercentage(
    applyServiceCharge,
    serviceChargeType as GuestFacingUpgradeCategory['serviceChargeType'],
    serviceCharge,
    defaultServiceCharge
  )
  const gratuityPercentage = getGratuityPercentage(
    applyGratuityCharge,
    gratuityType as GuestFacingUpgradeCategory['gratuityChargeType'],
    gratuity,
    defaultGratuity
  )

  return calculateFees(isFeesInPriceDisplayed, cost, serviceChargePercentage, gratuityPercentage)
}

export function calculateCategoryFees(
  price: number | undefined | null,
  category: {
    isChargingServiceCharge: GuestFacingUpgradeCategory['isChargingServiceCharge']
    serviceChargeType: GuestFacingUpgradeCategory['serviceChargeType']
    serviceChargePercentage?: GuestFacingUpgradeCategory['serviceChargePercentage']
    isChargingGratuity: GuestFacingUpgradeCategory['isChargingGratuity']
    gratuityChargeType: GuestFacingUpgradeCategory['gratuityChargeType']
    gratuityPercentage?: GuestFacingUpgradeCategory['gratuityPercentage']
  },
  defaultServiceCharge?: number,
  defaultGratuity?: number
) {
  const serviceCharge = getServiceChargePercentage(
    category.isChargingServiceCharge,
    category.serviceChargeType,
    category.serviceChargePercentage,
    defaultServiceCharge
  )
  const mandatoryGratuity = getGratuityPercentage(
    category.isChargingGratuity,
    category.gratuityChargeType,
    category.gratuityPercentage,
    defaultGratuity
  )

  return calculateFees(true, price, serviceCharge, mandatoryGratuity)
}

function getServiceChargePercentage(
  shouldApplyServiceCharge = false,
  serviceChargeType?: GuestFacingUpgradeCategory['serviceChargeType'] | null,
  serviceChargePercentage?: number | null,
  defaultServiceCharge?: number
) {
  let serviceCharge = 0

  if (shouldApplyServiceCharge) {
    if (serviceChargeType === 'DEFAULT_SERVICE_CHARGE' && defaultServiceCharge !== undefined) {
      serviceCharge = defaultServiceCharge
    } else {
      serviceCharge = serviceChargePercentage || 0
    }
  }

  return serviceCharge
}

function getGratuityPercentage(
  shouldApplyGratuity = false,
  gratuityType?: GuestFacingUpgradeCategory['gratuityChargeType'] | null,
  gratuityPercentage?: number | null,
  defaultGratuity?: number
) {
  let mandatoryGratuity = 0

  if (shouldApplyGratuity) {
    if (gratuityType === 'DEFAULT_GRATUITY' && defaultGratuity) {
      mandatoryGratuity = defaultGratuity
    } else if (gratuityType === 'SPECIFIC_GRATUITY' && gratuityPercentage) {
      mandatoryGratuity = gratuityPercentage
    }
  }

  return mandatoryGratuity
}
