import type { LanguageCode } from '@sevenrooms/core/api'
import { SevenroomsLoginButton, type GoogleJWT } from '@sevenrooms/core/ui-kit/core'
import { ReservationCheckoutLoginSection } from '../../../components'
import { useGetSevenroomsLogin } from '../../../hooks'
import { SocialMediaPanel } from './SocialMediaPanel'

export interface GuestLoginPanelProps {
  socialMediaLoginEnabled: boolean
  selectedLanguage: LanguageCode
  googleApiClientId: string
  onFacebookClick: () => void
  onGoogleLogin: (data: GoogleJWT) => void
  disabled: boolean
}

export function GuestLoginPanel({
  socialMediaLoginEnabled,
  disabled,
  googleApiClientId,
  onFacebookClick,
  onGoogleLogin,
  selectedLanguage,
}: GuestLoginPanelProps) {
  const sevenRoomsLogin = useGetSevenroomsLogin()
  if (sevenRoomsLogin) {
    return (
      <ReservationCheckoutLoginSection>
        <SevenroomsLoginButton {...sevenRoomsLogin} disabled={disabled} size="l" backgroundColor="primaryBackground" fullWidth />
      </ReservationCheckoutLoginSection>
    )
  }
  if (socialMediaLoginEnabled) {
    return (
      <ReservationCheckoutLoginSection>
        <SocialMediaPanel
          googleApiClientId={googleApiClientId}
          locale={selectedLanguage}
          onFacebookClick={onFacebookClick}
          onGoogleLogin={onGoogleLogin}
          disabled={disabled}
        />
      </ReservationCheckoutLoginSection>
    )
  }
  return null
}
