import styled, { css } from 'styled-components'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Flex, HStack, Box } from '@sevenrooms/core/ui-kit/layout'
import { SkeletonBox } from '../../../SkeletonBox'

export function OtherDayReservationAvailabilitySkeleton() {
  const isMobile = useMaxWidthBreakpoint('s')

  return (
    <>
      {[1, 2, 3].map(val => (
        <Box key={val}>
          <SkeletonBox width="100px" height="20px" mb="m" mt={isMobile ? 'none' : 'm'} />
          <StyledTimeSlotsContainer spacing="sm" overflowX="scroll" isMobile={isMobile}>
            <Flex flex="0 0 150px">
              <SkeletonBox height="59px" />
            </Flex>
            <Flex flex="0 0 150px">
              <SkeletonBox height="59px" />
            </Flex>
            <Flex flex="0 0 150px">
              <SkeletonBox height="59px" />
            </Flex>
            <Flex flex="0 0 150px">
              <SkeletonBox height="59px" />
            </Flex>
            <Flex flex="0 0 150px">
              <SkeletonBox height="59px" />
            </Flex>
          </StyledTimeSlotsContainer>
        </Box>
      ))}
    </>
  )
}

const StyledTimeSlotsContainer = styled(HStack)<{ isMobile: boolean }>`
  ${({ isMobile }) =>
    !isMobile &&
    css`
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    `}
`
