import { useRef, useEffect, type PropsWithChildren } from 'react'
import { createPortal } from 'react-dom'

interface WidgetPortalProps {
  portalId: string
}

export function WidgetPortal({ children, portalId }: PropsWithChildren<WidgetPortalProps>) {
  const portalContainer = useRef(document.getElementById(portalId))
  const portalElement = useRef<HTMLElement | null>(null)

  useEffect(() => {
    const portalNode = document.getElementById(portalId)
    if (portalNode) {
      portalContainer.current = portalNode
    }

    return () => {
      if (portalContainer.current && portalElement.current) {
        portalContainer.current.removeChild(portalElement.current)
      }
    }
  }, [portalId])

  if (!portalContainer.current) {
    return null
  }

  if (!portalElement.current) {
    portalElement.current = document.createElement('div')
    portalContainer.current.appendChild(portalElement.current)
  }

  return createPortal(children, portalElement.current)
}
