import { useCallback } from 'react'
import { useReleaseReservationHoldMutation } from '../store'
import { useCachedCreateReservationHold } from './useCachedCreateReservationHold'
import { useVenue } from './useVenue'

export function useReleaseReservationHold() {
  const venue = useVenue()
  const [releaseReservationHoldMutation] = useReleaseReservationHoldMutation()
  const [, { data, reset }] = useCachedCreateReservationHold()

  const releaseReservationHold = useCallback(() => {
    // Clear reservation hold
    if (data?.reservationHoldId) {
      try {
        releaseReservationHoldMutation({
          date: data.date,
          shiftPersistentId: data.shiftPersistentId,
          reservationHoldId: data.reservationHoldId,
          venueId: venue.id,
        })
        reset()
        // eslint-disable-next-line no-console
        console.info('Reservation Hold released')
      } catch (err) {
        // https://sevenrooms.atlassian.net/browse/GX-2773
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }
  }, [data, releaseReservationHoldMutation, reset, venue.id])

  return { releaseReservationHold }
}
