import { defineMessages } from '@sevenrooms/core/locales'

export const additionalUpgradesCardMessages = defineMessages({
  availableUpgradesTitle: {
    id: 'upgrades.availableUpgrades.header',
    defaultMessage: 'Enhance your experience with one of our signature items',
  },
  availableUpgradesShowMoreTitle: {
    id: 'upgrades.availableUpgrades.title.showMore',
    defaultMessage: 'View More',
  },
  availableUpgradesShowLessTitle: {
    id: 'upgrades.availableUpgrades.title.showLess',
    defaultMessage: 'View Less',
  },
  availableUpgradesShowAllTitle: {
    id: 'upgrades.availableUpgrades.title.showAll',
    defaultMessage: 'View All Upgrades',
  },
})
