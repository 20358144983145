import { useMemo } from 'react'
import { z, type ZodSchema } from '@sevenrooms/core/form'

export type PrivateEventsSearchForm = ZodSchema<typeof usePrivateEventsSearchFormSchema>

export const usePrivateEventsSearchFormSchema = () =>
  useMemo(
    () =>
      z.object({
        privateEventsPartySize: z.number().nullable(),
        privateEventsStartDate: z.date().optional(),
      }),
    []
  )
