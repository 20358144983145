import styled from 'styled-components'
import { Box, StackResponsive } from '@sevenrooms/core/ui-kit/layout'
import type { PropsWithChildren, ReactNode } from 'react'

export interface CheckoutContainerProps {
  reservationDetails: ReactNode
}

export function CheckoutContainer({ children, reservationDetails }: PropsWithChildren<CheckoutContainerProps>) {
  return (
    <Box width="100%" pt="lm" maxWidth="976px" data-test="checkout-container">
      <StackResponsive breakpoint="m" spacing="xxl">
        <PrimaryContent>{children}</PrimaryContent>
        <SecondaryContent>{reservationDetails}</SecondaryContent>
      </StackResponsive>
    </Box>
  )
}

const LEFT_COL_WIDTH = '500px'
const BREADCRUMB_MARGIN_TOP = '40px'

export const PrimaryContent = styled.div`
  flex: 0 0 ${LEFT_COL_WIDTH};
  margin-bottom: ${props => props.theme.spacing.l};

  // max-width is needed here as the width of the "Sign in with Google" button is set programmatically
  // and it prevents the left column from shrinking at 925px browser width.
  max-width: ${LEFT_COL_WIDTH};

  @media (max-width: ${props => props.theme.breakpoints.m}px) {
    flex-grow: 1;
    flex-shrink: 1;
    max-width: none;
  }
`

export const SecondaryContent = styled.div`
  flex: 0 1 100%;
  align-self: flex-start;
  position: sticky;
  top: ${props =>
    `calc(${props.theme.topNav.height.desktop} + ${props.theme.spacing.lm}
      + ${BREADCRUMB_MARGIN_TOP})`};

  @media (max-width: ${props => props.theme.breakpoints.m}px) {
    display: none;
  }
`
