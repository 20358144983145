import { defineMessages } from '@sevenrooms/core/locales'

export const upgradesHeaderMessages = defineMessages({
  resWidgetUpgradePageRequiredLabel: {
    id: 'resWidgetUpgradePageRequiredLabel',
    defaultMessage: 'Required',
  },
  resWidgetUpgradePageOptionalLabel: {
    id: 'resWidgetUpgradePageOptionalLabel',
    defaultMessage: 'Optional',
  },
  resWidgetUpgradePageMustSelectLabel: {
    id: 'resWidgetUpgradePageMustSelectLabel',
    defaultMessage: 'Must select {quantity}',
  },
  resWidgetUpgradePageMustSelectAtLeastLabel: {
    id: 'resWidgetUpgradePageMustSelectAtLeastLabel',
    defaultMessage: 'Must select at least {quantity}',
  },
})
