import { defineMessages } from '@sevenrooms/core/locales'

export const venueDetailsMessages = defineMessages({
  getDirections: {
    id: 'venue.details.directions',
    defaultMessage: 'Get directions',
  },
  // Not Currently Translated
  imageAltText: {
    id: 'venue.details.image.alt',
    defaultMessage: 'Venue Header Image',
  },
})
