import { useCallback } from 'react'
import type { CountryCode } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { useLocales, allCountries, iso2Lookup } from '@sevenrooms/core/locales'
import { FormPhoneNumberInput, Label } from '@sevenrooms/core/ui-kit/form'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export interface PhoneNumberFormProps {
  disabled: boolean
  phoneNumberField: Field<string>
  phoneCountryCodeField: Field<CountryCode>
  phoneDialCodeField: Field<string>
  hideAsterisk?: boolean
}

export function PhoneNumberForm({
  disabled,
  phoneNumberField,
  phoneCountryCodeField,
  phoneDialCodeField,
  hideAsterisk = false,
}: PhoneNumberFormProps) {
  const { formatMessage } = useLocales()

  const phoneNumberMessage = formatMessage(reservationWidgetMessages.resWidgetPhoneNumberLabel)
  const countryCode = useWatch(phoneCountryCodeField)
  const onCountryChange = useCallback(
    (countryCode?: CountryCode) => {
      if (!countryCode) {
        return
      }
      phoneCountryCodeField.set(countryCode)
      const dialCode = allCountries[iso2Lookup[countryCode]]?.dialCode ?? ''
      phoneDialCodeField.set(dialCode)
    },
    [phoneCountryCodeField, phoneDialCodeField]
  )

  return (
    <Label primary={`${phoneNumberMessage}${hideAsterisk ? '' : '*'}`}>
      <FormPhoneNumberInput
        autoComplete="tel"
        data-test="sr-reservation-phone-number"
        disabled={disabled}
        field={phoneNumberField}
        fullWidth
        placeholder={phoneNumberMessage}
        defaultCountryCode={countryCode}
        onCountryChange={onCountryChange}
        withSelectNativeSupport
      />
    </Label>
  )
}
