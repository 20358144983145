import { ReservationHoldService, RTKUtils } from '@sevenrooms/core/api'
import { baseReservationWidgetApi } from './BaseReservationWidgetApi'

export const reservationHoldApi = baseReservationWidgetApi.injectEndpoints({
  endpoints: builder => ({
    createReservationHold: builder.mutation({
      queryFn: RTKUtils.rtkQuery(ReservationHoldService.createReservationHold),
    }),
    releaseReservationHold: builder.mutation({
      queryFn: RTKUtils.rtkQuery(ReservationHoldService.releaseReservationHold),
    }),
  }),
})

export const { useCreateReservationHoldMutation, useReleaseReservationHoldMutation } = reservationHoldApi
