import { useCallback, type PropsWithChildren, useEffect } from 'react'
import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { type ModifyStatus, useAccordionStatus } from './AccordionUtils'
import { BaseAccordionInput, type BaseAccordionInputProps } from './BaseAccordionInput'

export interface AccordionInputProps
  extends Omit<BaseAccordionInputProps, 'actionElement' | 'statusElement' | 'collapsed' | 'setCollapsed'> {
  saveText: string
  isDirty: boolean
  onSave: () => Promise<boolean>
  onError?: (e: Error) => void
  collapsed: boolean
  onToggle: (collapsed: boolean) => void
  defaultStatus?: ModifyStatus
}

export function AccordionInput({
  title,
  isDirty = false,
  saveText,
  collapsed = true,
  onSave,
  onError,
  onToggle,
  children,
  'data-test': dataTest,
  defaultStatus,
  ...accordionProps
}: PropsWithChildren<AccordionInputProps>) {
  const { formatMessage } = useLocales()

  const { icon, saveStatus, onAccordionSave } = useAccordionStatus(isDirty, onSave, defaultStatus)

  const onSaveClick = useCallback(async () => {
    if (!isDirty) {
      return
    }
    onToggle(true)
    try {
      const succeed = await onAccordionSave()
      if (!succeed) {
        onToggle(false)
      }
    } catch (e) {
      onToggle(false)
      if (!onError) {
        return
      }
      if (e instanceof Error) {
        onError(e)
      } else {
        onError(Error(typeof e === 'string' ? e : 'Accordion Input Save Error'))
      }
    }
  }, [isDirty, onToggle, onAccordionSave, onError])

  const onCollapsed = useCallback(
    async isCollapsed => {
      if (isCollapsed) {
        await onAccordionSave()
      }
    },
    [onAccordionSave]
  )

  useEffect(() => {
    onCollapsed(collapsed)
  }, [collapsed, onCollapsed])

  const saveButton = (
    <Button
      aria-label={`Save ${title} Button`}
      data-test={(dataTest && `${dataTest}-save`) || 'accordion-save-button'}
      variant="secondary"
      onClick={onSaveClick}
      disabled={!isDirty}
    >
      {saveText ?? formatMessage(commonMessages.save)}
    </Button>
  )

  return (
    <BaseAccordionInput
      collapsed={collapsed}
      disabled={saveStatus === 'modifying'}
      onToggle={onToggle}
      title={title}
      data-test={dataTest}
      actionElement={saveButton}
      statusElement={icon}
      {...accordionProps}
    >
      {children}
    </BaseAccordionInput>
  )
}
