import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'

export interface ConfirmOtherVenueNavigationModalProps {
  fromVenue: string
  toVenue: string
  onConfirm: () => void
  onClose?: () => void
}

export function ConfirmOtherVenueNavigationModal({ fromVenue, toVenue, onConfirm, onClose }: ConfirmOtherVenueNavigationModalProps) {
  const { formatMessage } = useLocales()

  return (
    <Modal ariaLabel="Modal" width="100%" maxHeight="90%" data-test="reservation-confirm-other-venue-navigation-modal">
      <ModalHeader onClose={() => onClose?.()}>
        <ModalTitle
          title={formatMessage(reservationWidgetMessages.resWidgetOtherVenueNavigationModalHeader, {
            venue: toVenue,
          })}
        />
      </ModalHeader>
      <ModalBody data-test="modal-body">
        <Text textStyle="body1">
          {formatMessage(reservationWidgetMessages.resWidgetOtherVenueNavigationModalBody, {
            venue_to: toVenue,
            venue_from: fromVenue,
          })}
        </Text>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={onClose} data-test="cancel-button">
            {formatMessage(reservationWidgetMessages.resWidgetCancelButtonLabel)}
          </Button>
          <Button variant="primary" onClick={() => onConfirm()} data-test="confirm-button">
            {formatMessage(reservationWidgetMessages.resWidgetContinueButtonLabel)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
