import { RequestService, RTKUtils } from '@sevenrooms/core/api'
import { baseReservationWidgetApi } from '../store/hooks/BaseReservationWidgetApi'

export const { useCreateRequestMutation, useCancelRequestMutation, useRequestDetailQuery } = baseReservationWidgetApi.injectEndpoints({
  endpoints: builder => ({
    createRequest: builder.mutation({
      queryFn: RTKUtils.rtkQuery(RequestService.createRequest),
    }),
    cancelRequest: builder.mutation<void, { requestId: string }>({
      queryFn: RTKUtils.rtkQuery(RequestService.cancelRequest),
    }),
    requestDetail: builder.query({
      queryFn: RTKUtils.rtkQuery(RequestService.getRequest),
    }),
  }),
})
