import { useMemo } from 'react'
import sanitizeHtml from 'sanitize-html'
import type { Fee } from '@sevenrooms/core/domain'
import { FormatService, useLocales } from '@sevenrooms/core/locales'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { VStack, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import { formatFee } from '../../utils'
import { SummaryRow } from '../SummaryRow'

export interface PrivateEventsSummaryProps {
  fees?: Fee[]
  minSpend?: number
  currencyCode: string
}

export function PrivateEventsSummary({ fees, minSpend, currencyCode }: PrivateEventsSummaryProps) {
  const { formatMessage } = useLocales()
  const policy = formatMessage(reservationWidgetMessages.resWidgetGroupBookingPolicyBody)
  const hasPolicy = useMemo(() => (policy ? !!sanitizeHtml(policy, { allowedTags: [] }).trim() : false), [policy])

  if (minSpend === undefined && !fees?.length && !hasPolicy) {
    return null
  }

  return (
    <CardSection p="lm" data-test="private-events-summary" borderColor="borders" backgroundColor="primaryBackground" borderRadius="s">
      <VStack spacing="lm">
        <Text textStyle="h3">{formatMessage(reservationWidgetMessages.resWidgetGroupBookingSummaryLabel)}</Text>
        {(minSpend !== undefined || !!fees?.length) && (
          <VStack spacing="sm">
            {minSpend !== undefined && (
              <>
                <Text textStyle="body1Bold">{formatMessage(reservationWidgetMessages.resWidgetGroupBookingSummarySubLabel)}</Text>
                <SummaryRow
                  data-test="min-spend-value"
                  primaryText={formatMessage(reservationWidgetMessages.resWidgetGroupBookingSummaryMinSpendLabel)}
                  secondaryText={FormatService.formatCurrency(minSpend, currencyCode)}
                />
              </>
            )}
            {fees?.map(fee => (
              <SummaryRow
                primaryText={fee.name ?? ''}
                key={fee.id}
                data-test={`${fee.id}-fee`}
                secondaryText={formatFee(fee, currencyCode)}
              />
            ))}
          </VStack>
        )}
        {hasPolicy && policy && (
          <VStack spacing="s" data-test="private-events-policy">
            <Text textStyle="body1Bold" data-test="policy-body">
              {formatMessage(reservationWidgetMessages.resWidgetGroupBookingPolicyLabel)}
            </Text>
            <Text data-test="policy-body">
              <HTMLContent content={policy} allowedTags={false} />
            </Text>
          </VStack>
        )}
      </VStack>
    </CardSection>
  )
}
