import { FormatService } from '@sevenrooms/core/locales'
import { IconButton } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { HStack, VStack, AutoFallbackImage, Flex, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { UpgradeImagePlaceholder } from './UpgradeImagePlaceholder'

export interface UpgradeProps {
  name: string
  price?: number
  currencyCode: string
  onClick: () => void
  photo?: string
  height?: number | string
  'data-test'?: string
}

export function Upgrade({ name, onClick, photo, price, currencyCode, 'data-test': dataTest }: UpgradeProps) {
  const isMobile = useMaxWidthBreakpoint('s')

  return (
    <CardSection
      variant="secondary"
      hoverable
      height={isMobile ? '100px' : '160px'}
      flexDirection="row"
      data-test={dataTest}
      alignItems="center"
      onClick={onClick}
    >
      <Flex height="100%" width={isMobile ? '140px' : '240px'} flexShrink="0">
        <AutoFallbackImage
          imageUrl={photo}
          data-test="upgrade-card-photo"
          alt="upgrade card photo"
          centered
          width="100%"
          height="100%"
          fallback={<UpgradeImagePlaceholder />}
        />
      </Flex>
      <HStack p="sm" height="100%" flexGrow={1} justifyContent="space-between" alignItems="center" spacing="s">
        <VStack spacing="s">
          <Text color="primaryFont" data-test="upgrade-card-name" textOverflow={isMobile ? 3 : 6}>
            {name}
          </Text>
          {price !== undefined && (
            <Text color="primaryFont" data-test="upgrade-card-price">
              {FormatService.formatCurrency(price, currencyCode)}
            </Text>
          )}
        </VStack>
        <IconButton
          borderType="round"
          icon="VMSWeb-chevron-right"
          size="s"
          aria-label="upgrade-card-add-button"
          data-test="upgrade-card-add-button"
        />
      </HStack>
    </CardSection>
  )
}
