import { useMemo } from 'react'
import { type Field, useController } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormSelect, ErrorMessage } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { VStack, Box } from '@sevenrooms/core/ui-kit/layout'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export interface SelectTipProps {
  id: string
  dataTest: string
  minValue: number
  maxValue: number
  disabled?: boolean
  tipField: Field<number | null>
  onChange?: (value: number | null) => void
}

export function SelectTip({ minValue, maxValue, dataTest, disabled, id, tipField, onChange }: SelectTipProps) {
  const {
    fieldState: { error },
  } = useController(tipField)
  const options = useMemo(() => generateOptions(minValue, maxValue), [minValue, maxValue])
  const { formatMessage } = useLocales()

  const isMobile = useMaxWidthBreakpoint('s')

  return (
    <VStack>
      <Box width="100px">
        <FormSelect
          id={id}
          field={tipField}
          data-test={dataTest}
          disabled={disabled}
          options={options}
          searchable={false}
          onChange={onChange}
          placeholder={formatMessage(reservationWidgetMessages.commonPaymentGeneralSelectLabel)}
          withNativeSupport
          withEmpty
          hideEmptyOption={!isMobile}
          hideErrorMessage
        />
      </Box>
      {error?.message && <ErrorMessage>{error.message}</ErrorMessage>}
    </VStack>
  )
}

export const generateOptions = (minValue: number, maxValue: number) => {
  const result = []
  let curr = minValue
  while (curr <= maxValue) {
    result.push({
      id: curr,
      label: `${curr}%`,
    })
    curr += 1
  }
  return result
}
