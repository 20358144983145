import { configureStore } from '@reduxjs/toolkit'
import { paymentApi } from '@sevenrooms/payments'
import { baseReservationWidgetApi } from './hooks'
import { type PreloadedState, preloadedState } from './preloadedState'
import { rootReducer } from './reducers'

export const store = configureStore({
  devTools: process.env.NODE_ENV === 'development',
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat([baseReservationWidgetApi.middleware, paymentApi.middleware]),
  preloadedState,
  reducer: rootReducer,
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState> & PreloadedState
