import { useEffect, useRef, useState } from 'react'

export function useTimer(endTime?: Date) {
  const interval = useRef<number>()
  const [seconds, setSeconds] = useState<number | undefined>(undefined)

  useEffect(() => {
    if (endTime) {
      if (interval.current) {
        window.clearInterval(interval.current)
        interval.current = undefined
      }
      setSeconds(getRemainingSeconds(endTime))
      interval.current = window.setInterval(() => {
        const remainingSeconds = getRemainingSeconds(endTime)
        setSeconds(remainingSeconds)
        if (!remainingSeconds) {
          window.clearInterval(interval.current)
          interval.current = undefined
        }
      }, 1000)
    }
    return () => {
      if (interval.current) {
        window.clearInterval(interval.current)
        interval.current = undefined
      }
    }
  }, [endTime])

  return seconds
}

function getRemainingSeconds(endTime?: Date) {
  return endTime ? Math.max(Math.floor((endTime.getTime() - new Date().getTime()) / 1000), 0) : 0
}
