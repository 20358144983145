import { useCallback } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenue } from '../../../hooks'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'

export function BookReservation() {
  const venue = useVenue()
  const { formatMessage } = useLocales()

  const onBookBtnClick = useCallback(() => {
    const url = venue.newResWidgetEnabled ? `/explore/${venue.urlKey}/reservations` : `/reservations/${venue.urlKey}`
    window.open(url, '_blank')
  }, [venue.newResWidgetEnabled, venue.urlKey])

  return (
    <VStack spacing="m" width="100%">
      <Text textStyle="h3" data-test="reservation-status-sub-header">
        {formatMessage(reservationWidgetMessages.resCancelPageSuccessSubheader)}
      </Text>
      <Box width="227px">
        <Button variant="primary" data-test="book-button" fullWidth onClick={onBookBtnClick} isExternal>
          {formatMessage(reservationWidgetMessages.resCancelPageSuccessBookButton)}
        </Button>
      </Box>
    </VStack>
  )
}
