import type { SchemaCategories } from '../../../utils'

export const getCategoryMaxRemaining = (
  categories: SchemaCategories,
  category: {
    id: string
    name: string
    sortOrder: number
    venueId: string
    gratuityChargeType: 'DEFAULT_GRATUITY' | 'SPECIFIC_GRATUITY' | 'CLIENT_GRATUITY'
    gratuityPercentage: number | null
    isChargingGratuity: boolean
    isChargingServiceCharge: boolean
    isChargingTax: boolean
    requireGratuityCharge: boolean
    maxQuantityInt: number
    maxQuantityType: 'NUMBER_OF_GUESTS' | 'SPECIFIC_NUMBER' | 'UNLIMITED'
    minQuantityInt: number
    minQuantityType: 'NONE_REQUIRED' | 'NUMBER_OF_GUESTS' | 'SPECIFIC_NUMBER'
    serviceChargePercentage: number | null
    serviceChargeType: 'DEFAULT_SERVICE_CHARGE' | 'SPECIFIC_SERVICE_CHARGE'
    showCategoryFlag: boolean
    taxGroupId: string | null
  },
  partySize?: number
) => {
  if (category.maxQuantityType === 'UNLIMITED') {
    return undefined
  }

  const upgrades = categories[category.id]?.upgrades || {}
  const selectedUpgradesAmount = Object.values(upgrades).reduce((accumulator, upgradeAmount) => accumulator + upgradeAmount, 0)

  if (category.maxQuantityType === 'NUMBER_OF_GUESTS') {
    if (!partySize) {
      return undefined
    }
    return partySize - selectedUpgradesAmount
  }

  return category.maxQuantityInt - selectedUpgradesAmount
}
