import { useCallback, type PropsWithChildren, type ReactNode } from 'react'
import styled from 'styled-components'
import { CollapseButton } from '@sevenrooms/core/ui-kit/form'
import { Box, DividerLine, Flex, HStack, VStack, type VStackProps } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

export interface BaseAccordionInputProps extends Omit<VStackProps, 'onError' | 'onChange'> {
  title: string
  subTitle?: ReactNode
  'data-test'?: string
  actionElement?: JSX.Element
  statusElement?: JSX.Element
  disabled?: boolean
  collapsed: boolean
  onToggle: (collapsed: boolean) => void
}

export function BaseAccordionInput({
  title,
  subTitle,
  collapsed,
  disabled,
  onToggle,
  actionElement,
  statusElement,
  children,
  'data-test': dataTest,
  ...vStackProps
}: PropsWithChildren<BaseAccordionInputProps>) {
  const handleContainerClick = useCallback(() => {
    if (disabled) {
      return
    }
    onToggle(!collapsed)
  }, [collapsed, disabled, onToggle])

  return (
    <>
      <VStack data-test={dataTest} p="m" {...vStackProps}>
        <TitleContainer alignItems="center" spacing="s" onClick={handleContainerClick}>
          <Box minWidth="42px">
            <CollapseButton
              aria-label="accordion-expand-toggle"
              disabled={disabled}
              collapsed={collapsed}
              collapseIcon="plus-minus"
              onToggle={onToggle}
            />
          </Box>
          <VStack>
            <Text data-test={dataTest && `${dataTest}-title`}>{title}</Text>
            {collapsed && subTitle && (
              <Text
                textOverflow="twoLinesEllipsis"
                data-test={dataTest && `${dataTest}-sub-title`}
                fontWeight="bold"
                overflowWrap="anywhere"
              >
                {subTitle}
              </Text>
            )}
          </VStack>
          <Flex ml="auto">{statusElement}</Flex>
        </TitleContainer>
        {!collapsed && (
          <>
            {children}
            <HStack mt="lm" mr="s" mb="none" ml="s" justifyContent="flex-start">
              {actionElement}
            </HStack>
          </>
        )}
      </VStack>
      <DividerLine color="borders" margin="none" ml="lm" hideLast />
    </>
  )
}

const TitleContainer = styled(HStack)`
  cursor: pointer;
`
