import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { SkeletonBox } from '../../../components/SkeletonBox'

export function ManageReservationSkeleton() {
  const isMobile = useMaxWidthBreakpoint('s')

  return (
    <>
      <VStack width="100%" maxWidth={isMobile ? '100%' : '428px'} spacing="lm">
        {isMobile && <SkeletonBox height="54px" />}
        <SkeletonBox height="542px" />
      </VStack>
      <VStack width="100%" spacing="l">
        {!isMobile && <SkeletonBox height="54px" />}
        <SkeletonBox height="376px" />
        <SkeletonBox height="51px" />
        <SkeletonBox height="103px" />
        <SkeletonBox height="117px" />
        <SkeletonBox height="148px" />
        <SkeletonBox height="278px" />
      </VStack>
    </>
  )
}
