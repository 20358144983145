import type { UpdateGuestPreferencesRequest, UpdateGuestPreferenceType, VenueTags } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { SectionController, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { GuestImageInput } from './GuestImageInput'
import { GuestOtherInfoInput } from './GuestOtherInfoInput'
import { guestPreferencesMessages } from './guestPreferencesMessages'
import { GuestPreferencesSelect } from './GuestPreferencesSelect'
import type { AccordionPreferenceOption } from './AccordionInput'

export type GuestPreferencesValue = Partial<UpdateGuestPreferencesRequest>

export interface GuestPreferencesProps {
  venueTags?: VenueTags
  actualId?: string
  clientId?: string
  venueId?: string
  clientPhoto?: string
  clientDisplayName?: string
  guestCount?: number
  otherInfoText?: string
  showDietaryRestrictions: boolean
  showSpecialOccasions: boolean
  champagnePreferenceTags?: string[]
  clientDietaryPreferenceTags?: string[]
  liquorPreferenceTags?: string[]
  partyDietaryPreferenceTags?: string[]
  specialOccasionTags?: string[]
  showGuestImageInput?: boolean
  showCustomQuestion?: boolean
  onTagSave: (options: AccordionPreferenceOption[], type: Partial<UpdateGuestPreferenceType>) => Promise<boolean>
  onCustomQuestionSave: (value?: string) => Promise<boolean>
}

export function GuestPreferences({
  actualId,
  clientId,
  venueId,
  clientPhoto,
  clientDisplayName,
  venueTags,
  otherInfoText,
  champagnePreferenceTags,
  clientDietaryPreferenceTags,
  liquorPreferenceTags,
  partyDietaryPreferenceTags,
  specialOccasionTags,
  showSpecialOccasions,
  showDietaryRestrictions,
  showCustomQuestion,
  showGuestImageInput,
  guestCount = 0,
  onTagSave,
  onCustomQuestionSave,
}: GuestPreferencesProps) {
  const { formatMessage } = useLocales()
  const saveText = formatMessage(guestPreferencesMessages.guestPreferencesSave)

  if (!showCustomQuestion && !showGuestImageInput && (!venueTags || (!showSpecialOccasions && !showDietaryRestrictions))) {
    return null
  }

  return (
    <SectionController behavior="one-section-at-one-time">
      <Text data-test="guest-preferences-header" textStyle="body1Bold">
        {formatMessage(guestPreferencesMessages.guestPreferencesHeader)}
      </Text>
      <CardSection data-test="guest-preferences-section">
        {showSpecialOccasions && venueTags && specialOccasionTags && 'specialOccasionTags' in venueTags.reservationTags && (
          <GuestPreferencesSelect
            sectionName="specialOccasionTags"
            title={formatMessage(guestPreferencesMessages.guestSpecialOccasion)}
            onSave={onTagSave}
            venueTags={venueTags.reservationTags.specialOccasionTags}
            preferences={specialOccasionTags}
            saveText={saveText}
            selectType="specialOccasionTags"
            data-test="special-occasion-tags"
          />
        )}
        {showDietaryRestrictions && venueTags && (
          <>
            {clientDietaryPreferenceTags && 'dietaryPreferenceTags' in venueTags.clientTags && (
              <GuestPreferencesSelect
                title={formatMessage(guestPreferencesMessages.guestDietaryRestriction)}
                sectionName="clientDietaryPreferenceTags"
                onSave={onTagSave}
                venueTags={venueTags.clientTags.dietaryPreferenceTags}
                preferences={clientDietaryPreferenceTags}
                saveText={saveText}
                selectType="clientDietaryPreferenceTags"
                data-test="client-dietary-preference-tags"
              />
            )}
            {guestCount > 1 && partyDietaryPreferenceTags && 'dietaryPreferenceTags' in venueTags.reservationTags && (
              <GuestPreferencesSelect
                title={formatMessage(guestPreferencesMessages.partyDietaryRestriction)}
                sectionName="partyDietaryPreferenceTags"
                onSave={onTagSave}
                venueTags={venueTags.reservationTags.dietaryPreferenceTags}
                preferences={partyDietaryPreferenceTags}
                saveText={saveText}
                selectType="partyDietaryPreferenceTags"
                data-test="party-dietary-preference-tags"
              />
            )}
            {champagnePreferenceTags && 'champagnePreferenceTags' in venueTags.clientTags && (
              <GuestPreferencesSelect
                title={formatMessage(guestPreferencesMessages.guestChampagnePreference)}
                sectionName="champagnePreferenceTags"
                onSave={onTagSave}
                venueTags={venueTags.clientTags.champagnePreferenceTags}
                preferences={champagnePreferenceTags}
                saveText={saveText}
                selectType="champagnePreferenceTags"
                data-test="champagne-preference-tags"
              />
            )}
            {liquorPreferenceTags && 'liquorPreferenceTags' in venueTags.clientTags && (
              <GuestPreferencesSelect
                title={formatMessage(guestPreferencesMessages.guestLiquorPreference)}
                sectionName="liquorPreferenceTags"
                onSave={onTagSave}
                venueTags={venueTags.clientTags.liquorPreferenceTags}
                preferences={liquorPreferenceTags}
                saveText={saveText}
                selectType="liquorPreferenceTags"
                data-test="liquor-preference-tags"
              />
            )}
          </>
        )}
        {showCustomQuestion && (
          <GuestOtherInfoInput
            sectionName="guestOtherInfo"
            data-test="guest-preferences-other-info"
            saveText={saveText}
            title={formatMessage(guestPreferencesMessages.guestOtherInfo)}
            infoText={otherInfoText}
            onSave={onCustomQuestionSave}
          />
        )}
        {showGuestImageInput && (
          <GuestImageInput
            data-test="guest-preferences-image-upload"
            sectionName="guestImageInput"
            saveText={saveText}
            actualId={actualId}
            clientId={clientId}
            clientPhotoUrl={clientPhoto}
            clientDisplayName={clientDisplayName}
            venueId={venueId}
          />
        )}
      </CardSection>
    </SectionController>
  )
}
