import mergeRefs from 'react-merge-refs'
import sanitizeHtml from 'sanitize-html'
import styled from 'styled-components'
import type { AvailabilityChargeType, Upgrade } from '@sevenrooms/core/domain'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { DateOnly, TimeOnly } from '@sevenrooms/core/timepiece'
import { HTMLContent } from '@sevenrooms/core/ui-kit/core'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { useOverflowed, useThrottledResizeObserver } from '@sevenrooms/core/ui-kit/hooks'
import {
  Box,
  DividerLine,
  Flex,
  HStack,
  VStack,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text } from '@sevenrooms/core/ui-kit/typography'
import { getTimeslotDescription } from '../../../components/PrimaryAvailability/ReservationAvailability'
import { useWidgetSettings } from '../../../hooks'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { reservationAvailabilityModalMessages } from './reservationAvailabilityModalMessages'
import { ReservationAvailabilityModalUpgrades } from './ReservationAvailabilityModalUpgrades'
import type { AvailabilityTimeWithUpSellCost } from '../../../hooks/useAvailability'

export interface ReservationAvailabilityModalProps {
  currencyCode: string
  title?: string
  description?: string
  cancellationPolicy?: string
  timeIso: string
  chargeType?: AvailabilityChargeType
  cost?: number | null
  fees: number
  showCost?: boolean
  duration: number
  image?: string
  upgrades?: Upgrade[]
  onConfirm: (timeslot: AvailabilityTimeWithUpSellCost) => void
  onClose?: () => void
  timeslot: AvailabilityTimeWithUpSellCost
}

const IMAGE_HEIGHT = '270px'

export function ReservationAvailabilityModal({
  title = '',
  description,
  cancellationPolicy,
  image,
  timeIso,
  showCost = false,
  cost,
  fees,
  chargeType,
  duration,
  currencyCode,
  upgrades,
  onConfirm,
  onClose,
  timeslot,
}: ReservationAvailabilityModalProps) {
  const { formatMessage } = useLocales()
  const { ref: resizeRef, height: wrapperHeight } = useThrottledResizeObserver(50, 'border-box')
  const [isOverflowed, overflowRef] = useOverflowed(wrapperHeight)
  const showTopDividerLine = isOverflowed || !title
  const formattedTime = TimeOnly.from(timeIso).formatSTime()
  const formattedDate = DateOnly.from(timeIso).formatSMonthNDaySWeek()
  const formattedCost = getTimeslotDescription(formatMessage, undefined, showCost, cost, chargeType, duration, currencyCode, fees)
  const widgetSettings = useWidgetSettings()
  return (
    <Modal ariaLabel="Modal" width="100%" maxHeight="90%" data-test="reservation-availability-modal">
      <ModalHeader position="relative" boxShadow={isOverflowed ? 'tertiary' : 'none'} onClose={() => onClose?.()}>
        <ModalTitle title={title} />
      </ModalHeader>
      {showTopDividerLine && <DividerLine margin="none" />}
      <ModalBody ref={mergeRefs([overflowRef, resizeRef])}>
        <VStack spacing="lm" mt={showTopDividerLine ? 'lm' : 'none'} mb={isOverflowed ? 'lm' : 'none'}>
          {image && (
            <Flex alignItems="center" justifyContent="center" width="100%" height="100%" borderRadius="s" overflow="hidden">
              <StyledImage
                src={image}
                maxHeight={IMAGE_HEIGHT}
                width="100%"
                alt={formatMessage(reservationAvailabilityModalMessages.imageAlt)}
                data-test="public-photo"
              />
            </Flex>
          )}
          <Text textStyle="body1">
            <HStack spacing="s" flexWrap="wrap" alignItems="center">
              <Box data-test="timeslot-date">{formattedDate}</Box>
              <Box>•</Box>
              <Box data-test="timeslot-time">{formattedTime}</Box>
              {formattedCost && (
                <>
                  <Box>•</Box>
                  <HStack data-test="timeslot-cost" columnGap="xxs">
                    <Text>{formattedCost}</Text>
                    {fees !== 0 && (
                      <SecondaryText>
                        {formatMessage(reservationWidgetMessages.resWidgetIncludesFees, {
                          fees: FormatService.formatCurrency(fees, currencyCode),
                        })}
                      </SecondaryText>
                    )}
                  </HStack>
                </>
              )}
            </HStack>
          </Text>
          <DividerLine margin="none" />
          {!!upgrades?.length && (
            <>
              <ReservationAvailabilityModalUpgrades
                upgrades={upgrades}
                isFeesInPriceDisplayed={widgetSettings.isFeesInPriceDisplayed}
                currencyCode={currencyCode}
              />
              <DividerLine margin="none" />
            </>
          )}
          {description && (
            <VStack spacing="xs">
              <Text textStyle="body1Bold" data-test="timeslot-description-title">
                {formatMessage(reservationAvailabilityModalMessages.descriptionTitle)}
              </Text>
              <Text textStyle="body1">
                <HTMLContent
                  content={description}
                  allowedTags={sanitizeHtml.defaults.allowedTags}
                  data-test="timeslot-description-content"
                />
              </Text>
            </VStack>
          )}
          {showCost && cancellationPolicy && (
            <VStack spacing="xs">
              <Text textStyle="body1Bold" data-test="timeslot-cancellation-policy-header">
                {formatMessage(reservationAvailabilityModalMessages.cancellationPolicyHeader)}
              </Text>
              <Text textStyle="body1">
                <HTMLContent content={cancellationPolicy} allowedTags={false} data-test="timeslot-cancellation-policy-content" />
              </Text>
            </VStack>
          )}
        </VStack>
      </ModalBody>
      {isOverflowed && <DividerLine margin="none" />}
      <ModalFooter>
        <Button fullWidth variant="primary" onClick={() => onConfirm(timeslot)} data-test="confirm-button" size="l">
          {formatMessage(reservationAvailabilityModalMessages.confirmButton)}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export const StyledImage = styled.img<{ maxHeight: string }>`
  object-fit: cover;
  object-position: center center;
  max-height: ${props => props.maxHeight};
`
