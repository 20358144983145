import { defineMessages } from '@sevenrooms/core/locales'

export const policyDetailsMessages = defineMessages({
  bookingCheckoutPolicyHeader: {
    id: 'policy.details.bookingCheckout.header',
    defaultMessage: 'A note from us',
  },
  cancellationPolicyHeader: {
    id: 'policy.details.cancellation.header',
    defaultMessage: 'Cancellation Policy',
  },
  specialAttentionMessageHeader: {
    id: 'policy.details.specialAttention.header',
    defaultMessage: 'Our commitment to your safety during COVID-19',
  },
  specialAttentionMessageBody: {
    id: 'policy.details.specialAttention.body',
    defaultMessage:
      'Nothing is more important to us than the safety of our guests. In addition to meeting all health standards, we are taking extra precaution by ensuring that tables are spaced apart, and all surfaces are thoroughly disinfected between seatings.',
  },
})
