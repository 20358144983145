import type { Upgrade as UpgradeModel } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { StackResponsive, VStack, Modal, ModalBody, ModalHeader, type ModalHeaderProps, ModalFooter } from '@sevenrooms/core/ui-kit/layout'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { AdditionalUpgrades } from '../AdditionalUpgradesCard/AdditionalUpgrades'
import { ManageReservationHeader } from '../ManageReservationHeader'

export interface AddUpgradesModalProps {
  upgrades: UpgradeModel[]
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onConfirm: () => void
  currencyCode: string
}

export function AddUpgradesModal({ upgrades, onConfirm, closeHref, currencyCode }: AddUpgradesModalProps) {
  const { formatMessage } = useLocales()
  if (!upgrades.length) {
    return null
  }

  return (
    <Modal ariaLabel="Modal" width="100%" data-test="add-upgrades-modal">
      <ModalHeader closeHref={closeHref}>
        <ManageReservationHeader flowType="confirmation" />
      </ModalHeader>
      <ModalBody pb="lm">
        <VStack spacing="lm">
          <AdditionalUpgrades upgrades={upgrades?.slice(0, 3)} onClick={onConfirm} currencyCode={currencyCode} />
        </VStack>
      </ModalBody>
      <ModalFooter>
        <StackResponsive spacing="sm" data-test="modal-actions-panel">
          <Button fullWidth variant="secondary" data-test="cancel-button" href={closeHref}>
            {formatMessage(reservationWidgetMessages.resConfirmPageNoThanks)}
          </Button>
          <Button fullWidth variant="primary" onClick={onConfirm} data-test="confirm-button">
            {formatMessage(reservationWidgetMessages.resConfirmPageViewAllUpgrades)}
          </Button>
        </StackResponsive>
      </ModalFooter>
    </Modal>
  )
}
