import type { LocaleKeys } from '@sevenrooms/core/locales'
import { DateOnly, isToday } from '@sevenrooms/core/timepiece'

export const getRelativeFormattedDate = ({ date, locale }: { date: Date; locale?: LocaleKeys }) => {
  const dateOnly = DateOnly.fromDate(date)

  if (isToday(date)) {
    const relativeFormat = dateOnly.relativeTimeFormatDateOnly({ localeKey: locale, value: 0 })

    // We know this is not a best practice but there is no clean alternative at this point.
    // https://github.com/tc39/proposal-intl-relative-time/issues/11
    const titleCaseRelativeFormat = relativeFormat.charAt(0).toUpperCase() + relativeFormat.slice(1)

    const dateFormattedToParts = dateOnly.formatToPartsMonthDay({ localeKey: locale })

    return dateFormattedToParts
      .map(part => {
        if (part.type === 'weekday') {
          return titleCaseRelativeFormat
        }
        return part.value
      })
      .join('')
  }

  return dateOnly.formatSMonthNDaySWeek(locale)
}
