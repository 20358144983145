import { RTKUtils, GuestPromoCodeService } from '@sevenrooms/core/api'
import { baseReservationWidgetApi } from './BaseReservationWidgetApi'

const PromoCodeApiTags = ['PromoCodes']

export const promoCodeApi = baseReservationWidgetApi.enhanceEndpoints({ addTagTypes: PromoCodeApiTags }).injectEndpoints({
  endpoints: builder => ({
    validatePromoCode: builder.mutation({
      queryFn: RTKUtils.rtkQuery(GuestPromoCodeService.validatePromoCode),
    }),
    getPromoCodes: builder.query({
      providesTags: PromoCodeApiTags,
      queryFn: RTKUtils.rtkQuery(GuestPromoCodeService.getPromoCodes),
    }),
  }),
})

export const { useValidatePromoCodeMutation, useGetPromoCodesQuery } = promoCodeApi
