import { RTKUtils, DiningVenuesInfoService, type DiningVenuesInfoRequest } from '@sevenrooms/core/api'
import type { ReservationWidget } from '@sevenrooms/core/domain'
import { baseReservationWidgetApi } from './BaseReservationWidgetApi'

const DiningVenuesInfoApiTags = ['DiningVenuesInfo']

export const diningVenuesInfoApi = baseReservationWidgetApi.enhanceEndpoints({ addTagTypes: DiningVenuesInfoApiTags }).injectEndpoints({
  endpoints: builder => ({
    getDiningVenuesInfo: builder.query<ReservationWidget.VenueInfo, DiningVenuesInfoRequest>({
      queryFn: RTKUtils.rtkQuery(DiningVenuesInfoService.getDiningVenuesInfo),
    }),
  }),
})

export const { useGetDiningVenuesInfoQuery } = diningVenuesInfoApi
