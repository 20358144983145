import { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import type { ImageObject } from '@sevenrooms/core/domain'
import { ImageUploadButton, ImageEditPopup } from '@sevenrooms/core/ui-kit/form'
import { Box } from '@sevenrooms/core/ui-kit/layout'
import { useAccordionStatus } from './AccordionUtils'
import { BaseAccordionInput, type BaseAccordionInputProps } from './BaseAccordionInput'

export interface AccordionImageInputProps
  extends Omit<BaseAccordionInputProps, 'collapsed' | 'setCollapsed' | 'actionElement' | 'statusElement'> {
  collapsed: boolean
  isDirty: boolean
  image?: ImageObject
  imageEditSubtitle?: string
  editText?: string
  replaceText?: string
  noImageEditText?: string
  saveText?: string
  cancelText?: string
  deleteText?: string
  onSave: (image: ImageObject) => Promise<boolean>
  onImageChange: (image: ImageObject) => void
  onDelete: () => Promise<boolean>
  onCancel?: () => void
  onError?: (status: string) => void
  onToggle: (collapsed: boolean) => void
}

const MAX_IMAGE_SIZE = 4 * 1024 * 1024

/**
 * When using this component please use useAccordionImage from AccordionUtils to help manage props
 */
export function AccordionImageInput({
  isDirty,
  image,
  onSave,
  onError,
  onToggle,
  collapsed,
  onDelete,
  onCancel,
  onImageChange,
  title,
  saveText,
  deleteText,
  cancelText,
  replaceText,
  imageEditSubtitle = 'Max image size 4MB',
  editText = 'Edit Image',
  noImageEditText = 'Upload Photo From Device',
  ...accordionInputProps
}: AccordionImageInputProps) {
  const [editImage, setEditImage] = useState(false)
  const [preview, setPreview] = useState(image?.preview ?? image?.rawUrl)
  const { icon, onAccordionSave, onAccordionDelete, saveStatus } = useAccordionStatus(
    isDirty,
    onSave,
    image ? 'modified' : 'idle',
    onDelete
  )

  const onUploadClick = useCallback(() => {
    setEditImage(true)
  }, [])

  const onGuestImageSave = useCallback(
    async (newImage: ImageObject) => {
      if (!isDirty) {
        return
      }
      try {
        setPreview(newImage.preview)
        const success = await onAccordionSave(newImage)
        setEditImage(!success)
      } catch (e) {
        setEditImage(true)
        if (onError) {
          onError(e instanceof Error ? e.message : 'SAVE')
        }
      }
    },
    [isDirty, onAccordionSave, onError]
  )

  const onGuestImageRemove = useCallback(async () => {
    try {
      if (!image) {
        return
      }
      setEditImage(false)
      const success = await onAccordionDelete()
      if (success) {
        return
      }
    } catch (e) {
      if (onError) {
        onError(e instanceof Error ? e.message : 'DELETE')
      }
    }
    setEditImage(true)
  }, [image, onAccordionDelete, onError])

  useEffect(() => {
    setPreview(image?.preview ?? image?.rawUrl)
    if (collapsed && image) {
      onGuestImageSave(image)
    }
  }, [collapsed, image, image?.preview, image?.rawUrl, onGuestImageSave])

  return (
    <BaseAccordionInput
      title={title}
      collapsed={collapsed}
      onToggle={onToggle}
      actionElement={
        <ImageUploadButton
          onClick={onUploadClick}
          hasImage={!!image}
          onImageUpload={onImageChange}
          maxSize={MAX_IMAGE_SIZE}
          text={image ? editText : noImageEditText}
          variant="secondary"
          fullWidth={!image}
          disabled={saveStatus === 'modifying'}
          onError={onError}
          data-test="image-upload-button"
        />
      }
      statusElement={icon}
      {...accordionInputProps}
    >
      {preview && (
        <Box p="s" mt="s">
          <AccordionImageWrapper src={preview} alt="Uploaded Image Preview" />
        </Box>
      )}
      {image && (
        <ImageEditPopup
          title={title}
          subtitle={imageEditSubtitle}
          active={editImage}
          setActive={setEditImage}
          file={image}
          setFile={onImageChange}
          accept="image/*"
          maxSize={MAX_IMAGE_SIZE}
          onSave={onGuestImageSave}
          onDelete={onGuestImageRemove}
          onCancel={onCancel}
          saveText={saveText}
          deleteText={deleteText}
          cancelText={cancelText}
          replaceText={replaceText}
          aspect={1}
          circularCrop
        />
      )}
    </BaseAccordionInput>
  )
}

const AccordionImageWrapper = styled.img`
  border-radius: 50%;
  width: 128px;
  height: 128px;
`
