import { defineMessages } from '@sevenrooms/core/locales'

export const upgradesBannerMessages = defineMessages({
  title: {
    id: 'upgrades.banner.title',
    defaultMessage: 'Enhance your experience with one of our signature items.',
  },
  viewUpgradesButton: {
    id: 'upgrades.banner.viewUpgrades',
    defaultMessage: 'View Upgrades',
  },
})
