import { createSlice, type PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { GlobalClientAuthService } from '@sevenrooms/core/api'
import type { CountryCode } from '@sevenrooms/core/domain'

export type ClientLoginProvider = 'google' | 'facebook' | 'sevenrooms'

export interface ClientLoginState {
  isGettingClaims: boolean
  isLoggingOut: boolean
  provider?: ClientLoginProvider
  clientId?: string
  firstName?: string
  lastName?: string
  emailAddress?: string
  image?: string
  phoneNumber?: string
  phoneCountryCode?: CountryCode
  phoneDialCode?: string
}

const sevenRoomsLogoutAction = createAsyncThunk('clientLogin/sevenrooms/logout', async (_, { dispatch }) => {
  await GlobalClientAuthService.logout()
  dispatch(clientLoginActions.logout())
})

const getSevenRoomsClientClaimsAction = createAsyncThunk('clientLogin/sevenrooms/getClaims', async () =>
  GlobalClientAuthService.getExistingLoginCredentials()
)

const initialState: ClientLoginState = {
  isGettingClaims: false,
  isLoggingOut: false,
}

const clientLoginSlice = createSlice({
  name: 'clientLogin',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<Omit<ClientLoginState, 'isGettingClaims' | 'isLoggingOut'>>) => {
      const { payload: newState } = action
      return { ...newState, isGettingClaims: state.isGettingClaims, isLoggingOut: state.isLoggingOut }
    },
    logout: _state => initialState,
  },
  extraReducers: builder => {
    builder.addCase(sevenRoomsLogoutAction.pending, state => {
      state.isLoggingOut = true
    })
    builder.addCase(sevenRoomsLogoutAction.rejected, state => {
      state.isLoggingOut = false
    })
    builder.addCase(sevenRoomsLogoutAction.fulfilled, state => {
      state.isLoggingOut = false
    })
    builder.addCase(getSevenRoomsClientClaimsAction.pending, state => {
      state.isGettingClaims = true
    })
    builder.addCase(getSevenRoomsClientClaimsAction.rejected, state => {
      state.isGettingClaims = false
    })
    builder.addCase(getSevenRoomsClientClaimsAction.fulfilled, (state, action) => {
      const { payload: clientClaims } = action
      if (!clientClaims) {
        return { ...state, isGettingClaims: false }
      }
      return { provider: 'sevenrooms', ...clientClaims, isGettingClaims: false, isLoggingOut: state.isLoggingOut }
    })
  },
})

export const clientLoginActions = { ...clientLoginSlice.actions, getSevenRoomsClientClaimsAction, sevenRoomsLogoutAction }
export const clientLoginReducer = clientLoginSlice.reducer
export const clientLoginName = clientLoginSlice.name
