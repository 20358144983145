import styled from 'styled-components'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, type ButtonProps } from '@sevenrooms/core/ui-kit/form'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import type { CSSProperties } from 'react'

export interface SubmitARequestButtonProps {
  variant: 'primary' | 'secondary'
  width: CSSProperties['width']
  size: ButtonProps['size']
  onClick: () => void
}

export function SubmitARequestButton({ onClick, variant, width, size }: SubmitARequestButtonProps) {
  const { formatMessage } = useLocales()

  return (
    <StyledButton size={size} noPadding width={width} variant={variant} data-test="submit-a-request-button" onClick={onClick}>
      {formatMessage(reservationWidgetMessages.resWidgetSubmitRequestButton)}
    </StyledButton>
  )
}

const StyledButton = styled(Button)<{ width: CSSProperties['width'] }>`
  width: ${props => props.width};
`
