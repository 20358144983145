import { useLocales, FormatService } from '@sevenrooms/core/locales'
import type { ModalHeaderProps } from '@sevenrooms/core/ui-kit/layout'
import { useVenue } from '../../../hooks'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { CancelModalActionPanel } from '../CancelModalActionPanel'
import { NotificationModal } from '../NotificationModal'
import type { ReactNode } from 'react'

interface CancellationFeeModalProps {
  amount: number
  cardBrand: string
  cardLast4: string
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onCancelClick?: () => void
  isCancelDisabled?: boolean
  cancellationPolicy?: ReactNode
}

export function CancellationFeeModal({
  cancellationPolicy,
  amount,
  cardBrand,
  cardLast4,
  closeHref,
  onCancelClick,
  isCancelDisabled,
}: CancellationFeeModalProps) {
  const { formatMessage } = useLocales()
  const venue = useVenue()

  return (
    <NotificationModal
      type="error"
      data-test="cancellation-fee-modal"
      title={formatMessage(reservationWidgetMessages.resCancelPageHeader)}
      showIcon
      primary={formatMessage(reservationWidgetMessages.resCancelPageCancellationFeeText, {
        amount: FormatService.formatCurrency(amount, venue.currency),
        card_details: FormatService.formatPaymentCard(cardBrand, cardLast4),
      })}
      secondary={cancellationPolicy}
      closeHref={closeHref}
      actionsPanel={<CancelModalActionPanel closeHref={closeHref} onCancelClick={onCancelClick} isCancelDisabled={isCancelDisabled} />}
    />
  )
}
