import { Modal, ModalBody, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { ReactNode } from 'react'

export interface SimpleModalProps {
  title: string
  body: ReactNode
  onClose?: () => void
  'data-test'?: string
}

const SIMPLE_MODAL_MAX_HEIGHT = '420px'

export function SimpleModal({ title, body, onClose, 'data-test': dataTest = 'special-attention-modal' }: SimpleModalProps) {
  return (
    <Modal ariaLabel="Modal" width="100%" data-test={dataTest}>
      <ModalHeader onClose={() => onClose?.()}>
        <ModalTitle title={title} />
      </ModalHeader>
      <ModalBody mb="lm" data-test="modal-body" maxHeight={SIMPLE_MODAL_MAX_HEIGHT}>
        <Text textStyle="body1">{body}</Text>
      </ModalBody>
    </Modal>
  )
}
