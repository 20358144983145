import type { LanguageCode } from '@sevenrooms/core/api'
import type { CountryCode, PromoCode, BillingAddress, RedemptionData, ReservationWidget } from '@sevenrooms/core/domain'
import { ReservationWidget as ReservationWidgetConst } from '@sevenrooms/core/domain/constants'
import { allCountries, iso2Lookup } from '@sevenrooms/core/locales'
import { DateOnly, Formats, legacyParseAdapter, TimeOnly, Timezone, isValid } from '@sevenrooms/core/timepiece'
import { preloadedState } from '../preloadedState'
import type { FormStateActual } from './reservationFormSlice'
import type { AvailabilityTimeWithUpSellCost, PrivateEventsExperience } from '../../hooks/useAvailability'
import type { SchemaCategories } from '../../utils/upgradesUtils'

const { widgetSettings, venueInfo, clientInfo } = preloadedState

const searchParams = new URLSearchParams(document.location.search)

export const getInitialReservationFormStateState = (): {
  partySize: number
  startDate: string
  privateEventsPartySize: number | null
  privateEventsStartDate: string | undefined
  startTime: string
  haloTimeIntervalMinutes: number
  venues: string | null
  searchTab: ReservationWidget.SearchTab
  selectedTimeSlot: AvailabilityTimeWithUpSellCost | undefined
  selectedExperience: PrivateEventsExperience | undefined
  actual: FormStateActual | undefined
  categories: SchemaCategories
  birthdayDay: string | null
  birthdayMonth: string | null
  emailAddress: string
  firstName: string
  gratuityPercentage: number | null
  lastName: string
  phoneNumber?: string
  postalCode: string
  preferredLanguage: LanguageCode
  agreedToBookingPolicy: boolean
  agreedCustomCheckoutPolicy: boolean
  agreedToVenueGroupMarketingOptIn: boolean
  agreedToTailoredCommunicationOptIn: boolean
  agreedToVenueSpecificMarketingOptIn: boolean
  agreedToVenueSmsMarketingOptIn: boolean
  agreedToGroupBookingsPolicy: boolean
  agreedToAboveAgeConsentOn: boolean
  agreedToReservationSmsOptIn: boolean
  agreedToGdprDietaryOptIn: boolean
  phoneCountryCode: CountryCode
  phoneDialCode: string
  notes: string
  timeRange: {
    startTime: string
    endTime: string
  } | null
  startAndEndTime: string | null
  trackingSlug?: string
  clientId?: string
  promoCode?: PromoCode
  billingAddress?: BillingAddress
  salutation: string | null
  redemptionData?: RedemptionData
  champagnePreferenceTags: string[]
  clientDietaryPreferenceTags: string[]
  liquorPreferenceTags: string[]
  partyDietaryPreferenceTags: string[]
  specialOccasionTags: string[]
  customQuestionAnswer: string
} => {
  const {
    minGuests,
    maxGuests,
    isDefaultCustomCheckoutPolicyOn,
    isVenueGroupMarketingPolicyDefaultOn,
    tailoredCommunicationOn,
    isTailoredCommunicationPolicyDefaultOn,
    isVenueSpecificMarketingPolicyDefaultOn,
    isVenueSmsMarketingPolicyDefaultOn,
    reservationSmsOptInOn,
    displayReservationSmsOptIn,
    isGroupBookingsPolicyDefaultOn,
  } = widgetSettings
  const { venueToday, venueLanguages, countryCode = 'us', reservationSmsEnabled } = venueInfo
  const currentDateAtVenue = DateOnly.fromSafe(venueToday)?.toJsDate() || new Date()
  let preferredLanguageLocalStorage = window.localStorage.getItem(ReservationWidgetConst.PreferredLanguageCookie)
  if (preferredLanguageLocalStorage) {
    preferredLanguageLocalStorage = JSON.parse(preferredLanguageLocalStorage)
  }
  const preferredLanguage = (preferredLanguageLocalStorage as LanguageCode) || venueLanguages.find(lang => lang.isDefault)?.value || 'en'
  const firstName = clientInfo.firstName || ''
  const lastName = clientInfo.lastName || ''
  const phoneCountryCode = clientInfo.phoneNumberLocale || countryCode
  const phoneDialCode = allCountries[iso2Lookup[phoneCountryCode]]?.dialCode ?? ''
  let phoneNumber = ''
  if (clientInfo.phoneNumberLocalized) {
    phoneNumber = `+${phoneDialCode}${clientInfo.phoneNumberLocalized}`
  }
  const emailAddress = clientInfo.email || ''
  const postalCode = clientInfo.postalCode || ''

  return {
    actual: undefined,
    partySize: getInitialPartySize({ minGuests, maxGuests }),
    startDate: getStartDate({ currentDateAtVenue, maxDaysOut: widgetSettings.maxDaysOut }),
    startTime: getInitialTime(),
    searchTab: getInitialSearchTab(),
    selectedTimeSlot: undefined,
    selectedExperience: undefined,
    haloTimeIntervalMinutes: getInitialHalo(),
    venues: searchParams.get('venues'),
    categories: {},
    birthdayDay: '',
    birthdayMonth: '',
    emailAddress,
    firstName,
    gratuityPercentage: null,
    lastName,
    phoneNumber,
    postalCode,
    preferredLanguage,
    agreedToBookingPolicy: false,
    agreedCustomCheckoutPolicy: isDefaultCustomCheckoutPolicyOn || false,
    agreedToGroupBookingsPolicy: isGroupBookingsPolicyDefaultOn || false,
    agreedToVenueGroupMarketingOptIn: isVenueGroupMarketingPolicyDefaultOn || false,
    agreedToTailoredCommunicationOptIn: (tailoredCommunicationOn && isTailoredCommunicationPolicyDefaultOn) || false,
    agreedToVenueSpecificMarketingOptIn: isVenueSpecificMarketingPolicyDefaultOn || false,
    agreedToVenueSmsMarketingOptIn: isVenueSmsMarketingPolicyDefaultOn || false,
    agreedToAboveAgeConsentOn: false,
    agreedToReservationSmsOptIn: (reservationSmsEnabled && displayReservationSmsOptIn && reservationSmsOptInOn) || false,
    agreedToGdprDietaryOptIn: false,
    phoneCountryCode,
    phoneDialCode,
    notes: '',
    timeRange: null,
    startAndEndTime: null,
    trackingSlug: searchParams.get('tracking') || undefined,
    clientId: searchParams.get('client_id') || undefined,
    billingAddress: undefined,
    salutation: null,
    redemptionData: undefined,
    privateEventsPartySize: null,
    privateEventsStartDate: undefined,
    specialOccasionTags: [],
    clientDietaryPreferenceTags: [],
    partyDietaryPreferenceTags: [],
    champagnePreferenceTags: [],
    liquorPreferenceTags: [],
    customQuestionAnswer: '',
  }
}

export const getStartDate = ({ currentDateAtVenue, maxDaysOut }: { currentDateAtVenue: Date; maxDaysOut: number }): string => {
  const startDateParam = searchParams.get('date')

  if (!startDateParam) {
    return currentDateAtVenue.toISOString()
  }

  const dateValue = DateOnly.fromSafe(startDateParam)?.toJsDate()

  if (!dateValue) {
    return currentDateAtVenue.toISOString()
  }
  const maxDateValue = DateOnly.fromDate(currentDateAtVenue).toJsDate()
  maxDateValue.setDate(currentDateAtVenue.getDate() + maxDaysOut - 1)

  if (dateValue < currentDateAtVenue) {
    return currentDateAtVenue.toISOString()
  } else if (dateValue > maxDateValue) {
    return maxDateValue.toISOString()
  }

  return dateValue.toISOString()
}

const DEFAULT_PARTY_SIZE = 2

function getInitialPartySize({ minGuests, maxGuests }: { minGuests: number; maxGuests: number }) {
  let partySize = DEFAULT_PARTY_SIZE

  const partySizeParam = searchParams.get('party_size')
  if (partySizeParam) {
    const partySizeValue = Number.parseInt(partySizeParam)
    if (!Number.isNaN(partySizeValue)) {
      partySize = Math.min(partySizeValue, maxGuests)
    }
  }

  return Math.max(partySize, minGuests)
}

function getInitialTime() {
  const timeParam = searchParams.get('start_time')
  if (timeParam) {
    const utcTime = TimeOnly.fromSafe(timeParam)
    if (utcTime) {
      return utcTime.toHoursMinutesIso()
    }
    const initialTime = legacyParseAdapter(timeParam, Formats.HoursMinutesTwelveHours)
    if (isValid(initialTime)) {
      return Timezone.omit(TimeOnly.fromJsDate(initialTime).toHoursMinutesIso())
    }
  }
  return ReservationWidgetConst.AllTimesOption
}

function getInitialHalo() {
  const haloParam = searchParams.get('halo')
  let haloTimeIntervalMinutes = parseInt(haloParam || '')
  if (Number.isNaN(haloTimeIntervalMinutes)) {
    haloTimeIntervalMinutes = ReservationWidgetConst.HaloMinutesOptions[ReservationWidgetConst.HaloMinutesOptions.length - 1] as number
  }

  return haloTimeIntervalMinutes
}

function getInitialSearchTab() {
  const tabParam = searchParams.get('searchTab')
  return tabParam === 'group_bookings' ? 'group_bookings' : 'reservations'
}
