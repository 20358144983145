import type { Upgrade as UpgradeModel } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { additionalUpgradesCardMessages } from '../additionalUpgradesCardMessages'
import { Upgrade } from './Upgrade'

export interface AdditionalUpgradesProps {
  upgrades: UpgradeModel[]
  onClick: () => void
  currencyCode: string
}

export function AdditionalUpgrades({ upgrades, onClick, currencyCode }: AdditionalUpgradesProps) {
  const { formatMessage } = useLocales()

  return (
    <VStack spacing="lm">
      <Text textStyle="body1Bold" data-test="upgrades-title">
        {formatMessage(additionalUpgradesCardMessages.availableUpgradesTitle)}
      </Text>
      <VStack spacing="s">
        {upgrades.map(upgrade => (
          <Upgrade
            key={upgrade.id}
            name={upgrade.name}
            price={upgrade.isPriceHidden ? undefined : upgrade.price}
            currencyCode={currencyCode}
            data-test={`upgrade-card-${upgrade.id}`}
            photo={upgrade.previewImageUrlKey}
            onClick={onClick}
          />
        ))}
      </VStack>
    </VStack>
  )
}
