import { useMemo, useState } from 'react'
import mergeRefs from 'react-merge-refs'
import type { ReservationWidget as ReservationWidgetType } from '@sevenrooms/core/domain'
import { ReservationWidget } from '@sevenrooms/core/domain/constants'
import { type FormatMessage, useLocales } from '@sevenrooms/core/locales'
import { Formats, legacyParseAdapter, TimeOnly } from '@sevenrooms/core/timepiece'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { useOverflowed, useThrottledResizeObserver } from '@sevenrooms/core/ui-kit/hooks'
import { Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle, HStack, Box, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { useVenue, useWidgetSettings } from '../../hooks'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import { RadioTileGroup } from './RadioTileGroup'
import type { RadioTileProps } from './RadioTileGroup/RadioTile'

export interface FilterTimeProps {
  haloTimeIntervalMinutes: number
  onChange: ({ startTime, haloTimeIntervalMinutes }: { startTime: string; haloTimeIntervalMinutes: number }) => void
  onClose: () => void
  startTime: string
  venueLocale: string
}

export function FilterTime({ onClose, startTime, haloTimeIntervalMinutes, onChange, venueLocale }: FilterTimeProps) {
  const { formatMessage } = useLocales()
  const { startOfDayTime } = useVenue()
  const { availabilityTimeRange, specificTimeSlots, firstSeating, lastSeating } = useWidgetSettings()
  const haloOptions = useMemo(() => getHaloSelectOptions({ selectedHalo: haloTimeIntervalMinutes }), [haloTimeIntervalMinutes])
  const timeOptions = useMemo(
    () =>
      getTimeSelectOptions({
        venueLocale,
        startOfDayTime,
        availabilityTimeRange,
        specificTimeSlots,
        firstSeating: firstSeating ?? undefined,
        lastSeating: lastSeating ?? undefined,
        formatMessage,
      }),
    [venueLocale, startOfDayTime, availabilityTimeRange, specificTimeSlots, firstSeating, lastSeating, formatMessage]
  )
  const [time, setTime] = useState<string>(startTime)
  const [halo, setHalo] = useState<number>(haloTimeIntervalMinutes)
  const { ref: resizeRef, height: wrapperHeight } = useThrottledResizeObserver(50, 'border-box')
  const [, overflowRef] = useOverflowed(wrapperHeight)
  const timeLabel = formatMessage(reservationWidgetMessages.resWidgetTimePickerLabel)
  const haloLabel = formatMessage(reservationWidgetMessages.resWidgetHaloPickerLabel)

  const handleOnSubmit = () => {
    onChange({ startTime: time, haloTimeIntervalMinutes: halo })
  }

  return (
    <Modal maxHeight="90%" data-test="sr-filter-time" width="100%" ariaLabel={timeLabel}>
      <ModalHeader position="relative" onClose={onClose}>
        <HStack spacing="s">
          <ModalTitle title={timeLabel} />
        </HStack>
      </ModalHeader>
      <DividerLine margin="none" />
      <ModalBody ref={mergeRefs([overflowRef, resizeRef])}>
        <RadioTileGroup
          hideIcon
          hideLegend
          showBorder={false}
          onChange={setTime}
          value={time}
          name={timeLabel}
          options={timeOptions}
          label={timeLabel}
          data-test="sr-time-tile-group"
          data-test-id={`sr-filter-tim-option-${time}`}
        />
      </ModalBody>
      <DividerLine margin="none" />
      <Box p="sm lm">
        <RadioTileGroup
          hideLegend
          onChange={setHalo}
          value={halo}
          name={haloLabel}
          options={haloOptions}
          label={haloLabel}
          data-test="sr-halo-tile-group"
          data-test-id={`sr-filter-halo-option-${time}`}
          hideIcon
          showBorder
          direction="row"
          spacing="s"
        />
      </Box>
      <DividerLine margin="none" />
      <ModalFooter p="m lm">
        <Button onClick={handleOnSubmit} data-test="sr-filter-time-submit" data-test-id="sr-filter-time-submit" type="button" fullWidth>
          {getSubmitButtonText({ time, halo, formatMessage })}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

const searchIntervalMinutes = 30
const minutesInADay = 24 * 60
function convertToOption(timeOnly: TimeOnly, venueLocale: string) {
  return {
    id: timeOnly.toHoursMinutesIso(),
    label: timeOnly.formatSTime(venueLocale),
    value: timeOnly.toHoursMinutesIso(),
  }
}
export const getTimeSelectOptions = ({
  venueLocale,
  startOfDayTime,
  availabilityTimeRange,
  specificTimeSlots,
  firstSeating,
  lastSeating,
  formatMessage,
}: {
  venueLocale: string
  startOfDayTime: string
  availabilityTimeRange: ReservationWidgetType.AvailabilityTimeRange
  specificTimeSlots: string[]
  firstSeating?: string
  lastSeating?: string
  formatMessage: FormatMessage
}): Omit<RadioTileProps<string>, 'onChange' | 'name'>[] => {
  const allTimesOption = {
    id: ReservationWidget.AllTimesOption,
    label: formatMessage(reservationWidgetMessages.resWidgetAllTimesLabel),
    value: ReservationWidget.AllTimesOption,
    sticky: true,
  }
  let timeOptions = []
  if (availabilityTimeRange === 'specificTimeSlots') {
    timeOptions = specificTimeSlots.map(specificTimeSlot => {
      const timeOnly = TimeOnly.fromJsDate(legacyParseAdapter(specificTimeSlot, Formats.HoursMinutesTwelveHoursWithoutDelimiter))
      return convertToOption(timeOnly, venueLocale)
    })
  } else if (availabilityTimeRange === 'customTimeRange' && firstSeating && lastSeating) {
    const firstSeatingDate = legacyParseAdapter(firstSeating, Formats.HoursMinutesTwelveHoursWithoutDelimiter)
    // for 30 minutes interval support
    if (firstSeatingDate.getMinutes() % 10 === 5) {
      firstSeatingDate.setMinutes(firstSeatingDate.getMinutes() - 15)
    }
    const lastSeatingDate = legacyParseAdapter(lastSeating, Formats.HoursMinutesTwelveHoursWithoutDelimiter)
    // for 30 minutes interval support
    if (lastSeatingDate.getMinutes() % 10 === 5) {
      lastSeatingDate.setMinutes(lastSeatingDate.getMinutes() + 15)
    }
    while (firstSeatingDate.getTime() <= lastSeatingDate.getTime()) {
      const timeOnly = TimeOnly.fromJsDate(firstSeatingDate)
      timeOptions.push(convertToOption(timeOnly, venueLocale))
      firstSeatingDate.setMinutes(firstSeatingDate.getMinutes() + searchIntervalMinutes)
    }
  } else {
    const time = TimeOnly.from(startOfDayTime).toJsDate()
    const optionsCount = Math.round(minutesInADay / searchIntervalMinutes)
    timeOptions = [...Array(optionsCount).keys()].map(() => {
      const timeOnly = TimeOnly.fromJsDate(time)
      time.setMinutes(time.getMinutes() + searchIntervalMinutes)
      return convertToOption(timeOnly, venueLocale)
    })
  }
  timeOptions.unshift(allTimesOption)
  return timeOptions
}

export const getHaloSelectOptions = ({ selectedHalo }: { selectedHalo: number }): Omit<RadioTileProps<number>, 'onChange' | 'name'>[] => {
  const options = [...ReservationWidget.HaloMinutesOptions]
  if (selectedHalo && options.indexOf(selectedHalo) === -1) {
    options[options.length - 1] = selectedHalo
  }
  return options.map(halo => {
    const timeOnly = TimeOnly.from({ minutes: halo })
    return {
      id: String(halo),
      label: `± ${timeOnly.formatNTimeDuration()}`,
      value: halo,
    }
  })
}

export function getSubmitButtonText({ time, halo, formatMessage }: { time: string; halo: number; formatMessage: FormatMessage }) {
  if (time === ReservationWidget.AllTimesOption) {
    return formatMessage(reservationWidgetMessages.resWidgetSearchAllTimes)
  }

  const haloStartTime = TimeOnly.fromSafe(time)?.subMinutes(halo).formatSTime() || ''
  const haloEndTime = TimeOnly.fromSafe(time)?.addMinutes(halo).formatSTime() || ''

  return formatMessage(reservationWidgetMessages.resWidgetSearchHaloTimeRange, {
    halo_start_time: haloStartTime,
    halo_end_time: haloEndTime,
  })
}
