import { Icon, type IconType } from '@sevenrooms/core/ui-kit/icons'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

interface AlertDetailsRowProps {
  icon: IconType
  text: string
  'data-test'?: string
}

export function PriorityAlertDetailsRow({ icon, text, 'data-test': dataTest }: AlertDetailsRowProps) {
  return (
    <HStack spacing="s" alignItems="center" data-test={dataTest}>
      <Icon name={icon} size="lg" />
      <Text textStyle="h3">{text}</Text>
    </HStack>
  )
}
