import { useMemo } from 'react'
import styled from 'styled-components'
import type { Upgrade } from '@sevenrooms/core/domain'
import { useLocales, FormatService } from '@sevenrooms/core/locales'
import { type FlexProps, VStack, HStack, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { upgradeDetailsMessages } from './upgradeDetailsMessages'

export interface UpgradeDetailsProps extends FlexProps {
  upgrades: Upgrade[]
  currencyCode: string
}

export function UpgradeDetails({ upgrades, currencyCode, ...props }: UpgradeDetailsProps) {
  const { formatMessage } = useLocales()
  const priceSectionHidden = useMemo(() => upgrades?.every(upgrade => upgrade.isPriceHidden), [upgrades])
  if (!upgrades.length) {
    return null
  }
  return (
    <VStack width="100%" spacing="s" data-test="upgrade-details-card" {...props}>
      <HStack justifyContent="left">
        <Text textStyle="body1Bold" data-test="upgrade-details-title">
          {formatMessage(upgradeDetailsMessages.title)}
        </Text>
      </HStack>
      <DividerLine margin="none" />
      <StyledTable>
        {upgrades.map(upgrade => (
          <tr key={upgrade.id} data-test={`upgrade-details-${upgrade.name}`}>
            <StyledQuantityTableCell>
              <Text color="secondaryFont" data-test="upgrade-details-quantity">
                {upgrade.quantity ?? 1}x
              </Text>
            </StyledQuantityTableCell>
            <StyledTableCell>
              <Text color="secondaryFont" textOverflow="twoLinesEllipsis" data-test="upgrade-details-name">
                {upgrade.name}
              </Text>
            </StyledTableCell>
            {!priceSectionHidden && !upgrade.isPriceHidden && (
              <StyledCostTableCell>
                <Text color="secondaryFont" data-test="upgrade-details-price">
                  {FormatService.formatCurrency(upgrade.price + (upgrade.fees ?? 0), currencyCode)}
                  {upgrade.fees
                    ? ` ${formatMessage(reservationWidgetMessages.resWidgetIncludesFees, {
                        fees: FormatService.formatCurrency(upgrade.fees, currencyCode),
                      })}`
                    : ''}
                </Text>
              </StyledCostTableCell>
            )}
          </tr>
        ))}
      </StyledTable>
    </VStack>
  )
}

const StyledTable = styled.table`
  table-layout: fixed;
  border-collapse: collapse;
`
const StyledTableCell = styled.td`
  padding: ${props => props.theme.spacing.xs} 0;
  vertical-align: top;
`
const StyledQuantityTableCell = styled(StyledTableCell)`
  width: 25px;
`
const StyledCostTableCell = styled(StyledTableCell)`
  text-align: right;
`
