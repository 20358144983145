import { useEffect, useState } from 'react'
import type { ReservationWidget as ReservationWidgetModel } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { type OtherVenueInfo, WidgetTitle, OtherVenueAvailability } from '../../components'
import { SpecialAttention } from '../../components/PrimaryAvailability/SpecialAttention'
import { useReservationsRoute, useVenue, useWidgetLanguage, useWidgetSettings, useReservationNavigation } from '../../hooks'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import type { AvailabilityTimeWithUpSellCost } from '../../hooks/useAvailability'

interface GroupWidgetProps {
  venuesInfo: ReservationWidgetModel.VenueInfo
  partySize: number
  startDate: Date
  startTime: string
  haloTimeIntervalMinutes: number
  onTimeSlotClick: (slot: AvailabilityTimeWithUpSellCost, venue: OtherVenueInfo) => void
}
export function GroupWidget({ venuesInfo, partySize, startDate, startTime, haloTimeIntervalMinutes, onTimeSlotClick }: GroupWidgetProps) {
  const { formatMessage } = useLocales()
  const venue = useVenue()
  const widgetSettings = useWidgetSettings()
  const { selectedLanguage } = useWidgetLanguage()
  const { navigateToOtherVenue } = useReservationNavigation()
  const { primaryVenueId, clientId } = useReservationsRoute()

  const [venuesWithNoAvailabilities, setVenuesWithNoAvailabilities] = useState<string[]>([])

  const otherVenues = venuesInfo.venues.filter(v => v.urlKey !== venue.urlKey)

  useEffect(() => {
    // Need to reset when party size, time, and date changes
    setVenuesWithNoAvailabilities([])
  }, [partySize, startDate, startTime])

  return (
    <>
      <VStack width="100%" pt="lm" pb="lm" spacing="lm">
        <WidgetTitle />
        {widgetSettings.showSpecialAttentionMessage && <SpecialAttention data-test="venue-special-attention" />}
      </VStack>
      {venuesWithNoAvailabilities.length === otherVenues.length ? (
        <Box p="lm">
          <Text textStyle="body1">
            {formatMessage(reservationWidgetMessages.resWidgetNoAvailabilityCriteria, {
              optionalLink: () => null,
            })}
          </Text>
        </Box>
      ) : (
        <VStack spacing="lm">
          {otherVenues.map(v => (
            <OtherVenueAvailability
              key={v.urlKey}
              venue={v}
              partySize={partySize}
              startTime={startTime}
              startDate={DateOnly.fromDate(startDate).toIso()}
              defaultSearchTime={widgetSettings.defaultSearchTime}
              haloTimeIntervalMinutes={haloTimeIntervalMinutes}
              currencyCode={venue.currencyCode}
              selectedLangCode={selectedLanguage}
              startOfDayTime={venue.startOfDayTime}
              collapsed={false}
              isMobile={false}
              onTimeSlotClick={onTimeSlotClick}
              clientId={clientId}
              isGroupWidget
              onTitleClick={(venueUrlKey: string) => {
                if (primaryVenueId) {
                  // If primary_venue_id exists, we keep the existing ID to give credit to the original venue.
                  navigateToOtherVenue(venueUrlKey)
                } else {
                  navigateToOtherVenue(venueUrlKey, {
                    primary_venue_id: venue.id,
                  })
                }
              }}
              onNoAvailabilities={(venueId: string) => {
                if (!venuesWithNoAvailabilities.includes(venueId)) {
                  setVenuesWithNoAvailabilities([...venuesWithNoAvailabilities, venueId])
                }
              }}
            />
          ))}
        </VStack>
      )}
    </>
  )
}
