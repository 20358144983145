import { Icon, type IconType } from '@sevenrooms/core/ui-kit/icons'
import { VStack, HStack, Box } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

export interface ReservationDetailsItemProps {
  icon: IconType
  text?: string
  subText?: string
  oldText?: string
  oldSubText?: string
  'data-test'?: string
}

export function ReservationDetailsItem({ icon, text, subText, oldText, oldSubText, 'data-test': dataTest }: ReservationDetailsItemProps) {
  return (
    <HStack spacing="s" alignItems="baseline">
      <Box>
        <Icon size="lg" name={icon} data-test={dataTest && `${dataTest}-icon`} />
      </Box>
      <VStack>
        {text && (
          <Text data-test={dataTest && `${dataTest}-text`} textStyle="body1">
            {text}
          </Text>
        )}
        {oldText && oldText !== text && (
          <Text data-test={dataTest && `${dataTest}-old-text`} textStyle="body1" color="inactive" textDecoration="line-through">
            {oldText}
          </Text>
        )}
        {subText && (
          <Text data-test={dataTest && `${dataTest}-sub-text`} textStyle="body1">
            {subText}
          </Text>
        )}
        {oldSubText && oldSubText !== subText && (
          <Text data-test={dataTest && `${dataTest}-old-sub-text`} textStyle="body1" color="inactive" textDecoration="line-through">
            {oldSubText}
          </Text>
        )}
      </VStack>
    </HStack>
  )
}
