import { type ChangeEvent, useCallback, useState } from 'react'
import { type Field, useController } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { PromoCode } from '@sevenrooms/core/ui-kit/core'
import { CardSection } from '@sevenrooms/core/ui-kit/layout'
import { useVenue } from '../../hooks'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import { useValidatePromoCodeMutation } from '../../store'
import { appliedPromoCode } from '../../utils'
import type { PromoCodeFormSchema } from './PromoCodeCard.zod'

export interface PromoCodeCardProps {
  field: Field<PromoCodeFormSchema>
  dateTimeIso: string
  disabled?: boolean
}

export function PromoCodeCard({ field, dateTimeIso, disabled = false }: PromoCodeCardProps) {
  const { formatMessage } = useLocales()
  const { urlKey } = useVenue()
  const {
    field: { name, onChange: onChangeForm, ref, value },
  } = useController(field)
  const [validatePromoCodeQuery, { isLoading: isValidating, reset: resetValidatePromoCodeMutation }] = useValidatePromoCodeMutation({
    fixedCacheKey: 'validate-promo-code',
  })
  const [errorMessage, setErrorMessage] = useState('')
  const [draftValue, setDraftValue] = useState(value?.code || '')
  const [isApplied, setIsApplied] = useState(!!value)

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDraftValue(e.target.value)
      setErrorMessage('')
      onChangeForm(undefined)
    },
    [onChangeForm]
  )

  const onApplyPromoCode = useCallback(
    async (draftValue: string) => {
      try {
        const validationResponse = await validatePromoCodeQuery({
          venueKey: urlKey,
          promoCode: draftValue.toUpperCase(),
          dateTimeIso,
          platformApplyType: 'RESERVATION_WIDGET',
        }).unwrap()
        if (validationResponse.status === 'INVALID') {
          field.set(undefined)
          setErrorMessage(
            validationResponse.reason === 'EXHAUSTED'
              ? formatMessage(reservationWidgetMessages.resWidgetPromoErrorExhausted)
              : formatMessage(reservationWidgetMessages.resWidgetPromoErrorInvalid)
          )
        } else if (validationResponse.status === 'VALID') {
          const promo = validationResponse.promoCode
          onChangeForm(promo)
          appliedPromoCode(urlKey, promo.id)
          setIsApplied(true)
        }
      } catch {
        onChangeForm(undefined)
        setErrorMessage(formatMessage(reservationWidgetMessages.resWidgetPromoErrorInvalid))
      }
    },
    [dateTimeIso, field, formatMessage, onChangeForm, urlKey, validatePromoCodeQuery]
  )

  const onRemovePromoCode = useCallback(() => {
    resetValidatePromoCodeMutation()
    field.set(undefined)
    setDraftValue('')
    setIsApplied(false)
  }, [field, resetValidatePromoCodeMutation])

  return (
    <CardSection p="lm">
      <PromoCode
        name={name}
        value={draftValue}
        onChange={onChange}
        invalid={!!errorMessage}
        errorMessage={errorMessage}
        ref={ref}
        isApplied={isApplied}
        disabled={disabled || isValidating}
        onApplyPromoCode={onApplyPromoCode}
        onRemovePromoCode={onRemovePromoCode}
      />
    </CardSection>
  )
}
