import { useMemo } from 'react'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export interface SalutationFormProps {
  required: boolean
  disabled: boolean
  salutationOptions: string[]
  salutationField: Field<string | null>
}

export function SalutationForm({ required, disabled, salutationField, salutationOptions }: SalutationFormProps) {
  const { formatMessage } = useLocales()

  const salutationMessage = formatMessage(reservationWidgetMessages.resWidgetSalutationLabel)
  const asterisk = required ? '*' : ''
  const options: SelectOption<string>[] = useMemo(
    () =>
      (salutationOptions || []).map(value => ({
        id: value,
        label: value,
      })),
    [salutationOptions]
  )
  return (
    <Label primary={`${salutationMessage}${asterisk}`}>
      <FormSelect
        aria-required="true"
        data-test="sr-reservation-salutation"
        disabled={disabled}
        field={salutationField}
        placeholder={salutationMessage}
        options={options}
        searchable={false}
        withEmpty
      />
    </Label>
  )
}
