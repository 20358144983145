import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormBirthday, Label } from '@sevenrooms/core/ui-kit/form'
import { useVenue } from '../../hooks'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export interface BirthdayFormProps {
  disabled: boolean
  dayField: Field<string | null>
  monthField: Field<string | null>
  required: boolean
}

export function BirthdayForm({ disabled, dayField, monthField, required }: BirthdayFormProps) {
  const { formatMessage } = useLocales()
  const { locale } = useVenue()

  const birthdayLabel = formatMessage(reservationWidgetMessages.resWidgetBirthdayLabel)

  return (
    <Label primary={`${birthdayLabel}${required ? '*' : ''}`}>
      <FormBirthday disabled={disabled} dayField={dayField} monthField={monthField} locale={locale} />
    </Label>
  )
}
