import { useCallback, useState } from 'react'
import type { RedemptionData } from '@sevenrooms/core/domain'
import { type Field, useWatchMany, useForm } from '@sevenrooms/core/form'
import { type FormatMessage, FormatService, useLocales } from '@sevenrooms/core/locales'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { Button, ErrorMessage, Form, FormCurrencyInput, FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { BaseSection, Box, Flex, HStack, StackResponsive, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenue, useWidgetSettings } from '../../hooks'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'
import { useCheckGiftCardBalanceMutation } from '../../store'
import { type RedemptionGiftCardForm, useRedemptionGiftCardSchema } from './RedemptionGiftCard.zod'

export interface RedemptionGiftCardProps {
  totalAmount: number
  field: Field<RedemptionData | undefined>
  onChange?: (value: RedemptionData | undefined) => void
  disabled?: boolean
}

export function getErrorMessageByCode({
  errorCode,
  formatMessage,
}: {
  errorCode: string
  formatMessage: FormatMessage
}): [string, boolean] {
  switch (errorCode) {
    case 'INVALID_REDEMPTION_SYSTEM':
    case 'INVALID_CLIENT_KEY':
    case 'REDEMPTION_SYSTEM_NOT_ON':
      return [formatMessage(reservationWidgetMessages.resWidgetRedemptionServiceErrorSetup), true]
    case 'CARD_DEACTIVATED':
    case 'CARD_NOT_ACTIVE':
      return [formatMessage(reservationWidgetMessages.resWidgetRedemptionServiceErrorCardNotActive), false]
    case 'NOT_SUFFICIENT_FUNDS':
      return [formatMessage(reservationWidgetMessages.resWidgetRedemptionServiceErrorNotSufficientFunds), false]
    case 'CARD_NOT_FOUND':
    case 'INCORRECT DATA':
    case 'CANNOT ACCEPT CARD':
      return [formatMessage(reservationWidgetMessages.resWidgetRedemptionServiceErrorCardNotFound), false]
    case 'INVALID_AMT':
      return [formatMessage(reservationWidgetMessages.resWidgetRedemptionServiceErrorInvalidAmount), false]
    case 'ENCRYPTION_ERROR':
      return [formatMessage(reservationWidgetMessages.resWidgetRedemptionServiceErrorSession), false]
    default:
      return [formatMessage(reservationWidgetMessages.resWidgetRedemptionServiceError), false]
  }
}

export function RedemptionGiftCard({ disabled, field: parentField, onChange, totalAmount }: RedemptionGiftCardProps) {
  const { formatMessage } = useLocales()
  const { redemptionCardEncryptionId, redemptionCardEncryptionKey } = useWidgetSettings()
  const { id: venueId, currency, currencySymbol } = useVenue()
  const isMobile = useMaxWidthBreakpoint('s')
  const { fieldHeight } = useTheme()
  const [checkGiftCardBalance, { isLoading }] = useCheckGiftCardBalanceMutation()

  const redemptionGiftCardSchema = useRedemptionGiftCardSchema({ totalAmount })
  const form = useForm(redemptionGiftCardSchema, {
    defaultValues: { amountToRedeem: 0, cardNumber: '', cardPin: '' },
  })
  const {
    field,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = form

  const onRedemptionDataChange = useCallback(
    (value: RedemptionData | undefined) => {
      parentField.set(value)
      onChange?.(value)
    },
    [onChange, parentField]
  )

  const [collapsed, setCollapsed] = useState(true)
  // There's an issue w/the definition of the Field type in @sevenrooms/core && @sevenrooms/form, so we need to cast it
  const [redemptionData, amountToRedeem] = useWatchMany(field as unknown as Field<RedemptionGiftCardForm>, [
    'redemptionData',
    'amountToRedeem',
  ])
  const [isConfigurationError, setIsConfigurationError] = useState(false)
  const isFormDisabled = disabled || isLoading || isConfigurationError
  const isGiftCardApplied = !!redemptionData
  const [isModify, setIsModify] = useState(false)
  const errorMessage = errors.cardNumber?.message || errors.cardPin?.message || errors.amountToRedeem?.message

  const applyGiftCard = useCallback(async () => {
    await handleSubmit(async ({ cardNumber, cardPin }) => {
      try {
        const { redemptionAmount, redemptionCardFields } = await checkGiftCardBalance({
          venueId,
          redemptionSystem: 'VALUTEC',
          redemptionCardEncryptionId,
          redemptionCardEncryptionKey,
          redemptionCardId: cardNumber,
          redemptionCardPin: cardPin,
        }).unwrap()

        const amountToApply = Math.min(totalAmount, redemptionAmount)
        field.prop('redemptionData').set({ redemptionAmount, redemptionCardFields }, { shouldValidate: true })
        field.prop('amountToRedeem').set(amountToApply)
        onRedemptionDataChange({ redemptionAmount: amountToApply, redemptionCardFields })
      } catch (error) {
        const [errorMessage, isConfigurationError] = getErrorMessageByCode({ errorCode: error as string, formatMessage })
        setError('cardNumber', {
          type: 'manual',
          message: errorMessage,
        })
        setError('cardPin', { type: 'manual', message: errorMessage })
        setIsConfigurationError(isConfigurationError)
      }
    })()
  }, [
    handleSubmit,
    checkGiftCardBalance,
    venueId,
    redemptionCardEncryptionId,
    redemptionCardEncryptionKey,
    totalAmount,
    field,
    onRedemptionDataChange,
    formatMessage,
    setError,
  ])

  const onChangeAmountClick = useCallback(() => {
    setIsModify(true)
  }, [])

  const onUpdateAmountClick = useCallback(async () => {
    await handleSubmit(async ({ amountToRedeem, redemptionData }) => {
      onRedemptionDataChange({
        redemptionAmount: amountToRedeem ?? 0,
        redemptionCardFields: redemptionData?.redemptionCardFields as string,
      })
      setIsModify(false)
    })()
  }, [handleSubmit, onRedemptionDataChange])

  const onCancelClick = useCallback(() => {
    setIsModify(false)
    reset()
    onRedemptionDataChange(undefined)
  }, [onRedemptionDataChange, reset])

  return (
    <BaseSection
      title={formatMessage(reservationWidgetMessages.resWidgetGiftCardLabel)}
      collapsed={collapsed}
      onToggleCollapse={(collapsed: boolean) => {
        setCollapsed(collapsed)
      }}
      data-test="redemption-card-section"
      backgroundColor={isMobile ? 'secondaryBackground' : undefined}
      padding="none"
      ml="lm"
      mr="lm"
      mt="lm"
      mb="lm"
      maxWidth="100%"
      type="h3"
    >
      <Form {...form} onSubmit={() => {}} onInvalid={() => {}}>
        <VStack p="none lm lm lm">
          {isGiftCardApplied && !isModify ? (
            <HStack spacing="xs" alignItems="center">
              <Icon name="VMSWeb-checkbox-marked-circle" color="success" />
              <Text textStyle="body1Bold">
                {formatMessage(reservationWidgetMessages.resWidgetGiftCardAmountAppliedLabel, {
                  amount: FormatService.formatCurrency(amountToRedeem ?? 0, currency),
                  remaining: FormatService.formatCurrency(redemptionData.redemptionAmount - (amountToRedeem ?? 0), currency),
                })}
              </Text>
            </HStack>
          ) : (
            <>
              <HStack spacing="sm">
                <Label width="100%" primary={formatMessage(reservationWidgetMessages.resWidgetGiftCardNumberLabel)}>
                  <FormInput
                    data-test="sr-gift-card-number"
                    disabled={isFormDisabled}
                    readOnly={isModify}
                    // There's an issue w/the definition of the Field type in @sevenrooms/core && @sevenrooms/form, so we need to cast it
                    field={field.prop('cardNumber') as unknown as Field<string>}
                    fullWidth
                    placeholder={formatMessage(reservationWidgetMessages.resWidgetGiftCardNumberLabel)}
                    hideErrorMessage
                  />
                </Label>
                <Box width="40%">
                  <Label primary={formatMessage(reservationWidgetMessages.resWidgetGiftCardPINLabel)}>
                    <FormInput
                      data-test="sr-gift-card-pin"
                      disabled={isFormDisabled}
                      readOnly={isModify}
                      // There's an issue w/the definition of the Field type in @sevenrooms/core && @sevenrooms/form, so we need to cast it
                      field={field.prop('cardPin') as unknown as Field<string>}
                      fullWidth
                      placeholder={formatMessage(reservationWidgetMessages.resWidgetGiftCardPINLabel)}
                      hideErrorMessage
                    />
                  </Label>
                </Box>
              </HStack>
              {isModify && (
                <HStack spacing="sm" mt="m">
                  <Label width="100%" primary={formatMessage(reservationWidgetMessages.resWidgetGiftCardRedeemAmountLabel)}>
                    <FormCurrencyInput
                      data-test="sr-gift-card-redeem-amount"
                      currencySymbol={currencySymbol}
                      disabled={isFormDisabled}
                      // There's an issue w/the definition of the Field type in @sevenrooms/core && @sevenrooms/form, so we need to cast it
                      field={field.prop('amountToRedeem') as unknown as Field<number | null>}
                      fullWidth
                      placeholder={formatMessage(reservationWidgetMessages.resWidgetGiftCardRedeemAmountLabel)}
                      hideErrorMessage
                    />
                  </Label>
                  <Box width="40%">
                    <Label primary={formatMessage(reservationWidgetMessages.resWidgetGiftCardBalanceLabel)}>
                      <Flex alignItems="center" height={fieldHeight.m}>
                        <Text textStyle="body1Bold" data-test="sr-gift-card-total-amount">
                          {FormatService.formatCurrency(redemptionData?.redemptionAmount)}
                        </Text>
                      </Flex>
                    </Label>
                  </Box>
                </HStack>
              )}
            </>
          )}
          {!!errorMessage && (
            <Box mt="s">
              <ErrorMessage>{errorMessage}</ErrorMessage>
            </Box>
          )}
          <StackResponsive spacing="m" mt="m" data-test="card-actions-panel">
            {isGiftCardApplied || isModify ? (
              <>
                {isModify ? (
                  <Button
                    variant="secondary"
                    data-test="sr-gift-card-update-amount-btn"
                    fullWidth
                    onClick={onUpdateAmountClick}
                    disabled={isFormDisabled}
                  >
                    {formatMessage(reservationWidgetMessages.resWidgetGiftCardUpdateAmountLabel)}
                  </Button>
                ) : (
                  <Button
                    variant="secondary"
                    data-test="sr-gift-card-change-amount-btn"
                    fullWidth
                    onClick={onChangeAmountClick}
                    disabled={isFormDisabled}
                  >
                    {formatMessage(reservationWidgetMessages.resWidgetGiftCardChangeAmountLabel)}
                  </Button>
                )}
                <Button
                  variant="secondary-warning"
                  data-test="sr-gift-card-cancel-btn"
                  fullWidth
                  onClick={onCancelClick}
                  disabled={disabled || isLoading}
                >
                  {formatMessage(reservationWidgetMessages.resWidgetGiftCardCancelLabel)}
                </Button>
              </>
            ) : (
              <Button variant="primary" data-test="sr-gift-card-apply-btn" fullWidth onClick={applyGiftCard} disabled={isFormDisabled}>
                {formatMessage(reservationWidgetMessages.resWidgetGiftCardApplyLabel)}
              </Button>
            )}
          </StackResponsive>
        </VStack>
      </Form>
    </BaseSection>
  )
}
