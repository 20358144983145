import { useTheme } from '@sevenrooms/core/ui-kit'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { VStack, DividerLine, HStack, Box, Flex } from '@sevenrooms/core/ui-kit/layout'
import type { Theme } from '@sevenrooms/core/ui-kit/theme'
import { SkeletonBox } from '../../../components/SkeletonBox'
import { DESKTOP_IMAGE_WIDTH, DESKTOP_IMAGE_HEIGHT } from './UpgradesGroup/UpgradesCard'

export function UpgradesSectionLoader() {
  const { lineHeights } = useTheme() as Theme
  const isMobile = useMaxWidthBreakpoint('s')
  const spacing = isMobile ? 'm' : 'lm'

  return (
    <VStack data-test="upgrades-section-loader" width="100%" pt="lm">
      <Flex mb="lm" alignSelf="center">
        <SkeletonBox width="200px" height={lineHeights.l} borderWidth="none" borderRadius="s" mb="xxs" />
      </Flex>
      <Box borderColor="borders" borderRadius="s" mb={spacing}>
        <SkeletonBox height="61px" borderWidth="none" borderBottomRightRadius="none" borderBottomLeftRadius="none" mb={spacing} />
        <UpgradesBodyLoader />
        <DividerLine ml={spacing} mr={spacing} mt="none" mb="none" />
        <UpgradesBodyLoader />
        <DividerLine ml={spacing} mr={spacing} mt="none" mb="none" />
        <UpgradesBodyLoader />
      </Box>
      <Box borderColor="borders" borderRadius="s">
        <SkeletonBox height="61px" borderWidth="none" borderBottomRightRadius="none" borderBottomLeftRadius="none" mb={spacing} />
        <UpgradesBodyLoader />
        <DividerLine ml={spacing} mr={spacing} mt="none" mb="none" />
        <UpgradesBodyLoader />
        <DividerLine ml={spacing} mr={spacing} mt="none" mb="none" />
        <UpgradesBodyLoader />
      </Box>
    </VStack>
  )
}

export function UpgradesBodyLoader() {
  const { lineHeights, fieldHeight } = useTheme() as Theme
  const isMobile = useMaxWidthBreakpoint('s')

  return (
    <Box mb={isMobile ? 'sm' : 'lm'} mt={isMobile ? 'sm' : 'lm'}>
      <HStack ml={isMobile ? 'm' : 'lm'} mr={isMobile ? 'm' : 'lm'} mb="sm" alignItems="center" justifyContent="space-between">
        <SkeletonBox maxWidth="160px" height={lineHeights.l} borderWidth="none" borderRadius="s" mr="m" />
        <SkeletonBox width="130px" height={fieldHeight.s} borderWidth="none" borderRadius="s" />
      </HStack>
      <HStack ml={isMobile ? 'm' : 'lm'} mr={isMobile ? 'm' : 'lm'}>
        <VStack flex="1 1 auto" spacing={isMobile ? 'sm' : 'm'}>
          <SkeletonBox maxWidth="300px" height={lineHeights.l} borderWidth="none" borderRadius="s" />
          <SkeletonBox maxWidth="100px" height={lineHeights.l} borderWidth="none" borderRadius="s" />
          <SkeletonBox maxWidth="140px" height={lineHeights.l} borderWidth="none" borderRadius="s" />
        </VStack>
        {!isMobile && (
          <VStack flex="1 1 none">
            <SkeletonBox width={DESKTOP_IMAGE_WIDTH} height={DESKTOP_IMAGE_HEIGHT} borderWidth="none" borderRadius="s" />
          </VStack>
        )}
      </HStack>
    </Box>
  )
}
