import { defineMessages } from '@sevenrooms/core/locales'

export const otherDaysAvailabilityMessages = defineMessages({
  scrollLeftLabel: {
    id: 'otherdays.availability.leftlabel',
    defaultMessage: 'scroll {date} left',
  },
  scrollRightLabel: {
    id: 'otherdays.availability.rightlabel',
    defaultMessage: 'scroll {date} right',
  },
})
