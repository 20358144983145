import { type PropsWithChildren, useMemo } from 'react'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { StackResponsive, VStack, Box } from '@sevenrooms/core/ui-kit/layout'

interface ManageReservationContainerProps {
  'data-test'?: string
  portalId?: string
}

export function ManageReservationContainer({
  'data-test': dataTest,
  portalId,
  children,
}: PropsWithChildren<ManageReservationContainerProps>) {
  const moreThanSmallDesktop = !useMaxWidthBreakpoint('l')
  const moreThanTablet = !useMaxWidthBreakpoint('m')
  const lessThanRegularMobile = useMaxWidthBreakpoint('s')
  const spacing = useMemo(() => {
    if (moreThanSmallDesktop) {
      return 'xxl'
    }
    if (lessThanRegularMobile || moreThanTablet) {
      return 'l'
    }
    return 'lm'
  }, [moreThanTablet, moreThanSmallDesktop, lessThanRegularMobile])

  return (
    <VStack maxWidth="976px" width="100%" alignItems="center" data-test={dataTest} pt="l" pb="l">
      {portalId && <Box id={portalId} width="100%" />}
      <StackResponsive
        width="100%"
        justifyContent="center"
        alignItems={lessThanRegularMobile ? 'center' : 'start'}
        spacing={spacing}
        data-test={dataTest && `${dataTest}-stack`}
      >
        {children}
      </StackResponsive>
    </VStack>
  )
}
