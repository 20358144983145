import { useCallback } from 'react'
import type { UpdateGuestPreferenceType } from '@sevenrooms/core/domain'
import { useManageReservationRoute } from '../../hooks'
import { useUpdateGuestPreferencesMutation } from '../../store'
import { GuestPreferences, type GuestPreferencesProps, type GuestPreferencesValue } from './GuestPreferences'
import type { AccordionPreferenceOption } from './AccordionInput'

export interface ManageGuestPreferencesProps extends Omit<GuestPreferencesProps, 'onTagSave' | 'onCustomQuestionSave'> {
  onError?: () => void
  requireGdprOptin?: boolean
  agreedToGdprDietaryOptin?: boolean
}

export function ManageGuestPreferences({
  onError,
  agreedToGdprDietaryOptin,
  requireGdprOptin,
  clientDietaryPreferenceTags,
  ...props
}: ManageGuestPreferencesProps) {
  const { token, lang } = useManageReservationRoute()
  const [updateGuestPreferences] = useUpdateGuestPreferencesMutation()
  const onSave = useCallback(
    async (newGuestPreferences: GuestPreferencesValue) => {
      try {
        return await updateGuestPreferences({
          token: token ?? '',
          lang,
          data: newGuestPreferences,
        }).unwrap()
      } catch (e) {
        onError?.()
        return false
      }
    },
    [token, lang, updateGuestPreferences, onError]
  )
  const onCustomQuestionSave = useCallback((value?: string) => onSave({ customQuestionAnswer: value }), [onSave])

  const onTagSave = useCallback(
    async (options: AccordionPreferenceOption[], type: UpdateGuestPreferenceType) => {
      const newOptions = mapSelectedOptionsToTags(options)
      try {
        return onSave({ [type]: newOptions, ...(type === 'clientDietaryPreferenceTags' && { agreedToGdprDietaryOptin }) })
      } catch (e) {
        onError?.()
        return false
      }
    },
    [onSave, agreedToGdprDietaryOptin, onError]
  )

  return (
    <GuestPreferences
      {...props}
      clientDietaryPreferenceTags={!requireGdprOptin || agreedToGdprDietaryOptin ? clientDietaryPreferenceTags : undefined}
      onCustomQuestionSave={onCustomQuestionSave}
      onTagSave={onTagSave}
    />
  )
}

const mapSelectedOptionsToTags = (options: AccordionPreferenceOption[]) => options.filter(value => value.selected).map(value => value.id)
