import styled from 'styled-components'
import ImagePlaceholder from '@sevenrooms/core/ui-kit/images/ImagePlaceholder.svg'
import { Stack, Image } from '@sevenrooms/core/ui-kit/layout'

export function UpgradeImagePlaceholder() {
  return (
    <PlaceholderContainer alignItems="center" justifyContent="center" width="100%" height="100%">
      <Image src={ImagePlaceholder} alt="image placeholder" data-test="upgrade-card-placeholder" />
    </PlaceholderContainer>
  )
}

const PlaceholderContainer = styled(Stack)`
  background-color: ${props => props.theme.colors.borders};
`
