export type ReservationWidgetStepName = 'search' | 'upgrades' | 'checkout'
export type ReservationWidgetFlow = 'create' | 'modify' | 'prearrival'

export interface ReservationWidgetStep {
  name: ReservationWidgetStepName
  disabled: boolean
}

export const createReservationWidgetSteps: ReservationWidgetStep[] = [
  {
    name: 'search',
    disabled: false,
  },
  {
    name: 'upgrades',
    disabled: true,
  },
  {
    name: 'checkout',
    disabled: false,
  },
]

export const modifyReservationWidgetSteps: ReservationWidgetStep[] = [
  {
    name: 'search',
    disabled: false,
  },
  {
    name: 'upgrades',
    disabled: true,
  },
  {
    name: 'checkout',
    disabled: false,
  },
]

export const prearrivalReservationWidgetSteps: ReservationWidgetStep[] = [
  {
    name: 'upgrades',
    disabled: false,
  },
  {
    name: 'checkout',
    disabled: false,
  },
]

export function getInitialState(flow: ReservationWidgetFlow = 'create') {
  switch (flow) {
    case 'modify':
      return { flow, steps: modifyReservationWidgetSteps }
    case 'prearrival':
      return { flow, steps: prearrivalReservationWidgetSteps }
    case 'create':
    default:
      return { flow, steps: createReservationWidgetSteps }
  }
}
