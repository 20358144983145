import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export interface LastNameFormProps {
  disabled: boolean
  lastNameField: Field<string>
}

export function LastNameForm({ disabled, lastNameField }: LastNameFormProps) {
  const { formatMessage } = useLocales()

  const lastNameMessage = formatMessage(reservationWidgetMessages.resWidgetLastNameLabel)

  return (
    <Label primary={`${lastNameMessage}*`}>
      <FormInput
        aria-required="true"
        autoComplete="family-name"
        data-test="sr-reservation-last-name"
        disabled={disabled}
        field={lastNameField}
        fullWidth
        placeholder={lastNameMessage}
      />
    </Label>
  )
}
