import React from 'react'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { VStack, Flex } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'
import { getCategoryMaxRemaining } from './getCategoryMaxRemaining'
import { UpgradesGroup } from './UpgradesGroup'
import { UpgradesSectionLoader } from './UpgradesSectionLoader'
import type { Categories, SchemaCategories } from '../../../utils'
import type { UpgradesForm } from '../Upgrades.zod'

export interface UpgradesSectionProps {
  currencyCode: string
  field: Field<UpgradesForm['categories']>
  upgradesData: Categories
  isLoading?: boolean
  partySize: number | undefined
  categories: SchemaCategories
}

export function UpgradesSection({ currencyCode, upgradesData, field, isLoading = false, partySize, categories }: UpgradesSectionProps) {
  const { formatMessage } = useLocales()

  if (isLoading) {
    return <UpgradesSectionLoader />
  }

  return (
    <VStack spacing="lm" pt="lm">
      <Flex alignSelf="center">
        <Text textStyle="h2">{formatMessage(reservationWidgetMessages.resWidgetUpgradePageHeader)}</Text>
      </Flex>
      {upgradesData.map(({ category, upgrades }) => (
        <UpgradesGroup
          key={category.id}
          category={category}
          upgrades={upgrades}
          currencyCode={currencyCode}
          field={field.prop(category.id).prop('upgrades')}
          partySize={partySize}
          categoryMaxRemaining={getCategoryMaxRemaining(categories, category, partySize)}
        />
      ))}
    </VStack>
  )
}
