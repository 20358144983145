import { useEffect, useRef } from 'react'
import type { GuestFacingUpgradeInventory, GuestFacingUpgradeCategory } from '@sevenrooms/core/domain'
import { useFormContext, type Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Box, DividerLine, CardSection } from '@sevenrooms/core/ui-kit/layout'
import { Status } from '@sevenrooms/core/ui-kit/typography'
import { UpgradesCard } from './UpgradesCard'
import { upgradesGroupMessages } from './upgradesGroupMessages'
import { UpgradesHeader } from './UpgradesHeader'
import type { UpgradesForm } from '../../Upgrades.zod'

export interface UpgradesGroupProps {
  currencyCode: string
  field: Field<Record<string, number>>
  category: GuestFacingUpgradeCategory & { maxRemaining?: number }
  upgrades: GuestFacingUpgradeInventory[]
  partySize: number | undefined
  categoryMaxRemaining?: number
}

export function UpgradesValidationMessage({ category }: { category: GuestFacingUpgradeCategory }) {
  const scrollToRef = useRef<null | HTMLDivElement>(null)
  const { formState } = useFormContext<UpgradesForm>()

  const { formatMessage } = useLocales()

  useEffect(() => {
    const isFirstValidationError = Boolean(Object.keys(formState?.errors?.categories || {})?.[0] === category.id)
    if (scrollToRef.current && isFirstValidationError) {
      // This is required to avoid the element from scrolling behind the layout header.
      const offsetHeader = 144
      window.scrollTo({
        top: scrollToRef.current.offsetTop - offsetHeader,
        left: 0,
        behavior: 'smooth',
      })
    }
    // Intentional, only scroll into view when pressing submit
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.isSubmitting])

  if (formState.errors.categories?.[category.id]) {
    return (
      <Box ref={scrollToRef} p="s" mb="none">
        <Status kind="error">{formatMessage(upgradesGroupMessages.resWidgetUpgradeSelectionRequired)}</Status>
      </Box>
    )
  }
  return null
}

export function UpgradesGroup({ currencyCode, category, upgrades, field, partySize, categoryMaxRemaining }: UpgradesGroupProps) {
  const { formatMessage } = useLocales()
  const isMobile = useMaxWidthBreakpoint('s')
  const { showCategoryFlag } = category

  return (
    <div>
      <UpgradesValidationMessage category={category} />
      <CardSection>
        {showCategoryFlag && <UpgradesHeader category={category} partySize={partySize} />}
        {upgrades.map(upgrade => (
          <div key={upgrade.id}>
            <UpgradesCard
              {...upgrade}
              currencyCode={currencyCode}
              perEachLabel={formatMessage(upgradesGroupMessages.resWidgetUpgradePageEachLabel)}
              field={field.prop(upgrade.id)}
              categoryMaxRemaining={categoryMaxRemaining}
            />
            <DividerLine hideLast ml={isMobile ? 'm' : 'lm'} mr={isMobile ? 'm' : 'lm'} mt="none" mb="none" />
          </div>
        ))}
      </CardSection>
    </div>
  )
}
