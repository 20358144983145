import { useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import type { Upgrade as UpgradeModel } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { HStack, VStack, CardContainer } from '@sevenrooms/core/ui-kit/layout'
import { AdditionalUpgrades } from './AdditionalUpgrades'
import { additionalUpgradesCardMessages } from './additionalUpgradesCardMessages'

export interface AdditionalUpgradesCardProps {
  upgrades: UpgradeModel[]
  hasMore?: boolean
  onAdd: () => void
  currencyCode: string
}

const MAX_DISPLAYED_UPGRADES_COUNT = 3

export function AdditionalUpgradesCard({ upgrades, hasMore, onAdd, currencyCode }: AdditionalUpgradesCardProps) {
  const { formatMessage } = useLocales()
  const [expanded, setExpanded] = useState(false)
  const displayedUpgrades = useMemo(() => (expanded ? upgrades : upgrades.slice(0, MAX_DISPLAYED_UPGRADES_COUNT)), [expanded, upgrades])
  const hasButtons = upgrades.length > MAX_DISPLAYED_UPGRADES_COUNT
  if (!displayedUpgrades.length) {
    return null
  }

  return (
    <UpgradesContainer spacing="lm" hasMore={hasButtons && !expanded}>
      <AdditionalUpgrades upgrades={displayedUpgrades} onClick={onAdd} currencyCode={currencyCode} />
      {hasButtons && (
        <VStack spacing="m" data-test="actions-panel">
          {!expanded && (
            <Button
              fullWidth
              variant="secondary"
              data-test="show-more-button"
              onClick={() => {
                setExpanded(true)
              }}
            >
              <HStack spacing="s" alignItems="center">
                <div>{formatMessage(additionalUpgradesCardMessages.availableUpgradesShowMoreTitle)}</div>
                <Icon name="VMSWeb-chevron-down" />
              </HStack>
            </Button>
          )}
          {expanded && (
            <>
              <Button
                fullWidth
                variant="secondary"
                data-test="show-less-button"
                onClick={() => {
                  setExpanded(false)
                }}
              >
                <HStack spacing="s" alignItems="center">
                  <div>{formatMessage(additionalUpgradesCardMessages.availableUpgradesShowLessTitle)}</div>
                  <Icon name="VMSWeb-chevron-up" />
                </HStack>
              </Button>
              {hasMore && (
                <Button fullWidth variant="secondary" data-test="show-all-button" onClick={onAdd}>
                  {formatMessage(additionalUpgradesCardMessages.availableUpgradesShowAllTitle)}
                </Button>
              )}
            </>
          )}
        </VStack>
      )}
    </UpgradesContainer>
  )
}

const UpgradesContainer = styled(VStack)<{ hasMore: boolean }>`
  ${props =>
    props.hasMore &&
    css`
      ${CardContainer}:last-child:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, #ffffff 0%, #ffffff 0.01%, rgba(255, 255, 255, 0) 75.44%);
      }
    `}
`
