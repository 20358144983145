import { useMemo } from 'react'
import type { RedemptionData } from '@sevenrooms/core/domain'
import { z } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { reservationWidgetMessages } from '../../reservationWidgetMessages'

export type RedemptionGiftCardForm = z.infer<ReturnType<typeof useRedemptionGiftCardSchema>>

export function useRedemptionGiftCardSchema({ totalAmount }: { totalAmount: number }) {
  const { formatMessage } = useLocales()
  const requiredErrorMessage = formatMessage(reservationWidgetMessages.resWidgetErrorsFieldRequired)

  return useMemo(
    () =>
      z
        .object({
          cardNumber: z.string().min(1, { message: requiredErrorMessage }),
          cardPin: z.string().min(1, { message: requiredErrorMessage }),
          amountToRedeem: z
            .number()
            .nullable()
            .refine(value => value !== null, requiredErrorMessage),
          redemptionData: z.custom<RedemptionData>().optional(),
        })
        .superRefine(({ amountToRedeem, redemptionData }, ctx) => {
          if (redemptionData && amountToRedeem) {
            if (amountToRedeem > redemptionData.redemptionAmount) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(reservationWidgetMessages.resWidgetRedeemAmountExceedBalanceError),
                path: ['amountToRedeem'],
              })
            }
            if (amountToRedeem > totalAmount) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: formatMessage(reservationWidgetMessages.resWidgetRedeemAmountExceedTotalError),
                path: ['amountToRedeem'],
              })
            }
          }
        }),
    [formatMessage, requiredErrorMessage, totalAmount]
  )
}
