import { useMemo } from 'react'
import { useClientLogin } from './useClientLogin'
import { useVenue } from './useVenue'
import type { WidgetContainerProps } from '../components'

export function useGetSevenroomsLogin() {
  const { newResWidgetSrLoginEnabled } = useVenue()
  const {
    setClientLoginDetails,
    logoutOfSevenrooms,
    onClientDelete,
    isClientLoginLoading,
    provider: loginProvider,
    userInfo: { firstName: userFirstName, lastName: userLastName, image: userImage },
  } = useClientLogin()

  const sevenRoomsLogin = useMemo(() => {
    if (!newResWidgetSrLoginEnabled) {
      return undefined
    }
    const onLogin: Required<WidgetContainerProps>['sevenRoomsLogin']['onLogin'] = userInfo => {
      setClientLoginDetails('sevenrooms', userInfo)
    }

    const onUpdate: Required<WidgetContainerProps>['sevenRoomsLogin']['onUpdate'] = userInfo => {
      setClientLoginDetails('sevenrooms', userInfo)
    }

    return {
      onLogin,
      onUpdate,
      onLogout: logoutOfSevenrooms,
      onDelete: onClientDelete,
      isLoading: isClientLoginLoading,
      ...(loginProvider === 'sevenrooms' ? { userFirstName, userLastName, userImage } : {}),
    }
  }, [
    newResWidgetSrLoginEnabled,
    logoutOfSevenrooms,
    onClientDelete,
    isClientLoginLoading,
    loginProvider,
    userFirstName,
    userLastName,
    userImage,
    setClientLoginDetails,
  ])
  return sevenRoomsLogin
}
