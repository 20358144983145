import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { StackResponsive, VStack, NotificationBanner } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { CallVenueMessage } from '../../../components'
import { reservationWidgetMessages } from '../../../reservationWidgetMessages'

interface ActionPanelProps {
  onModifyBtnClick?: () => void
  onCancelBtnClick?: () => void
  isEditable?: boolean
  isCancelable?: boolean
  disabled?: boolean
}

export function ActionPanel({ onModifyBtnClick, onCancelBtnClick, isEditable, isCancelable, disabled }: ActionPanelProps) {
  const { formatMessage } = useLocales()
  return (
    <>
      {(!isEditable || !isCancelable) && (
        <NotificationBanner
          type="warning"
          data-test="action-panel-notification-banner"
          content={
            <VStack spacing="xs">
              <Text fontWeight={500} wordBreak="break-word">
                {formatMessage(
                  isCancelable
                    ? reservationWidgetMessages.resCancelPageUnableModifyResHeader
                    : reservationWidgetMessages.resCancelPageUnableCancelModifyResHeader
                )}
              </Text>
              <CallVenueMessage wordBreak="break-word" />
            </VStack>
          }
        />
      )}
      {(isEditable || isCancelable) && (
        <StackResponsive width="100%" spacing="sm">
          {isEditable && (
            <Button fullWidth variant="primary" data-test="action-panel-modify-button" disabled={disabled} onClick={onModifyBtnClick}>
              {formatMessage(reservationWidgetMessages.resCancelPageModifyButtonLabel)}
            </Button>
          )}
          {isCancelable && (
            <Button
              fullWidth
              variant="secondary-warning"
              data-test="action-panel-cancel-button"
              disabled={disabled}
              onClick={onCancelBtnClick}
            >
              {formatMessage(reservationWidgetMessages.resCancelPageCancelButtonLabel)}
            </Button>
          )}
        </StackResponsive>
      )}
    </>
  )
}
