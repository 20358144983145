import { useCallback, useMemo } from 'react'
import type { UpdateGuestPreferenceType } from '@sevenrooms/core/domain'
import { useSectionController } from '@sevenrooms/core/ui-kit/layout'
import { AccordionSelect, useAccordionOptions, type AccordionPreferenceOption } from './AccordionInput'

export interface GuestPreferencesSelectProps {
  sectionName: string
  title: string
  venueTags: Record<string, string>
  preferences: string[]
  onSave: (option: AccordionPreferenceOption[], type: UpdateGuestPreferenceType) => Promise<boolean>
  selectType: UpdateGuestPreferenceType
  'data-test'?: string
  saveText: string
}

export function GuestPreferencesSelect({
  sectionName,
  onSave,
  preferences,
  selectType,
  venueTags,
  ...inputProps
}: GuestPreferencesSelectProps) {
  const { tryToggleCollapse, collapsed } = useSectionController(sectionName, { initiallyCollapsed: true })
  const preferenceOptions = useMemo(
    () =>
      Object.entries(venueTags).map(([key, value]) => ({
        id: key,
        label: value,
        selected: preferences.includes(key),
      })),
    [venueTags, preferences]
  )
  const onSaveCallback = useCallback((options: AccordionPreferenceOption[]) => onSave(options, selectType), [onSave, selectType])
  const { accordionOptions, isDirty, onAccordionSave, onOptionsChange } = useAccordionOptions(preferenceOptions, onSaveCallback)
  return (
    <AccordionSelect
      accordionOptions={accordionOptions}
      isDirty={isDirty}
      onOptionChange={onOptionsChange}
      onSave={onAccordionSave}
      collapsed={collapsed}
      onToggle={tryToggleCollapse}
      {...inputProps}
    />
  )
}
