import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import {
  getInitialState,
  type ReservationWidgetStepName,
  type ReservationWidgetStep,
  type ReservationWidgetFlow,
} from './reservationWidgetSteps'

export interface ReservationNavigationState {
  steps: ReservationWidgetStep[]
}

export const reservationNavigationSlice = createSlice({
  name: 'reservationNavigation',
  initialState: getInitialState(),
  reducers: {
    mutateStepDisabledStatus: (state, action: PayloadAction<{ step: ReservationWidgetStepName; disabled: boolean }>) => {
      const { disabled, step } = action.payload
      const stepToUpdate = state.steps.find(value => value.name === step)
      if (stepToUpdate) {
        stepToUpdate.disabled = disabled
      }
    },
    updateFlow: (state, action: PayloadAction<{ flow: ReservationWidgetFlow }>) => {
      const { flow } = action.payload
      if (flow !== state.flow) {
        return getInitialState(flow)
      }
      return state
    },
  },
})

export const { mutateStepDisabledStatus, updateFlow } = reservationNavigationSlice.actions
