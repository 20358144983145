import styled from 'styled-components'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { StackResponsive } from '@sevenrooms/core/ui-kit/layout'
import { StyledSearchInputGridContainer } from '../ReservationSearchInputGroup'
import type { PropsWithChildren } from 'react'

export interface SearchContainerProps {}

const SEARCH_CONTAINER_SPACING = 'xl'

function SearchContainer({ children }: PropsWithChildren<SearchContainerProps>) {
  const isMobile = useMaxWidthBreakpoint('m')
  return (
    <StackResponsive
      justifyContent={isMobile ? undefined : 'center'}
      data-test="search-container"
      mb="3xl"
      breakpoint="m"
      width="100%"
      spacing={SEARCH_CONTAINER_SPACING}
    >
      {children}
    </StackResponsive>
  )
}

const SEARCH_MAIN_CONTENT_WIDTH = '796px'
const SEARCH_VENUE_DETAILS_WIDTH = '280px'
const SEARCH_MAIN_CONTENT_MIN_HEIGHT = '320px'
const TABS_HEIGHT = '51px'

namespace SearchContainer {
  export const TabsContent = styled.div`
    ${StyledSearchInputGridContainer} {
      position: sticky;
      z-index: ${props => props.theme.zIndex.default};
      top: ${props => `calc(${props.theme.topNav.height.desktop} + ${TABS_HEIGHT} + ${props.theme.spacing.sm})`};

      @media (max-width: ${props => props.theme.breakpoints.m}px) {
        top: ${props => `calc(${props.theme.topNav.height.mobile}  + ${TABS_HEIGHT} + ${props.theme.spacing.sm})`};
      }
    }
  `
  export const Content = styled.div<{ breadcrumbsHeight?: number }>`
    ${StyledSearchInputGridContainer} {
      position: sticky;
      z-index: ${props => props.theme.zIndex.default};
      top: ${props => `calc(${props.theme.topNav.height.desktop} + ${props.breadcrumbsHeight || 0}px)`};

      @media (max-width: ${props => props.theme.breakpoints.m}px) {
        top: ${props => `calc(${props.theme.topNav.height.mobile} + ${props.breadcrumbsHeight || 0}px)`};
      }
    }
  `

  export const MainContent = styled.div<{ showBreadcrumbs?: boolean; showTabs?: boolean }>`
    width: ${SEARCH_MAIN_CONTENT_WIDTH};
    margin-top: -${props => (props.showBreadcrumbs || props.showTabs ? 0 : props.theme.topNav.spacerHeight)}px;
    min-height: ${SEARCH_MAIN_CONTENT_MIN_HEIGHT};

    @media (max-width: ${props => props.theme.breakpoints.l}px) {
      width: calc(100% - ${SEARCH_VENUE_DETAILS_WIDTH} - ${props => props.theme.spacing[SEARCH_CONTAINER_SPACING]});
      max-width: ${SEARCH_MAIN_CONTENT_WIDTH};
    }

    @media (max-width: ${props => props.theme.breakpoints.m}px) {
      width: 100%;
      max-width: 100%;
    }
  `

  export const VenueDetailsContainer = styled.div<{ hasSpacer?: boolean }>`
    width: ${SEARCH_VENUE_DETAILS_WIDTH};
    min-width: ${SEARCH_VENUE_DETAILS_WIDTH};
    margin-top: ${props => props.theme.spacing.xl};
    align-self: flex-start;
    position: sticky;
    top: ${props =>
      `calc(${props.theme.topNav.height.desktop} + ${props.hasSpacer ? props.theme.topNav.spacerHeight : 0}px + ${
        props.theme.spacing.xl
      })`};

    @media (max-width: ${props => props.theme.breakpoints.m}px) {
      top: auto;
      position: relative;
      margin-top: 0;
      width: 100%;
      max-width: 100%;
    }
  `
}

export { SearchContainer }
