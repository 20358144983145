import type { Field } from '@sevenrooms/core/form'
import { PhoneNumberForm, EmailAddressForm } from '../../../components'
import type { CheckoutForm } from '../Checkout.zod'

export interface SevenroomsGlobalClientDetailsProps {
  field: Field<CheckoutForm>
}

export function SevenroomsGlobalClientDetails({ field }: SevenroomsGlobalClientDetailsProps) {
  return (
    <>
      <EmailAddressForm emailAddressField={field.prop('emailAddress')} disabled hideAsterisk />
      <PhoneNumberForm
        phoneNumberField={field.prop('phoneNumber')}
        phoneDialCodeField={field.prop('phoneDialCode')}
        phoneCountryCodeField={field.prop('phoneCountryCode')}
        disabled
        hideAsterisk
      />
    </>
  )
}
