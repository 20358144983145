import { defineMessages } from '@sevenrooms/core/locales'

export const reservationDetailsMessages = defineMessages({
  reservedFor: {
    id: 'reservation.details.reserved.for',
    defaultMessage: 'Reserved For:',
  },
  tableMinimum: {
    id: 'reservation.details.table.minimum',
    defaultMessage: 'Table Minimum:',
  },
  partySize: {
    id: 'reservation.details.party.size',
    defaultMessage: 'Party of',
  },
  reservationNumber: {
    id: 'reservation.details.reservation.number',
    defaultMessage: 'Reservation #',
  },
})
