import type { ChargePerType } from '@sevenrooms/core/domain'
import { useLocales, type FormatMessage, FormatService } from '@sevenrooms/core/locales'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { useMaxWidthBreakpoint } from '@sevenrooms/core/ui-kit/hooks'
import { Grid, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { ReservationTimeslot, type ReservationTimeSlotVariant } from '../../ReservationTimeslot'
import { SkeletonBox } from '../../SkeletonBox'
import { reservationAvailabilityMessages } from './reservationAvailabilityMessages'
import type { AvailabilityTimeWithUpSellCost } from '../../../hooks/useAvailability'
import type { OtherVenueInfo } from '../../AlternativeAvailability'
import type { PropsWithChildren, ReactNode } from 'react'

export interface ReservationAvailabilityProps {
  timeslots: AvailabilityTimeWithUpSellCost[]
  variant: ReservationTimeSlotVariant
  onClick: (slot: AvailabilityTimeWithUpSellCost, date?: string, selectedVenue?: OtherVenueInfo) => void
  noAvailabilityTitle: ReactNode
  isLoadingData: boolean
  isLoadingLanguage: boolean
  requestOrPriorityAlertAvailability: ReactNode
  currencyCode: string
}

const NUMBER_OF_SKELETON_LOADERS = 16

export function ReservationAvailability({
  noAvailabilityTitle,
  timeslots,
  onClick,
  variant = 'primary',
  isLoadingData,
  isLoadingLanguage,
  currencyCode,
  requestOrPriorityAlertAvailability,
}: ReservationAvailabilityProps) {
  const { formatMessage } = useLocales()
  const shouldDisplayNoAvailabilityTitle = timeslots.length === 0 && !isLoadingData
  if (shouldDisplayNoAvailabilityTitle) {
    return isLoadingLanguage ? (
      <SkeletonBox maxWidth="360px" width="60%" height="16px" borderWidth="none" borderRadius="s" />
    ) : (
      <VStack spacing="lm" width="100%" alignItems="center" justifyContent="center" overflowX="scroll">
        <Text textAlign="center" textStyle="h3" data-test={`reservation-availability-grid-${variant}-text`}>
          {noAvailabilityTitle}
        </Text>
        {requestOrPriorityAlertAvailability}
      </VStack>
    )
  }

  return isLoadingData ? (
    <ReservationAvailabilityGridLoader />
  ) : (
    <ReservationAvailabilityGrid>
      <>
        {timeslots.map(timeSlotInfo => (
          <ReservationTimeslot
            key={`${timeSlotInfo.time}${timeSlotInfo.accessPersistentId}`}
            timeslotDescription={getTimeslotDescription(
              formatMessage,
              timeSlotInfo.publicTimeSlotDescription,
              timeSlotInfo.showCost,
              (timeSlotInfo.cost ?? 0) + timeSlotInfo.includedUpgradesCost,
              timeSlotInfo.chargeType,
              timeSlotInfo.duration,
              currencyCode,
              timeSlotInfo.fees
            )}
            time={timeSlotInfo.time}
            variant={variant}
            onClick={() => {
              onClick(timeSlotInfo)
            }}
          />
        ))}
        {variant === 'primary' ? requestOrPriorityAlertAvailability : null}
      </>
    </ReservationAvailabilityGrid>
  )
}

export function ReservationAvailabilityGrid({
  variant = 'primary',
  children,
}: PropsWithChildren<{ variant?: ReservationTimeSlotVariant }>) {
  const isLargeBreakpoint = useMaxWidthBreakpoint('l')
  const isMediumBreakpoint = useMaxWidthBreakpoint('m')

  let isTabletOrMobile = 4
  if (isMediumBreakpoint) {
    isTabletOrMobile = 2
  } else if (isLargeBreakpoint) {
    isTabletOrMobile = 3
  }
  const gridTemplate = `repeat(${isTabletOrMobile}, minmax(0, 1fr))`
  return (
    <Grid
      gridAutoColumns="auto auto"
      gridTemplateColumns={gridTemplate}
      gap="sm"
      alignItems="start"
      data-test={`reservation-availability-grid-${variant}`}
    >
      {children}
    </Grid>
  )
}

export function ReservationAvailabilityGridLoader() {
  const { fieldHeight } = useTheme()
  return (
    <ReservationAvailabilityGrid>
      {Array.from({ length: NUMBER_OF_SKELETON_LOADERS }, (_v, i) => i).map(i => (
        <SkeletonBox key={i} height={fieldHeight.l} />
      ))}
    </ReservationAvailabilityGrid>
  )
}

export function getTimeslotDescription(
  formatMessage: FormatMessage,
  timeslotDescription: string | undefined,
  showCost: boolean,
  cost: number | undefined | null,
  chargeType: ChargePerType | undefined | null,
  duration: number,
  venueCurrencyCode: string,
  fees: number | undefined
) {
  if (timeslotDescription) {
    return timeslotDescription
  }
  if (!showCost || !cost || !chargeType) {
    return undefined
  }
  const isPersonChargeType = chargeType === 'person' || chargeType === 'person_slot'
  const chargeTypeModiferString = formatMessage(
    isPersonChargeType
      ? reservationAvailabilityMessages.resWidgetPerPersonLabel
      : reservationAvailabilityMessages.resWidgetPerReservationLabel
  )
  const isSlotChargeType = chargeType === 'person_slot' || chargeType === 'reservation_slot'
  return `${FormatService.formatCurrency(
    (isSlotChargeType ? (cost * duration) / 15 : cost) + (fees ?? 0),
    venueCurrencyCode
  )}/${chargeTypeModiferString}`
}
